import L, { Icon } from 'leaflet';

import RedDot from '../../../../../../assets/markers/red-dot.svg';
import BlueDot from '../../../../../../assets/markers/blue-dot.svg';

import { DOT_MARKER_ICON_SIZE, DOT_MARKER_ANCHOR } from './DotMarker.const';
import { MapOverviewMarkerColors } from '../../../../../berth/pages/MapOverview/MapOveriew.types';

const getUrl = (color: MapOverviewMarkerColors): string => {
	switch (color) {
		case MapOverviewMarkerColors.Red:
			return RedDot;

		default:
			return BlueDot;
	}
};

export const DotMarker = (color: MapOverviewMarkerColors): Icon => {
	const icon = getUrl(color);

	return new L.Icon({
		iconUrl: icon,
		iconRetinaUrl: icon,
		iconAnchor: L.point(DOT_MARKER_ANCHOR, DOT_MARKER_ANCHOR),
		iconSize: L.point(DOT_MARKER_ICON_SIZE, DOT_MARKER_ICON_SIZE),
		className: `a-marker--${color}`,
	});
};
