import parseGeneralInfo from './parseGeneralInfo';
import parseVesselInfo from './parseVesselInfo';
import parseVoyageInfo from './parseVoyageInfo';
import parsaCargoInfo from './parseCargoInfo';

export default {
	parseGeneralInfo,
	parseVesselInfo,
	parseVoyageInfo,
	parsaCargoInfo,
};
