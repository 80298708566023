import { StoreConfig, EntityStore } from '@datorama/akita';

import { LogsState, createInitialLogsState } from './logs.model';

@StoreConfig({
	name: 'logs',
	resettable: true,
})
export class LogsStore extends EntityStore<LogsState> {
	constructor() {
		super(createInitialLogsState());
	}
}

export const logsStore = new LogsStore();
