import React from 'react';
import cx from 'classnames';

import { Bell } from '../../../../assets/images';

import { BadgeProps } from './Badge.props';
import styles from './Badge.module.scss';

export const Badge: React.FC<BadgeProps> = ({
	children,
	type,
	isAlert,
	className,
}) => (
	<div
		className={cx(
			className,
			[styles['a-badge']],
			[styles[`a-badge--${type}`]],
		)}
	>
		{children}
		{isAlert ? <Bell className={styles['a-badge-icon']} /> : null}
	</div>
);
