import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { ApiError } from '../../../../core/services/api.types';
import { DossierDetail } from '../../registries/dossiers.types';
import { DossierState } from './dossier.model';
import { DossierStore, dossierStore } from './dossier.store';

export class DossierQuery extends Query <DossierState> {
	constructor(protected store: DossierStore) {
		super(store);
	}

	public isLoading$: Observable<boolean> = this.selectLoading();

	public hasError$: Observable<ApiError> = this.selectError();

	public dossier$: Observable<DossierDetail | null> = this.select('detail');
}

export const dossierQuery = new DossierQuery(dossierStore);
