import { from, Observable } from 'rxjs';

import { QronoPortService } from '../../../core/services/api-service/qronoport.service';
import { processResponse } from '../../../core/services/helpers';

import { VoyageResult, VoyageQuery, VoyageDetail } from './voyages.types';

export class VoyagesRegistry extends QronoPortService {
	public fetchAllByBerth(berths: string): Observable<VoyageDetail[]> {
		if (!berths) {
			return from([[]]);
		}

		return this.api.get<VoyageDetail[]>('voyages/berth', {
			qs: {
				berths,
			},
		})
			.pipe(processResponse<VoyageDetail[]>());
	}

	public fetchOneByImoNumber(imoNumber: string | number): Observable<VoyageDetail[]> {
		if (!imoNumber) {
			return from([[]]);
		}

		return this.api.get<VoyageDetail[]>('voyages/berth', {
			qs: {
				imoNumber,
			},
		}).pipe(processResponse<VoyageDetail[]>(false));
	}

	public fetchOne(qs: VoyageQuery): Observable<VoyageResult> {
		return this.api.get<VoyageResult>('voyages', {
			qs,
		})
			.pipe(processResponse<VoyageResult>(false));
	}
}

export const voyagesRegistry = new VoyagesRegistry();
