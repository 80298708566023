import React, { ReactNode } from 'react';
import { Formik, Field, FieldProps } from 'formik';

import { RadioButtonGroup, RadioButton } from '../../../core/components';
import {
	ActivityVisualisationType,
	TimelineToggleProps,
} from './TimelineToggle.types';

export const TimelineToggle: React.FC<TimelineToggleProps> = ({
	onChange = (): void => {},
	activityVisualisation,
}) => (
	<Formik
		onSubmit={(): void => {}}
		initialValues={{
			activityVisualisation,
		}}
		enableReinitialize
	>
		{(): ReactNode => (
			<form onChange={onChange}>
				<RadioButtonGroup>
					<Field
						name="activityVisualisation"
						render={({ field }: FieldProps): ReactNode => (
							<RadioButton
								field={field}
								id={ActivityVisualisationType.timeline}
								label="Timeline"
								icon="clock"
							/>
						)}
					/>
					<Field
						name="activityVisualisation"
						id={ActivityVisualisationType.list}
						label="List"
						render={({ field }: FieldProps): ReactNode => (
							<RadioButton
								field={field}
								id={ActivityVisualisationType.list}
								label="List"
								icon="list"
							/>
						)}
					/>
				</RadioButtonGroup>
			</form>
		)}
	</Formik>
);
