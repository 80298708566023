import React from 'react';

import cx from 'classnames';

import { Header } from '../../components';
import { BaseLayoutProps } from './BaseLayout.types';

import styles from './BaseLayout.module.scss';

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => (
	<div
		className={styles['a-base-layout']}
	>
		<Header />
		<section
			className={cx('u-grid-container u-grid-container--centered')}
		>
			{children}
		</section>
	</div>
);
