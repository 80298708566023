import { map, NaviRequest, redirect, route } from 'navi';
import { parse } from 'query-string';

import { Token, TokenState } from './store/user.model';
import { userService } from './store/user.service';
import { USER_ROUTES, AUTH_LOGIN_URL } from './user.const';

import { withAuthentication } from '../../router/router.helpers';
import { getLoginRedirectUri } from './helpers/auth/loginHelper';


export default {
	[USER_ROUTES.CALLBACK]: map(async (req: NaviRequest) => {
		const parsedParams: { [key: string]: string } = {};
		req.hash.split('&').map((part) => part.replace(/#/, '')).forEach((param) => {
			const parts = param.split('=');
			// eslint-disable-next-line prefer-destructuring
			parsedParams[parts[0]] = parts[1];
		});
		if (parsedParams.error && parsedParams.error === 'access_denied') {
			return redirect(USER_ROUTES.LOGIN);
		}

		const { hash } = req;
		const token = (parse(hash) as unknown as Token);
		const state = parse(token.state) as TokenState;

		// parse doesn't allow passing Token as the return type
		userService.setToken(token);

		if (!state.redirectTo) {
			return redirect('/');
		}

		return redirect(state.redirectTo);
	}),
	[USER_ROUTES.LOGOUT]: route(async () => {
		userService.logout();

		window.location.replace(AUTH_LOGIN_URL);

		return {};
	}),
	[USER_ROUTES.LOGIN]: withAuthentication(route(async (req: NaviRequest) => {
		window.location.replace(getLoginRedirectUri(req.params));

		return {};
	})),
};
