import { pathOr } from 'ramda';
import { DossierDetailActivity } from '../../../lib/dossiers/registries/dossiers.types';

export const removeEmptyActivities = (activities: DossierDetailActivity[]): DossierDetailActivity[] => (
	activities.filter((activity: DossierDetailActivity) => {
		const stakeholderID = pathOr(null, ['lastAssigneeStakeholder', 'id'], activity);
		const stakeholderRole = pathOr(
			null,
			['lastAssigneeStakeholder', 'supplyChainRole'],
			activity
		);

		const startET = pathOr(null, ['dateTimeWindow', 'beginEstimate'], activity);
		const startAT = pathOr(startET, ['dateTimeWindow', 'beginActual'], activity);

		const endET = pathOr(null, ['dateTimeWindow', 'endEstimate'], activity);
		const endAT = pathOr(endET, ['dateTimeWindow', 'endActual'], activity);

		return activity && startAT && endAT && stakeholderID && stakeholderRole;
	})
);
