import { StoreConfig, Store } from '@datorama/akita';

import { createInitialVesselsState, VesselsState } from './vessels.model';

@StoreConfig({
	name: 'vessels',
	resettable: true,
})
export class VesselsStore extends Store<VesselsState> {
	constructor() {
		super(createInitialVesselsState());
	}
}

export const vesselsStore = new VesselsStore();
