import { Observable } from 'rxjs';

import { NextPortService } from '../../../core/services/api-service/nextport.service';
import { processResponse } from '../../../core/services/helpers';

import { DossierOptions, Dossier, DossierDetail } from './dossiers.types';
import { INITIAL_DOSSIERS_OPTIONS } from '../store/dossiers/dossiers.model';

export class DossiersRegistry extends NextPortService {
	public fetchAll(qs: DossierOptions = INITIAL_DOSSIERS_OPTIONS): Observable<Dossier[]> {
		return this.api.get<Dossier[]>('dossiers', {
			headers: this.headers,
			qs,
		})
			.pipe(processResponse<Dossier[]>());
	}

	public fetchOne(id: number): Observable<DossierDetail> {
		return this.api.get<DossierDetail>(`dossiers/${id}`, {
			headers: this.headers,
		})
			.pipe(processResponse<DossierDetail>());
	}
}

export const dossiersRegistry = new DossiersRegistry();
