import { useState, useEffect } from 'react';
import { isNil } from 'ramda';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { userQuery } from '../store';
import { Token } from '../store/user.model';

export const useToken = (): Token | null => {
	const [token, setToken] = useState<Token | null>(null);

	useEffect(
		() => {
			const destroyed$: Subject<boolean> = new Subject<boolean>();

			userQuery.token$
				.pipe(
					takeUntil(destroyed$),
					filter((newToken: Token | null) => !isNil(newToken))
				)
				.subscribe((newToken: Token | null) => setToken(newToken));

			return (): void => {
				destroyed$.next(true);
				destroyed$.complete();
			};
		},
		[token]
	);

	return token;
};
