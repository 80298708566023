import React from 'react';
import cx from 'classnames';

import { getAlerts } from '../../helpers/alerts/alertHelper';
import { getCompanyColor } from '../../../lib/companies/helpers/getColor';
import { removeEmptyActivities } from '../../../core/helpers/timeline';

import { ActivityListProps } from './ActivityList.types';
import { ActivityListItem } from './ActivityListItem/ActivityListItem';

import styles from './ActivityList.module.scss';
import { activityPopulator } from '../../../core/helpers/tmpUtilities/activityPopulator';
// eslint-disable-next-line max-len
import { getCargoContentName } from '../../../core/components/ActivityTimeline/AcitivyTimelineItem/AcitivyTimelineItem.helpers';

export const ActivityList: React.FC<ActivityListProps> = ({
	activities,
	cargo,
	alerts,
	title,
	onClick,
}) => {
	const tempActivities = activityPopulator(activities);

	const relevantActivities = removeEmptyActivities(tempActivities);
	return (
		<div className={cx('u-grid-container', styles['o-activity-section'])}>
			<span className={styles['a-activity-list-title']}>
				{title}
			</span>
			<div className={cx('u-grid-container', styles['o-activity-list'])}>
				{
					relevantActivities.length > 0 ? (
						relevantActivities.map((item, index) => (
							<ActivityListItem
								key={`activity-${title}-list-item-${item.activityType + index}`}
								activityData={item}
								cargoContentName={getCargoContentName(cargo, item)}
								color={getCompanyColor(
									item.lastAssigneeStakeholder.supplyChainRole
								)}
								alerts={getAlerts(item.id, alerts)}
								onClick={onClick}
							/>
						))
					) : (
						<p className="text-sm mb-4 text-regentGray">No activities available</p>
					)
				}
			</div>
		</div>
	);
};
