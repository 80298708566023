import React from 'react';
import cx from 'classnames';

import styles from './DelayLabel.module.scss';
import { DelayLabelProps } from './DelayLabel.props';
import { getLabelColor, getDelay } from './DelayLabel.helper';

import { timeBetween } from '../../helpers/time/timeHelper';

import { ReactComponent as Clock } from '../../../../assets/images/clock.svg';

export const DelayLabel: React.FC<DelayLabelProps> = ({ estTime, realTime }) => (
	<div className={styles['m-delay-label']}>
		<div
			className={cx(
				styles['m-delay-label--inner'],
				styles[`m-delay-label--inner__${getLabelColor(timeBetween(estTime, realTime))}`],
			)}
		>
			<Clock className={styles['a-delay-icon']} />
			<span className={styles['a-delay-label-text']}>
				{getDelay(estTime, realTime)} min
			</span>
		</div>
	</div>
);
