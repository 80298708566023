import hash from 'object-hash';

import { AUTH_BASE_URL } from '../../user.const';

export const getLoginRedirectUri = (queryParams: {[name: string]: string}): string => {
	const parsedQueryParams = Object.keys(queryParams)
		.map((key) => `${key}=${queryParams[key]}`)
		.join('&');

	const endcodedQueryParams = encodeURIComponent(parsedQueryParams);
	// const nonce = hash({ nonce: Math.random() * Math.random() * Date.now() * Math.random() });

	return `${AUTH_BASE_URL}
			?client_id=${process.env.REACT_APP_NEXTPORT_CLIENT_ID}
			&scope=openid%20offline_access%20profile%20email%20%20${process.env.REACT_APP_NEXTPORT_SCOPE}
			&redirect_uri=${process.env.REACT_APP_CALLBACK_REDIRECT_URI}
			&nonce=123
			&state=${endcodedQueryParams}
			&response_type=token id_token
		`;
};
