import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isNil } from 'ramda';

import { voyageQuery } from '../voyage.query';
import { Voyage } from '../../../registries/voyages.types';

export const useVoyage = (): Voyage | null => {
	const [voyage, setVoyage] = useState<Voyage | null>();

	useEffect(() => {
		const destroyed$: Subject<boolean> = new Subject<boolean>();

		voyageQuery.voyage$
			.pipe(takeUntil(destroyed$))
			.subscribe((results: Voyage | null) => setVoyage(results));

		return (): void => {
			destroyed$.next(true);
			destroyed$.complete();
		};
	}, [voyage]);

	if (isNil(voyage)) {
		return null;
	}

	return voyage;
};
