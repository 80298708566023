import { FilteredVesselPositions } from '../MapOveriew.types';
import { Dossier } from '../../../../lib/dossiers/registries/dossiers.types';
import { VesselPosition } from '../../../../lib/vessels/registries/vessels.types';

export const filterVesselPositions = (
	vesselPositions: VesselPosition[],
	dossiers: Dossier[]
): FilteredVesselPositions => (
	vesselPositions.reduce(
		(acc: FilteredVesselPositions, vesselPos: VesselPosition) => {
			const dossierImmos = dossiers.map((dossier) => dossier.vesselImo);

			const { vessel } = vesselPos;
			const isOwnVessel = dossierImmos.some((dossier) => dossier === vessel.imoNumber);

			if (isOwnVessel) {
				acc.ownVessels.push(vesselPos);
				return acc;
			}

			acc.otherVessels.push(vesselPos);
			return acc;
		}, {
			ownVessels: [],
			otherVessels: [],
		}
	)
);
