import React, { ReactNode, createRef, forwardRef } from 'react';
import { Map as LeafletMap, TileLayer, ZoomControl } from 'react-leaflet';
import cx from 'classnames/bind';

import 'leaflet/dist/leaflet.css';

import { MapProps } from './Map.props';
import { MAP_ATTRIBUTION, MAP_TILE_LAYER, MAP_MAX_ZOOM } from './Map.const';
import { MapLoading } from './MapLoading/MapLoading';
import { MapMessage } from './MapMessage/MapMessage';

import styles from './Map.module.scss';

const cxBind = cx.bind(styles);

class MapComponent extends React.Component<MapProps, {}> {
	public leafletMapInstance = createRef<LeafletMap>();

	public render(): ReactNode {
		const {
			center,
			zoom,
			tileConfig = {
				attribution: MAP_ATTRIBUTION,
				url: MAP_TILE_LAYER,
				maxZoom: MAP_MAX_ZOOM,
			},
			loading = false,
			loadingContent,
			message,
			showZoomControls = true,
			topLeftCornerContent,
			topRightCornerContent,
			bottomLeftCornerContent,
			bottomRightCornerContent,
			topLeftContentOnTop = false,
			topRightContentOnTop = false,
			bottomLeftContentOnTop = false,
			bottomRightContentOnTop = false,
			children,
			innerRef,
			...props
		} = this.props;

		return (

			<div
				className={cx(
					cxBind({
						'm-map': true,
						'm-map--has-zoom-controls': showZoomControls,
					}),
					'bg-white w-full h-full shadow-md rounded-lg overflow-hidden relative z-0'
				)}
			>
				<div className="w-full h-full relative z-0">
					<LeafletMap
						ref={innerRef}
						center={center}
						zoom={zoom}
						maxZoom={MAP_MAX_ZOOM}
						zoomControl={false}

						useFlyTo
						{...props}
					>
						<TileLayer
							attribution={tileConfig.attribution}
							url={tileConfig.url}
						/>
						{ showZoomControls && (<ZoomControl position="topright" />) }
						{ children }
					</LeafletMap>
				</div>
				{ topLeftCornerContent && (
					<div
						className={cxBind({
							'm-map__corner-wrapper': true,
							'm-map__corner-wrapper--top-left': true,
							'z-50': topLeftContentOnTop,
						})}
					>
						{topLeftCornerContent}
					</div>
				)}
				{ topRightCornerContent && (
					<div
						className={cxBind({
							'm-map__corner-wrapper': true,
							'm-map__corner-wrapper--top-right': true,
							'z-50': topRightContentOnTop,
						})}
					>
						{topRightCornerContent}
					</div>
				)}
				{ bottomRightCornerContent && (
					<div
						className={cxBind({
							'm-map__corner-wrapper': true,
							'm-map__corner-wrapper--bottom-right': true,
							'z-50': bottomRightContentOnTop,
						})}
					>
						{bottomRightCornerContent}
					</div>
				)}
				{ bottomLeftCornerContent && (
					<div
						className={cxBind({
							'm-map__corner-wrapper': true,
							'm-map__corner-wrapper--bottom-left': true,
							'z-50': bottomLeftContentOnTop,
						})}
					>
						{bottomLeftCornerContent}
					</div>
				)}
				{ loading && (
					<MapLoading>
						{loadingContent}
					</MapLoading>
				)}
				{ message && (
					<MapMessage>
						{message}
					</MapMessage>
				)}

			</div>
		);
	}
}

export const Map = forwardRef((props: MapProps, ref) => <MapComponent innerRef={ref} {...props} />);
