import { Observable } from 'rxjs';

import { QronoPortService } from '../../../core/services/api-service/qronoport.service';
import { processResponse } from '../../../core/services/helpers';

import { Company } from './companies.types';

export class CompaniesRegistry extends QronoPortService {
	public fetchOne(vat: string): Observable<Company> {
		return this.api.get<Company>('companies', {
			qs: {
				vat,
			},
		})
			.pipe(processResponse<Company>(false));
	}
}

export const companiesRegistry = new CompaniesRegistry();
