import React, { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { DataGroup } from 'vis-timeline';

import { hashString } from '../../../helpers/generators/hash';
import { BerthOverviewTimelineGroup } from '../BerthOverviewTimelineGroup/BerthOverviewTimelineGroup';


const renderGroup = (berth: string): string => (
	renderToString(<BerthOverviewTimelineGroup berth={berth} />)
);

export const useBerthOverviewGroups = (berths: string[]): DataGroup[] => {
	const [timelineGroups, setTimelineGroups] = useState<DataGroup[]>([]);

	useEffect(() => {
		setTimelineGroups(berths.map(
			(berth) => ({
				id: hashString(berth),
				content: renderGroup(berth),
				className: 'o-timeline--berth-overview__vis-label vis-label__border--white',
			})
		));
	}, [berths]);

	return timelineGroups;
};
