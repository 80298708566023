import { route } from 'navi';
import { CORE_ROUTES } from './core.const';

export default {
	[CORE_ROUTES.NOT_ALLOWED]: route({
		title: 'not-allowed',
		getView: () => import('./pages/NotAllowed'),
	}),
	[CORE_ROUTES.NOT_FOUND]: route({
		title: 'Not found',
		getView: () => import('./pages/NotFound'),
	}),
};
