import { TimelineOptions, DataGroup, DataItem } from 'vis-timeline';

import { TimelineVisibleArea } from './Timeline.types';

export type TimelineProps = {
	groups: DataGroup[];
	items: DataItem[];
	alerts: DataItem[];
	passagePoints?: DataItem[];

	startDate: Date | null;
	endDate: Date | null;

	options?: TimelineOptions;
	timelineClass?: string;

	onTimelineViewportChange: (data: TimelineVisibleArea) => void;
	onTimelineItemClick: (id: number | string) => void;
	omitCluster?: () => void;
	setCluster?: () => void;
};

export const defaultProps = {
	groups: [],
	items: [],
	alerts: [],
	passagePoints: [],

	startDate: null,
	endDate: null,
	options: {},
	timelineClass: '',

	onTimelineViewportChange: (): void => {},
	onTimelineItemClick: (): void => {},
	omitCluster: (): void => {},
	setCluster: (): void => {},
};
