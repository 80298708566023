import React, { FC } from 'react';
import cx from 'classnames/bind';

import { BERTH_TIMELINE_GROUP_PREFIX } from '../../../../berth/pages/BerthOverview/BerthOverview.const';

import { BerthOverviewTimelineGroupProps } from './BerthOverviewTimelineGroup.props';
import styles from './BerthOverviewTimelineGroup.module.scss';

const cxBind = cx.bind(styles);

export const BerthOverviewTimelineGroup: FC<BerthOverviewTimelineGroupProps> = ({ berth }) => (
	<span className={cxBind('a-timeline-group__content')}>
		<span className={cxBind('a-timeline-group__name')}>
			{BERTH_TIMELINE_GROUP_PREFIX} {berth}
		</span>
	</span>
);
