import React, { ReactNode } from 'react';

import { Activities, InfoCircle } from '../../../../../assets/images';
import { DossierLogType } from '../../../../core/core.types';

import { LOG_TYPES } from '../../../../lib/logs/store/logs.const';
import { ActivityLogType } from '../../../../lib/logs/registries/logs.types';

export const showLogTypeIcon = (type: DossierLogType): ReactNode => {
	if (LOG_TYPES[ActivityLogType.activity].includes(type)) {
		return <Activities />;
	}

	if (LOG_TYPES[ActivityLogType.general].includes(type)) {
		return <InfoCircle />;
	}

	// Leave this for the next phase of the project. Parties are currently not supported.
	// if (type === ActivityLogType.Parties) {
	// 	return <UsersAlt />;
	// }

	return <InfoCircle />;
};
