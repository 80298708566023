import { DELAY_MIN, DELAY_MAX } from './ActivityClock.const';

export const getClockColor = (delay: number): string => {
	if (delay < DELAY_MIN) {
		return 'green';
	}

	if (delay >= DELAY_MIN && delay <= DELAY_MAX) {
		return 'orange';
	}

	return 'red';
};
