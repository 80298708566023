import React from 'react';
import { pathOr } from 'ramda';

import { VoyageInfoProps } from './VoyageInfo.props';
import styles from './VoyageInfo.module.scss';

import { Button } from '../../../../core/components';
import { ButtonTypes, ButtonSizes } from '../../../../core/components/Button/Button.types';

export const VoyageInfo: React.FC<VoyageInfoProps> = ({
	dossier,
	voyage,
	onShowMoreClick,
}) => (
	<div
		className={styles['m-dossier-info']}
	>
		<div
			className={styles['m-lists-wrapper']}
		>
			<table className={styles['a-list']}>
				<tbody>
					<tr className={styles['a-list__item']}>
						<td className={styles['a-list__item--title']}>Qronoport Ref.</td>
						<td>{dossier.dossierNumber}</td>
					</tr>
					<tr className={styles['a-list__item']}>
						<td className={styles['a-list__item--title']}>Own Ref.</td>
						<td>{dossier.requestingStakeholderReference.join(', ')}</td>
					</tr>
					<tr className={styles['a-list__item']}>
						<td className={styles['a-list__item--title']}>Imo-nr</td>
						<td>{dossier.vessel.imo}</td>
					</tr>
					<tr className={styles['a-list__item']}>
						<td className={styles['a-list__item--title']}>Origin</td>
						<td>{pathOr('-', ['originPort', 'name'], voyage)}</td>
					</tr>
					<tr className={styles['a-list__item']}>
						<td className={styles['a-list__item--title']}>Berth</td>
						<td>{dossier.berth}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div>
			<Button
				type={ButtonTypes.Secondary}
				size={ButtonSizes.small}
				action={onShowMoreClick}
			>
				Show More
			</Button>
		</div>
	</div>
);
