import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { userQuery } from '../store';

export const useProfileId = (): string => {
	const [profileId, setIsProfileId] = useState<string>('');

	useEffect(
		() => {
			const destroyed$: Subject<boolean> = new Subject<boolean>();

			userQuery.id$
				.pipe(
					takeUntil(destroyed$),
					filter((id: string) => id !== profileId)
				)
				.subscribe((id: string) => {
					setIsProfileId(id);
				});

			return (): void => {
				destroyed$.next(true);
				destroyed$.complete();
			};
		},
		[profileId]
	);

	return profileId;
};
