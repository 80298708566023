import React, { ReactNode } from 'react';
import { isEmpty } from 'ramda';

import { ActivityModalProps } from './ActivityModal.props';
import { PropertyValue, ActivityType } from '../../../core/core.types';

import { getActivityIcon } from '../../../core/helpers/icon/iconHelper';
import { splitCamelCaseString } from '../../../core/helpers/text/textHelper';
import { TimeOverview } from '../../../core/components';

import styles from './ActivityModal.module.scss';
import { DossierDetailActivity, DossierDetailCargo } from '../../../lib/dossiers/registries/dossiers.types';
import { parseCargoGeneralInfo, parseCargoOperationInfo } from '../InfoModal/helpers/parseCargoInfo';
import { hashString } from '../../../core/helpers/generators/hash';

const renderPropertyValues = (activityId: number, propertyValues: PropertyValue[]): ReactNode => (
	propertyValues.map((item) => (
		<tr key={`${activityId}_${item.dataType}_${item.value}`}>
			<td className={styles['a-list-item__title']}>
				{splitCamelCaseString(item.name)}
			</td>
			<td className={styles['a-list-item__desc']}>
				{item.value}
			</td>
		</tr>
	))
);

const renderCargo = (cargo: DossierDetailCargo): ReactNode => {
	const parsedCargo = {
		...parseCargoGeneralInfo(cargo),
		...parseCargoOperationInfo(cargo),
	};

	const items = Object.keys(parsedCargo).map((key: string) => (
		<tr key={`info-column--${hashString(`${key}${parsedCargo[key]}`)}`}>
			<td className={styles['a-list-item__title']}>
				{key}
			</td>
			<td className={styles['a-list-item__desc']}>
				{parsedCargo[key]}
			</td>
		</tr>
	));

	return items;
};

const renderTable = (
	activity: DossierDetailActivity,
	cargo?: DossierDetailCargo,
): ReactNode => {
	if (!isEmpty(activity.propertyValues)) {
		return renderPropertyValues(activity.id, activity.propertyValues);
	}

	if (
		(activity.activityType === ActivityType.Discharging
		|| activity.activityType === ActivityType.Loading)
		&& cargo
		&& !isEmpty(cargo)
	) {
		return renderCargo(cargo);
	}

	return (
		<tr>
			<td>-</td>
		</tr>
	);
};

export const ActivityModal: React.FC<ActivityModalProps> = ({ activity, cargo }) => (
	<>
		<div className={styles['o-voyage-activity']}>
			<div className={styles['m-activity-header']}>
				{getActivityIcon(
					activity.activityType,
					styles['a-activity-icon'],
				)}
				<div className={styles['a-activity-label']}>
					<span className={styles['a-activity-label__title']}>
						Activity
					</span>
					<span className={styles['a-activity-label__subtitle']}>
						{splitCamelCaseString(activity.activityType)}
					</span>
				</div>
			</div>
			<div className="flex flex-1">
				<div className={styles['m-time-overview-container']}>
					<TimeOverview
						active={false}
						renderPrefix={false}
						startTime={{
							label: 'Estimated Start time',
							estTime: activity.dateTimeWindow.beginEstimate,
							actualTime: null,
						}}
						endTime={{
							label: 'Estimated End time',
							estTime: activity.dateTimeWindow.endEstimate,
							actualTime: null,
						}}
					/>

					<TimeOverview
						active={false}
						renderPrefix={false}
						startTime={{
							label: 'Actual Start time',
							estTime: activity.dateTimeWindow.beginEstimate,
							actualTime: activity.dateTimeWindow.beginActual,
						}}
						endTime={{
							label: 'Actual End time',
							estTime: activity.dateTimeWindow.endEstimate,
							actualTime: activity.dateTimeWindow.endActual,
						}}
						displayActual
					/>
				</div>
				<div className="w-1/2 pl-12">
					<table className="w-full">
						<tbody>
							{renderTable(activity, cargo)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</>
);
