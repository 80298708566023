import { EntityState } from '@datorama/akita';
import { DossierOptions, GetDossiersSortField, Dossier } from '../../registries/dossiers.types';

export interface DossiersState extends EntityState<Dossier> {
	options: DossierOptions;
	isLastDossier: boolean;
}

export const INITIAL_DOSSIERS_OPTIONS: DossierOptions = {
	version: '1',
	page: 1,
	departed: false,
	'page-size': 15,
	'order-by-field': GetDossiersSortField.NextActivityEstimatedStart,
	'order-by': 'Descending',
};

export function createInitialDossiersState(): DossiersState {
	return {
		options: INITIAL_DOSSIERS_OPTIONS,
		isLastDossier: false,
	};
}
