import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { logsQuery } from '../store/logs.query';

export const useLogsLoading = (): boolean => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const destroyed$: Subject<boolean> = new Subject<boolean>();

		logsQuery.isLoading$
			.pipe(takeUntil(destroyed$))
			.subscribe((loading: boolean) => {
				setIsLoading(loading);
			});

		return (): void => {
			destroyed$.next(true);
			destroyed$.complete();
		};
	}, []);

	return isLoading;
};
