import React from 'react';
import cx from 'classnames';

import styles from './PageHeader.module.scss';
import { PageHeaderProps } from './PageHeader.types';

export const PageHeader: React.FC<PageHeaderProps> = ({ children, classNames }) => (
	<header
		className={cx(
			styles['o-page-header'],
			classNames,
		)}
	>
		{ children }
	</header>
);
