import { pathOr } from 'ramda';

import { DossierAlert } from '../../../lib/dossiers/registries/dossiers.types';

import { ImpactedActor } from './AlertSection.types';

export const getConflictedActivity = (activityId: number, alert: DossierAlert): string => {
	if (activityId === alert.activity1Id) {
		return pathOr('-', ['liquidBulkActivity2Type'], alert);
	}

	return pathOr('-', ['liquidBulkActivity1Type'], alert);
};

export const getImpactedActor = (
	activityId: number,
	alert: DossierAlert,
	impactedActors: ImpactedActor,
): string => {
	const alertActivityId = (alert.activity1Id === activityId) ? alert.activity2Id : alert.activity1Id;

	return pathOr('-', [alertActivityId], impactedActors);
};
