import React from 'react';
import cx from 'classnames/bind';

import { TimeOverview, Activity } from '../../../../core/components';

import { VoyageActivityProps } from './VoyageActivity.props';
import styles from './VoyageActivity.module.scss';

const cxBind = cx.bind(styles);

export const VoyageActivity: React.FC<VoyageActivityProps> = ({
	active,
	headerLabel,
	activityType,
	expectedStartDateTime,
	actualStartDateTime,
	expectedEndDateTime,
	actualEndDateTime,
}) => (
	<div
		className={cxBind('o-voyage-activity')}
	>
		<Activity
			type={activityType}
			whiteText={active}
			label={headerLabel}
		/>
		<TimeOverview
			active={active}
			activityType={activityType}
			startTime={{
				label: 'Start time',
				estTime: expectedStartDateTime,
				actualTime: actualStartDateTime,
			}}
			endTime={{
				label: 'End time',
				estTime: expectedEndDateTime,
				actualTime: actualEndDateTime,
			}}
			renderPrefix
		/>
	</div>
);
