import React from 'react';
import cx from 'classnames';

import styles from './StateLabel.module.scss';
import { StateLabelProps } from './StateLabel.props';


export const StateLabel: React.FC<StateLabelProps> = ({ color, text }) => (
	<div className={styles['m-state-label']}>
		<div
			className={cx(
				styles['m-state-label--inner'],
				styles[`m-state-label--inner__${color}`],
			)}
		>
			<span className={styles['a-state-label-text']}>{text}</span>
		</div>
	</div>
);
