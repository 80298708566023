import L, { DivIcon } from 'leaflet';

import ArrowRed from '../../../../../../assets/markers/arrow-red.svg';
import ArrowBlue from '../../../../../../assets/markers/arrow-blue.svg';

import { ARROW_MARKER_ICON_SIZE, ARROW_MARKER_ANCHOR } from './ArrowMarker.const';
import { MapOverviewMarkerColors } from '../../../../../berth/pages/MapOverview/MapOveriew.types';

const getUrl = (color: MapOverviewMarkerColors): string => {
	switch (color) {
		case MapOverviewMarkerColors.Red:
			return ArrowRed;

		default:
			return ArrowBlue;
	}
};

export const ArrowMarker = (color: MapOverviewMarkerColors, angle: number): DivIcon => {
	const icon = getUrl(color);

	return new L.DivIcon({
		iconAnchor: L.point(ARROW_MARKER_ANCHOR, ARROW_MARKER_ANCHOR),
		iconSize: L.point(ARROW_MARKER_ICON_SIZE, ARROW_MARKER_ICON_SIZE),
		html: `<img
			src="${icon}"
			height="${ARROW_MARKER_ICON_SIZE}"
			width="${ARROW_MARKER_ICON_SIZE}"
			style="transform: rotate(${angle}deg)"
		/>`,
		className: `a-marker--${color}`,
	});
};
