import React from 'react';

export enum ActivityVisualisationType {
	timeline = 'timeline',
	list = 'list',
}

export type ActivityVisualisationTypes = 'timeline' | 'list';

export interface ActivityVisualisationTypeForm {
	activityVisualisation: ActivityVisualisationTypes;
}

export interface TimelineToggleProps {
	onChange?: (e: React.FormEvent<HTMLFormElement>) => void;
	activityVisualisation: ActivityVisualisationTypes;
}
