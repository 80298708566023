import React, { FC, useState } from 'react';
import { omit } from 'ramda';

import { Timeline } from '../Timeline/Timeline';
import { removeEmptyActivities } from '../../helpers/timeline';

import { ActivityTimelineProps } from './ActivityTimeline.props';
import { DETAIL_ACTIVITY_TIMELINE_OPTIONS } from './ActivityTimeline.const';
import { mapTimelineData } from './ActivityTimeline.helpers';
import { TIMELINE_DEFAULT_OPTIONS } from '../Timeline/Timeline.const';
import { activityPopulator } from '../../helpers/tmpUtilities/activityPopulator';


export const ActivityTimeline: FC<ActivityTimelineProps> = ({
	alerts,
	activities,
	passagePoints,
	startDate,
	endDate,
	options,
	onTimelineItemClick,
	onTimelineViewportChange,
	timelineClass,
	cargo,
}) => {
	const [timelineOptions, setTimelineOptions] = useState({
		...TIMELINE_DEFAULT_OPTIONS,
		...DETAIL_ACTIVITY_TIMELINE_OPTIONS,
		...options,
	});

	const tempActivities = activityPopulator(activities);

	const { timelineItems, timelineGroups, timelineAlerts, timelinePassagePoints } = mapTimelineData(
		removeEmptyActivities(tempActivities),
		alerts,
		passagePoints || [],
		cargo,
	);

	const omitCluster = (): void => (
		setTimelineOptions(omit(['cluster'], timelineOptions))
	);

	const setCluster = (): void => (
		setTimelineOptions({
			...TIMELINE_DEFAULT_OPTIONS,
			...DETAIL_ACTIVITY_TIMELINE_OPTIONS,
			...options,
		})
	);

	return (
		<Timeline
			alerts={timelineAlerts}
			passagePoints={timelinePassagePoints}
			groups={timelineGroups}
			items={timelineItems}
			startDate={startDate}
			endDate={endDate}
			options={timelineOptions}
			onTimelineViewportChange={onTimelineViewportChange}
			onTimelineItemClick={onTimelineItemClick}
			timelineClass={timelineClass}
			omitCluster={omitCluster}
			setCluster={setCluster}
		/>
	);
};
