import { RxHR } from '@akanass/rx-http-request';
import { filter } from 'rxjs/operators';
import { isNil } from 'ramda';

import { userQuery } from '../../../user/store/user.query';
import { Token } from '../../../user/store/user.model';

export const NEXTPORT_DEFAULT_HEADERS = {
	'Ocp-Apim-Subscription-Key': process.env.REACT_APP_NEXTPORT_API_KEY as string,
};

export abstract class NextPortService {
	protected headers: { [key: string]: string } = NEXTPORT_DEFAULT_HEADERS;

	protected api = RxHR.defaults({
		baseUrl: process.env.REACT_APP_NEXTPORT_API_BASE_URL,
		headers: NEXTPORT_DEFAULT_HEADERS,
		json: true,
	});

	constructor() {
		userQuery.token$
			.pipe(filter((token: Token | null) => !isNil(token)))
			.subscribe((token: Token | null) => {
				this.headers = {
					...NEXTPORT_DEFAULT_HEADERS,
					Authorization: `${(token as Token).token_type} ${(token as Token).access_token}`, // eslint-disable-line max-len
				};
			});
	}
}
