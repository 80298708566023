import React, { FC, useState } from 'react';
import cx from 'classnames';
import { isNil, head } from 'ramda';
import { useNavigation } from 'react-navi';

import { ArrowLeft } from '../../../../assets/images';
import { useDossierDetail } from '../../../lib/dossiers/store/dossier/dossier.hooks';
import { useCompanies } from '../../../lib/companies/store/companies/companies.hooks';
import { useLogQuery, useDossierLogs, useSingleDossier } from '../../../lib/logs/store/logs.hooks';

import { PageHeader, Button, RouterLink, Badge, Modal, Dropdown } from '../../../core/components';
import {
	InfoBox,
	VoyageInfo,
	ActivityLogList,
	PartyList,
	VoyageDetailActivitySection,
	VoyageDetailMapSection,
} from '../../components';

import { BadgeType } from '../../../core/components/Badge/Badge.props';
import { BaseLayout } from '../../../core/layouts';

import { VoyageActivity } from '../../components/InfoBox/VoyageActivity/VoyageActivity';
import { ActivityModal, ActivityModalFooter, InfoModal } from '../../modals';

import { ModalHeader } from '../../../core/components/Modal/ModalHeader/ModalHeader';
import { DossierDetail } from '../../../lib/dossiers/registries/dossiers.types';
import { ActivityLogType, LogQuery, DossierLog, Log } from '../../../lib/logs/registries/logs.types';
import { ButtonTypes } from '../../../core/components/Button/Button.types';
import { ActivityType } from '../../../core/core.types';
import { ActivityVisualisationType } from '../../components/TimelineToggle/TimelineToggle.types';
import { Voyage } from '../../../lib/voyages/registries/voyages.types';
import { useVoyage } from '../../../lib/voyages/store/voyage/hooks';
import { activityLogFilters } from '../../components/ActivityList/ActivityList.const';

import styles from './VoyageDetail.module.scss';
import { getImpactedActors } from '../../helpers/alerts/alertHelper';
import { getCargo } from './VoyageDetail.helper';

const VoyageDetail: FC = () => {
	const navigation = useNavigation();
	const [filterType, setFilterType] = useState();
	const [activeActivity, setActiveActivity] = useState();
	const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
	const dossier: DossierDetail | null = useDossierDetail();
	const logQuery: LogQuery = useLogQuery(dossier, filterType);
	const dossierLogs: DossierLog[] = useDossierLogs(logQuery);
	const currentDossierLogs = head(dossierLogs);
	const logs: Log[] = useSingleDossier(currentDossierLogs);
	const companies = useCompanies();
	const voyage: Voyage | null = useVoyage();

	const handleInfoModalToggle = (open = true): void => {
		setShowInfoModal(open);
	};

	if (!dossier) {
		return null;
	}

	const unreadLogs = logs.filter((log) => !log.isReadByCurrentStakeholder).length;
	const handleLogFilter = (category: ActivityLogType): void => {
		setFilterType(category);
	};

	const handleActivityClick = (activityId: number | string): void => {
		// if clicked on an advance or delay, the activityId returns a string
		// in format `${activityId}_${start or end}-${delay or advance}`
		// in order to set the current activity, we need to get the numbers in
		// front of the first underscore
		const id = typeof activityId === 'string' ? parseInt(head(activityId.split('_')), 10) : activityId;

		if (!Number.isNaN(id)) {
			const currentActivity = dossier.activities.find((activity) => activity.id === id);
			setActiveActivity(currentActivity);
		}
	};

	const impactedActors = getImpactedActors(
		activeActivity,
		dossier.activities,
		dossier.alerts
	);

	return (
		<BaseLayout>
			<PageHeader>
				<div className="flex flex-row justify-center items-center">
					<RouterLink
						className="mr-4"
						href="/voyages"
					>
						<ArrowLeft width="12" height="12" />
					</RouterLink>
					<h1>
						Voyage detail
						{dossier && (
							<small className="ml-2 font-normal lowercase text-blue">
								{!isNil(dossier.requestingStakeholderReference)
									? head(dossier.requestingStakeholderReference)
									: '-'}/
								{dossier.vessel.name}
							</small>
						)}
					</h1>
				</div>
			</PageHeader>
			{dossier && (
				<>
					<section
						className={styles['m-info-container']}
					>
						<InfoBox>
							<VoyageInfo
								onShowMoreClick={(): void => handleInfoModalToggle()}
								dossier={dossier}
								voyage={voyage}
							/>
						</InfoBox>
						<InfoBox>
							<VoyageActivity
								active={false}
								headerLabel="Current activity"
								activityType={ActivityType[dossier.currentActivityType]}
								expectedStartDateTime={
									dossier.currentActivityExpectedStartDateTime
								}
								actualStartDateTime={
									dossier.currentActivityActualStartDateTime
								}
								expectedEndDateTime={
									dossier.currentActivityExpectedEndDateTime
								}
								actualEndDateTime={dossier.currentActivityActualEndDateTime}
							/>
						</InfoBox>
						<InfoBox active>
							<VoyageActivity
								active
								headerLabel="Next activity"
								activityType={ActivityType[dossier.nextStakeholderActivityType]}
								expectedStartDateTime={
									dossier.nextStakeholderActivityExpectedStartDateTime
								}
								actualStartDateTime={
									dossier.nextStakeholderActivityActualStartDateTime
								}
								expectedEndDateTime={
									dossier.nextStakeholderActivityExpectedEndDateTime
								}
								actualEndDateTime={
									dossier.nextStakeholderActivityActualEndDateTime
								}
							/>
						</InfoBox>
					</section>

					<VoyageDetailActivitySection
						dossier={dossier}
						voyage={voyage}
						defaultActivityView={ActivityVisualisationType.timeline}
						onActivityClick={handleActivityClick}
					/>
					<VoyageDetailMapSection dossier={dossier} />
					<section className={
						cx(
							styles['o-activity-log-and-parties'],
							'max-w-full mt-12',
						)
					}
					>
						<div className={cx('u-grid-container', styles['o-activity-log'])}>
							<div className={styles['m-activity-log__header']}>
								<div>
									<div className={styles['a-title']}>Activity log</div>
									<Badge className={styles['a-badge']} type={BadgeType.Red} isAlert>
										{unreadLogs}
									</Badge>
								</div>
								<Dropdown
									items={activityLogFilters}
									onClick={(type): void => handleLogFilter(type)}
									name="ActivityLogFilter"
								/>
							</div>
							<InfoBox>
								<ActivityLogList
									logs={logs}
									small
								/>
								<div className={styles['m-activity-log__actions']}>
									<Button
										type={ButtonTypes.Secondary}
										action={(): void => {
											navigation.navigate(`/voyages/${dossier.id}/activityLog`);
										}}
									>
										Show More
									</Button>
								</div>
							</InfoBox>
						</div>
						<div className={styles['o-parties']}>
							<div className={styles['m-parties__header']}>
								<div className={styles['a-title']}>Active Parties</div>
							</div>
							<PartyList parties={companies} />
						</div>
					</section>
					<Modal
						header={(
							<ModalHeader
								closeModal={(): void => setActiveActivity(null)}
							/>
						)}
						footer={(
							<ActivityModalFooter
								actviveActivity={activeActivity}
								impactedActors={impactedActors}
								alerts={dossier.alerts}
							/>
						)}
						visible={!isNil(activeActivity)}
						onClose={(): void => setActiveActivity(null)}
					>
						<ActivityModal
							activity={activeActivity}
							cargo={getCargo(activeActivity, dossier.cargo)}
						/>
					</Modal>
					<Modal
						header={(
							<ModalHeader
								title="Info"
								closeModal={(): void => handleInfoModalToggle(false)}
							/>
						)}
						visible={showInfoModal}
						onClose={(): void => handleInfoModalToggle(false)}
					>
						<InfoModal
							dossier={dossier}
							voyage={voyage}
						/>
					</Modal>
				</>
			)}
		</BaseLayout>
	);
};

export default VoyageDetail;
