import { VesselPosition } from '../../registries/vessels.types';

export interface VesselsState {
	positions: VesselPosition[];
	activePosition: VesselPosition;
	filter: string | null;
	showOwnVesselsOnly: boolean;
}

export const INITIAL_ACTIVE_POSITION: VesselPosition = {
	vessel: {
		name: '',
		imoNumber: '',
		mmsiNumber: '',
	},
	location: {
		destination: '',
		eta: '',
		latitude: 0,
		longitude: 0,
		speed: 0,
		course: 0,
		draught: 0,
		reportedAT: '',
	},
};

export function createInitialVesselsState(): VesselsState {
	return {
		positions: [],
		activePosition: INITIAL_ACTIVE_POSITION,
		filter: null,
		showOwnVesselsOnly: false,
	};
}
