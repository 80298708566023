import React, { ReactNode } from 'react';

import { hashString } from '../../../../../../core/helpers/generators/hash';
import { InfoColumnProps, InfoColumnContent } from './InfoColumn.types';
import styles from './InfoColumn.module.scss';

export const InfoColumn: React.FC<InfoColumnProps> = ({
	title,
	data,
	className,
}) => {
	const parseTable = (listItems: InfoColumnContent[]): ReactNode => {
		const items = listItems.map((listItem) => Object.keys(listItem).map((key: string, i: number) => (
			<tr key={`info-column--${hashString(`${i}${key}${listItem[key]}`)}`}>
				<td className={styles['a-list-item__title']}>
					{key}
				</td>
				<td className={styles['a-list-item__desc']}>
					{listItem[key]}
				</td>
			</tr>
		)));

		return (
			<table
				className="w-full"
			>
				<tbody>
					{items}
				</tbody>
			</table>
		);
	};

	return (
		<div className={className}>
			{
				title && (
					<h3 className="mb-4">
						{title}
					</h3>
				)
			}
			{
				Array.isArray(data)
					? parseTable(data)
					: parseTable([data])
			}
		</div>
	);
};
