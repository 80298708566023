export enum InfoSelectionOption {
	stay = 'stay',
	voyage = 'voyage',
	cargo = 'cargo(s)',
}

export interface InfoSelectionProps {
	options: InfoSelectionOption[];
	onSelect: (option: InfoSelectionOption) => void;
	selectedOption: InfoSelectionOption;
}
