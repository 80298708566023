import React, { ReactNode } from 'react';
import { isNil, isEmpty, pathOr } from 'ramda';
import cx from 'classnames';

import { displayDateTime } from '../../../voyage/components/ActivityList/ActivityListItem/ActivityList.helper';
import { timeBetween, AvailableTimeFormats } from '../../helpers/time/timeHelper';
import { DelayLabel } from '../DelayLabel/DelayLabel';

import { TimeOverviewData } from './TimeOverview.props';

import styles from './TimeOverview.module.scss';

const getClockColor = (estTime: string | null, actualTime: string | null): string => {
	const delay = timeBetween(estTime, actualTime);
	if (!estTime || !actualTime) {
		return 'gray';
	}

	if (delay < 5) {
		return 'green';
	}

	if (delay >= 5 && delay <= 20) {
		return 'orange';
	}

	if (delay > 20) {
		return 'red';
	}
	return 'gray';
};

export const renderEnd = (endTime: TimeOverviewData | undefined): boolean => !isNil(endTime);

const getTimeLabel = (actualTime: string | null): string => (actualTime ? 'Actual' : 'Estimated');

const hasTime = (
	time: TimeOverviewData,
	displayActual: boolean,
): boolean => {
	if (displayActual) {
		return !isEmpty(pathOr('', ['actualTime'], time));
	}

	return !isEmpty(pathOr(pathOr('', ['estTime'], time), ['actualTime'], time));
};

export const renderTime = (
	active: boolean | undefined,
	time: TimeOverviewData,
	first = false,
	renderAfter = false,
	renderPrefix: boolean,
	displayActual = false,
	hasActivity = false,
): ReactNode => {
	const {
		label,
		estTime,
		actualTime,
	} = time;

	return (
		<div
			data-color={getClockColor(estTime, actualTime)}
			className={cx(
				styles['m-time-overview'],
				styles[`m-time-overview${(first && renderAfter) && '--first'}`]
			)}
		>
			<div className={styles['a-time-container']}>
				<span
					className={cx(
						styles['a-activity-time-label'],
						styles[`a-activity-time-label${active && '--active'}`],
					)}
				>
					{renderPrefix && getTimeLabel(actualTime)} {label}
				</span>
				<div className={styles['a-activity-time-wrapper']}>
					{hasActivity || hasTime(time, displayActual) ? (
						<>
							<div>
								<span
									className={cx(
										styles['a-activity-time'],
										styles[`a-activity-time${active && '--active'}`],
									)}
								>
									{displayDateTime(
										estTime,
										actualTime,
										AvailableTimeFormats.time
									)}
								</span>
								{(estTime && actualTime) && timeBetween(
									estTime,
									actualTime,
								) !== 0 && (
									<DelayLabel
										estTime={estTime}
										realTime={actualTime}
									/>
								)}
							</div>
							<span
								className={cx(
									styles['a-activity-date'],
									styles[`a-activity-date${active && '--active'}`],
								)}
							>
								{displayDateTime(
									estTime,
									actualTime,
									AvailableTimeFormats.date,
								)}
							</span>
						</>
					) : (
						<span
							className={cx(
								styles['a-activity-time'],
								styles[`a-activity-time${active && '--active'}`],
							)}
						> -
						</span>
					)}
				</div>
			</div>
		</div>
	);
};
