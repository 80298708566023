import { isNil } from 'ramda';
import format from 'date-fns/format';
import { DateTimeWindow } from '../../core.types';


export enum AvailableTimeFormats {
	time,
	date,
	timeDateInWords,
	timeDateShort,
	timeDateInWordsShort,
}

export const TimeFormats = {
	[AvailableTimeFormats.time]: 'HH:mm',
	[AvailableTimeFormats.date]: 'eee, MMM d',
	[AvailableTimeFormats.timeDateInWords]: 'HH:mm, eeeeee d MMM',
	[AvailableTimeFormats.timeDateShort]: 'dd/MM, HH:mm',
	[AvailableTimeFormats.timeDateInWordsShort]: 'HH:mm, dd/MM',
};

export const formatTimeStamp = (
	date: string,
	dateFormat: string
): string => format(new Date(date), dateFormat);

export const timeBetween = (
	startTime: string | null,
	endTime: string | null,
): number => {
	if (isNil(startTime) || isNil(endTime)) {
		return 0;
	}

	const diff = Date.parse(endTime) - Date.parse(startTime);
	const minutes = Math.floor((diff / 1000 / 60));

	return minutes;
};

export const getActualOrEstimateTime = (
	{ beginActual, beginEstimate, endActual, endEstimate }: DateTimeWindow,
	getStart: boolean,
): Date => {
	if (getStart) {
		return new Date(beginActual || beginEstimate);
	}

	return new Date(endActual || endEstimate);
};
