import React, { useState } from 'react';

import { InfoModalProps } from './InfoModal.types';
import { InfoSelection } from './components/InfoSelection/InfoSelection';
import { InfoSelectionOption } from './components/InfoSelection/InfoSelection.types';
import { InfoRow } from './components/InfoRow/InfoRow';
import infoHelper from './helpers';

// import styles from './InfoModal.module.scss';

export const InfoModal: React.FC<InfoModalProps> = ({ dossier, voyage }) => {
	const [selectedOption, setSelectedOption] = useState<InfoSelectionOption>(InfoSelectionOption.stay);

	return (
		<>
			<InfoSelection
				options={[
					InfoSelectionOption.stay,
					InfoSelectionOption.voyage,
					InfoSelectionOption.cargo,
				]}
				selectedOption={selectedOption}
				onSelect={setSelectedOption}
			/>
			{ selectedOption === InfoSelectionOption.stay && (
				<InfoRow
					rows={[{
						left: {
							title: 'General information',
							data: infoHelper.parseGeneralInfo(voyage),
						},
						right: {
							title: 'Vessel details',
							data: infoHelper.parseVesselInfo(dossier),
						},
					}]}
				/>
			)}
			{ selectedOption === InfoSelectionOption.voyage && (
				<InfoRow
					rows={[{
						left: {
							data: infoHelper.parseVoyageInfo.voyageInfo(dossier, voyage),
						},
						right: {
							data: infoHelper.parseVoyageInfo.stakeHolders(dossier),
						},
					}]}
				/>
			)}
			{ selectedOption === InfoSelectionOption.cargo && (
				<InfoRow
					rows={infoHelper.parsaCargoInfo(dossier)}
				/>
			)}
		</>
	);
};
