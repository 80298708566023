import { uniqWith, eqProps } from 'ramda';

import { PAGE_SIZES } from '../../../core/core.const';
import { DossierDetailActivity, DossierDetailCargo } from '../../../lib/dossiers/registries/dossiers.types';
import { ModifiedCompany } from '../../../lib/companies/registries/companies.types';
import { LogQuery, ActivityLogType } from '../../../lib/logs/registries/logs.types';
import { ActivityType } from '../../../core/core.types';

export const getUniqueCompaniesFromDossierDetail = (activities: DossierDetailActivity[]):
ModifiedCompany[] => {
	const companies: ModifiedCompany[] = activities
		.reduce((prevState: ModifiedCompany[], currVal: DossierDetailActivity) => {
			const { lastAssigneeStakeholder } = currVal;

			if (lastAssigneeStakeholder) {
				return [...prevState, {
					// necessary to add custom id for companies that don't have a detail
					// if there are multiple with same id akita will only show one
					id: lastAssigneeStakeholder.name + lastAssigneeStakeholder.id,
					name: lastAssigneeStakeholder.name,
					vat: lastAssigneeStakeholder.vat,
					role: lastAssigneeStakeholder.supplyChainRole,
				}];
			}

			return prevState;
		}, []);

	return uniqWith<ModifiedCompany, ModifiedCompany>(eqProps('vat'))(companies);
};

export const getLogQuery = (
	dossierId: number,
	category: ActivityLogType,
	page = 1,
): LogQuery => {
	const query: LogQuery = {
		dossier: dossierId,
		'page-size': PAGE_SIZES.default,
		page,
	};

	if (category !== ActivityLogType.all) {
		query.category = category;
	}

	return query;
};

export const getCargo = (
	activity: DossierDetailActivity,
	cargo: DossierDetailCargo[]
): DossierDetailCargo | undefined => {
	const cargoTypes = [ActivityType.Discharging, ActivityType.Loading];

	if (
		!activity
		|| !activity.cargoId
		|| !cargoTypes.includes(activity.activityType)
	) {
		return undefined;
	}

	return cargo.find((item) => activity.cargoId === item.id);
};
