import { mount, redirect, map } from 'navi';

import coreRoutes from '../modules/core/core.routes';
import { CORE_ROUTES } from '../modules/core/core.const';

import voyageRoutes from '../modules/voyage/voyage.routes';
import { VOYAGE_ROUTES } from '../modules/voyage/voyage.const';

import berthRoutes from '../modules/berth/berth.routes';
import userRoutes from '../modules/user/user.routes';
import notificationsRoutes from '../modules/notifications/notifications.routes';
import arriveDepartRoutes from '../modules/arriveDepart/arriveDepart.routes';

export default mount({
	// fallback routes
	'/': redirect(VOYAGE_ROUTES.VOYAGE_OVERVIEW),
	'*': map(() => redirect(CORE_ROUTES.NOT_FOUND, {
		exact: false,
	})),

	// module routes
	...coreRoutes,
	...userRoutes,
	...voyageRoutes,
	...berthRoutes,
	...notificationsRoutes,
	...arriveDepartRoutes,
});
