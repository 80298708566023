import React, { Component, createRef, ReactNode, RefObject } from 'react';
import cx from 'classnames';
import { Timeline as VisTimeline, TimelineOptions, DataItem, DataGroup } from 'vis-timeline';
import { head, equals, differenceWith } from 'ramda';
import { isEqual as isEqualDate } from 'date-fns';

import {
	TIMELINE_DEFAULT_OPTIONS,
	TIMELINE_DEFAULT_START,
	TIMELINE_DEFAULT_END,
} from './Timeline.const';
import { TimelineProps, defaultProps } from './Timeline.props';
import { TimelineAxis } from './Timeline.types';
import { compareCustomTimeItems } from './Timeline.helper';

import './Timeline.scss';

class Timeline extends Component<TimelineProps, {}> {
	static defaultProps: TimelineProps = defaultProps;

	private timelineRef: RefObject<HTMLDivElement>;

	private timelineInstance: VisTimeline & Partial<TimelineAxis> | null;

	constructor(props: TimelineProps) {
		super(props);
		this.timelineRef = createRef<HTMLDivElement>();
		this.timelineInstance = null;
	}

	componentDidMount(): void {
		// set all inital stuff here:
		const {
			options,
			startDate,
			endDate,
			onTimelineViewportChange,
			onTimelineItemClick,
		} = this.props;

		const timelineStart = startDate || TIMELINE_DEFAULT_START;
		const timelineEnd = endDate || TIMELINE_DEFAULT_END;

		// timeline initialisation
		if (this.timelineRef.current) {
			this.timelineInstance = new VisTimeline(
				this.timelineRef.current,
				[],
				{
					...TIMELINE_DEFAULT_OPTIONS,
					...options,
					start: timelineStart,
					end: timelineEnd,
				}
			);

			this.timelineInstance.on('rangechanged', ({ start, end }) => {
				onTimelineViewportChange({
					startDate: start,
					endDate: end,
				});
			});

			this.timelineInstance.on('select', ({ items: selectItems }) => {
				const id = head(selectItems);

				if (!id) {
					return;
				}

				onTimelineItemClick(id);
			});

			this.updateDataIfNeeded([], [], [], []);
		}
	}

	componentDidUpdate(prevProps: TimelineProps): void {
		if (!this.timelineInstance) {
			return;
		}

		const { items, groups, options, alerts, passagePoints } = prevProps;

		// Check for prop changes
		this.updateDataIfNeeded(items, groups, (alerts || []), (passagePoints || []));
		this.updateOptionsIfNeeded(options || {});

		this.updateViewportIfNeeded();
	}

	componentWillUnmount(): void {
		if (this.timelineInstance) {
			this.timelineInstance.destroy();
		}
	}

	setTimeRange(startDate: Date | null, endDate: Date | null): void {
		if (!this.timelineInstance || !startDate || !endDate) {
			return;
		}

		this.timelineInstance.setWindow(startDate, endDate, {
			animation: false,
		});
	}

	showAllItems(): void {
		const { items } = this.props;
		if (!this.timelineInstance || items.length === 0) {
			return;
		}

		this.timelineInstance.fit({
			animation: false,
		});
	}

	updateDataIfNeeded(
		prevItems: DataItem[],
		prevGroups: DataGroup[],
		prevAlerts: DataItem[],
		prevPassagePoints: DataItem[],
	): void {
		if (!this.timelineInstance) {
			return;
		}

		const { items, groups, alerts, passagePoints } = this.props;

		if (!equals(prevItems, items) || !equals(prevAlerts, alerts) || !equals(prevPassagePoints, passagePoints)) {
			if (!alerts || !passagePoints) {
				this.timelineInstance.setItems(items);
				return;
			}

			if (!equals(prevPassagePoints, passagePoints)) {
				const newItems = differenceWith(compareCustomTimeItems, passagePoints, prevPassagePoints);

				newItems.forEach((item) => {
					if (this.timelineInstance) {
						this.timelineInstance.addCustomTime(item.start, item.id);
					}
				});
			}

			if (!equals(prevAlerts, alerts)) {
				alerts.forEach((item) => {
					if (this.timelineInstance) {
						this.timelineInstance.addCustomTime(item.start, item.id);
					}
				});
			}

			this.timelineInstance.setItems([...alerts, ...passagePoints, ...items]);
		}

		if (!equals(prevGroups, groups)) {
			this.timelineInstance.setGroups(groups);
		}
	}

	updateOptionsIfNeeded(prevOptions: TimelineOptions): void {
		if (!this.timelineInstance) {
			return;
		}

		const { options, omitCluster, setCluster } = this.props;

		if (!equals(prevOptions, options)) {
			this.timelineInstance.setOptions(options || {});
		}

		if (
			this.timelineInstance.timeAxis
			&& this.timelineInstance.timeAxis.step
			&& omitCluster
			&& setCluster
		) {
			const { scale } = this.timelineInstance.timeAxis.step;
			const shouldCluster = (scale === 'minute' || scale === 'second');

			if (options) {
				if (shouldCluster && options.cluster) {
					omitCluster();
				} else if (!shouldCluster && !options.cluster) {
					setCluster();
				}
			}
		}
	}

	updateViewportIfNeeded(): void {
		if (!this.timelineInstance) {
			return;
		}

		const { start, end } = this.timelineInstance.getWindow();
		const { startDate, endDate } = this.props;

		// no time changes
		if (startDate && endDate && (isEqualDate(start, startDate) && isEqualDate(end, endDate))) {
			return;
		}

		// At least one of the dates to be shown are null -> show overview
		if (!(startDate || endDate)) {
			this.showAllItems();
			return;
		}

		// Set timeline viewport
		this.setTimeRange(startDate, endDate);
	}

	render(): ReactNode {
		const { timelineClass } = this.props;
		return (
			<div
				className={cx(
					'o-timeline -highlight bg-white w-full shadow-md rounded-lg overflow-hidden',
					timelineClass
				)}
			>
				<div ref={this.timelineRef} />
			</div>
		);
	}
}

export { Timeline };
