import React from 'react';
import cx from 'classnames';
import { isNil } from 'ramda';

import { TimeOverviewProps } from './TimeOverview.props';
import { renderTime, renderEnd } from './TimeOverview.helper';

import styles from './TimeOverview.module.scss';

export const TimeOverview: React.FC<TimeOverviewProps> = ({
	active,
	startTime,
	endTime,
	activityType,
	renderPrefix,
	displayActual,
	clockWrapperBackground = 'white',
}) => (
	<div
		className={cx(
			{
				'u-grid-container': true,
				[styles['m-time-overview__wrapper']]: true,
				[styles['m-time-overview__wrapper--transparent']]: clockWrapperBackground === 'transparent',
			}
		)}
	>
		{
			startTime
			&& renderTime(
				active,
				startTime,
				true,
				renderEnd(endTime),
				renderPrefix,
				displayActual,
				!isNil(activityType),
			)
		}
		{
			endTime
			&& renderTime(
				active,
				endTime,
				false,
				false,
				renderPrefix,
				displayActual,
				!isNil(activityType),
			)
		}
	</div>
);
