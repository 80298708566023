import React, { FC } from 'react';
import cx from 'classnames/bind';

import { ToggleProps } from './Toggle.props';
import styles from './Toggle.module.scss';

const cxBind = cx.bind(styles);

export const Toggle: FC<ToggleProps> = ({
	children,
	value,
	disabled = false,
	onChange = (): void => {},
	name,
}) => (
	<label htmlFor={name}>
		{children}
		<div className={cxBind('a-toggle')}>
			<input
				type="checkbox"
				id={name}
				className={cxBind('a-toggle__input')}
				checked={value}
				onChange={(val): void => onChange(val.target.checked)}
				disabled={disabled}
			/>
			<div className={cxBind('a-toggle__slider', 'a-toggle__slider--round')} />
		</div>
	</label>
);
