import { Observable } from 'rxjs';

import { QronoPortService } from '../../../core/services/api-service/qronoport.service';
import { processResponse } from '../../../core/services/helpers';

import { VesselPosition } from './vessels.types';

export class VesselsRegistry extends QronoPortService {
	public fetchPositions(): Observable<VesselPosition[]> {
		return this.api.get<VesselPosition[]>('vessels/position')
			.pipe(processResponse<VesselPosition[]>());
	}

	public fetchPosition(imo: string): Observable<VesselPosition> {
		return this.api.get<VesselPosition>(`vessels/position/${imo}`)
			.pipe(processResponse<VesselPosition>());
	}
}

export const vesselsRegistry = new VesselsRegistry();
