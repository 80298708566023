import { pathOr } from 'ramda';

import { DossierDetail, DossierDetailCargo } from '../../../../lib/dossiers/registries/dossiers.types';
import { InfoColumnContent } from '../components/InfoRow/InfoColumn/InfoColumn.types';
import { InfoModalData } from '../InfoModal.types';

/* eslint-disable quote-props */
export const parseCargoOperationInfo = (cargo: DossierDetailCargo): InfoColumnContent => ({
	'Nr. of shiptank': pathOr('-', ['tankCount'], cargo),
	'Nr. of samples': pathOr('-', ['sampling', 'numberOfSamples'], cargo),
	'Circulation dur.': pathOr('-', ['cargo', 'circulationMinutes'], cargo),
	'Type of analysis': pathOr('-', ['analyse', 'typeOfAnalyse'], cargo),
	'Connection responsible': pathOr('-', ['cargo', 'responsibleConnection'], cargo),
	'Required connection': pathOr('-', ['requiredConnectionType'], cargo),
});

export const parseCargoGeneralInfo = (cargo: DossierDetailCargo): InfoColumnContent => ({
	'Operation type': pathOr('-', ['operation'], cargo),
	'Product name': pathOr('-', ['contentName'], cargo),
	'Imo class': pathOr('-', ['vesselImoClass'], cargo),
	'Un number': pathOr('-', ['unNumber'], cargo),
	'Quantity': `${pathOr('-', ['quantity'], cargo)} ${pathOr('-', ['quantityUnit'], cargo)}`,
	'Heated': pathOr(false, ['isHeated'], cargo) ? `${pathOr('-', ['temperatureCelcius'], cargo)} °C` : '-',
	'Port of loading': pathOr('-', ['portOfLoading'], cargo),
	'Port of discharge': pathOr('-', ['portOfDischarge'], cargo),
});
/* eslint-enable quote-props */

export default (dossier: DossierDetail): InfoModalData[] => {
	const allCargo = pathOr([], ['cargo'], dossier);

	return allCargo.map((cargo, i) => ({
		left: {
			data: parseCargoGeneralInfo(cargo),
		},
		right: {
			data: parseCargoOperationInfo(cargo),
		},
		rowTitle: `Cargo ${i + 1}`,
	}));
};
