import { propOr, pathOr } from 'ramda';

import { InfoColumnContent } from '../components/InfoRow/InfoColumn/InfoColumn.types';
import { Voyage } from '../../../../lib/voyages/registries/voyages.types';

/* eslint-disable quote-props */
export default (voyage: Voyage | null): InfoColumnContent => ({
	'Stay number': propOr('-', 'stayNumber', voyage),
	'Origin': pathOr('-', ['originPortName', 'name'], voyage),
	'Destination': pathOr('-', ['destinationPortName', 'name'], voyage),
	'ETA': pathOr('-', ['voyageEstimateArrivalDateTime'], voyage),
	'ETD': pathOr('-', ['voyageEstimateDepartureDateTime'], voyage),
});
/* eslint-enable quote-props */
