import React, { FC, useState, ReactNode } from 'react';
import { isNil, head } from 'ramda';

import { ArrowLeft } from '../../../../assets/images';
import { useDossierDetail } from '../../../lib/dossiers/store/dossier/dossier.hooks';
import { useLogsLoading } from '../../../lib/logs/hooks';
import { useDossierLogs, useLogQuery, useSingleDossier } from '../../../lib/logs/store/logs.hooks';

import { PageHeader, RouterLink, Badge, Dropdown, Loader, Button } from '../../../core/components';
import { InfoBox, ActivityLogList } from '../../components';
import { BaseLayout } from '../../../core/layouts';

import { BadgeType } from '../../../core/components/Badge/Badge.props';
import { DossierDetail } from '../../../lib/dossiers/registries/dossiers.types';
import { ActivityLogType, Log, LogQuery, DossierLog } from '../../../lib/logs/registries/logs.types';
import { activityLogFilters } from '../../components/ActivityList/ActivityList.const';
import { ButtonTypes, ButtonSizes, ButtonWeights } from '../../../core/components/Button/Button.types';
import { logsService } from '../../../lib/logs/store/logs.service';

const markLogsAsRead = (unreadLogs: Log[], dossierId: number): void => {
	const unreadLogIds = unreadLogs.map((log) => log.id);
	const unreadItems = {
		id: dossierId,
		logs: unreadLogIds,
	};

	logsService.readAll([unreadItems]);
};

const VoyageActivityLog: FC = () => {
	const [filterType, setFilterType] = useState();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const dossier: DossierDetail | null = useDossierDetail(false);
	const isLoading: boolean = useLogsLoading();
	const logQuery: LogQuery = useLogQuery(dossier, filterType, currentPage);
	const dossierLogs: DossierLog[] = useDossierLogs(logQuery);
	const currentDossierLogs = head(dossierLogs);
	const logs: Log[] = useSingleDossier(currentDossierLogs);

	if (!dossier) {
		return null;
	}

	const { requestingStakeholderReference } = dossier;
	const unreadLogs = logs.filter((log) => !log.isReadByCurrentStakeholder);

	const handleLogFilter = (category: ActivityLogType): void => {
		setCurrentPage(1);
		setFilterType(category);
	};

	const nextLogsPage = (): void => {
		const nextPage = currentDossierLogs.page + 1;
		setCurrentPage(nextPage);
	};

	const loadMoreButton = (): ReactNode => {
		if (currentDossierLogs && currentDossierLogs.isLastPage) {
			return null;
		}

		return (
			<Loader loading={isLoading}>
				<Button
					type={ButtonTypes.Secondary}
					size={ButtonSizes.small}
					action={(): void => nextLogsPage()}
				>
					Load more
				</Button>
			</Loader>
		);
	};

	return (
		<BaseLayout>
			<PageHeader>
				<div className="flex flex-row justify-center items-center">
					<RouterLink
						className="mr-4"
						href={`/voyages/${dossier.id}`}
					>
						<ArrowLeft width="12" height="12" />
					</RouterLink>
					<h1>
						Activity Log
						<small className="ml-2 font-normal lowercase text-blue">
							{!isNil(requestingStakeholderReference)
								? head(requestingStakeholderReference)
								: '-'}/
							{dossier.vessel.name}
						</small>
					</h1>
					<Badge
						type={BadgeType.Red}
						isAlert
						className="ml-4"
					>
						{unreadLogs.length}
					</Badge>
				</div>
				<div>
					<Dropdown
						items={activityLogFilters}
						onClick={(type): void => handleLogFilter(type)}
						name="ActivityLogFilter"
					/>
					<Button
						className="ml-8"
						type={ButtonTypes.Secondary}
						size={ButtonSizes.small}
						weight={ButtonWeights.light}
						action={(): void => markLogsAsRead(unreadLogs, dossier.id)}
					>
						Mark all as read
					</Button>
				</div>
			</PageHeader>
			<div className="w-full">
				<InfoBox fullWidth>
					<ActivityLogList logs={logs} />
				</InfoBox>
			</div>
			{loadMoreButton()}
		</BaseLayout>
	);
};

export default VoyageActivityLog;
