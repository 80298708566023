import { Observable } from 'rxjs';

import { NextPortService } from '../../../core/services/api-service/nextport.service';
import { processResponse } from '../../../core/services/helpers';

import { Log, LogQuery } from './logs.types';

export class LogsRegistry extends NextPortService {
	public fetchAll(qs: LogQuery = {}): Observable<Log[]> {
		return this.api.get<Log[]>('logs', {
			qs,
			headers: this.headers,
		})
			.pipe(processResponse<Log[]>());
	}

	public readAll(unreadItems: number[] = []): void {
		this.api.post('read-logs', {
			body: unreadItems,
			headers: this.headers,
		})
			.subscribe();
	}
}

export const logsRegistry = new LogsRegistry();
