import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { ApiError } from '../../../../core/services/api.types';
import { ModifiedCompany } from '../../registries/companies.types';
import { CompaniesState } from './companies.model';
import { CompaniesStore, companiesStore } from './companies.store';

export class CompaniesQuery extends QueryEntity <CompaniesState> {
	constructor(protected store: CompaniesStore) {
		super(store);
	}

	public isLoading$: Observable<boolean> = this.selectLoading();

	public hasError$: Observable<ApiError> = this.selectError();

	public companies$: Observable<ModifiedCompany[]> = this.selectAll();
}

export const companiesQuery = new CompaniesQuery(companiesStore);
