import {
	formatTimeStamp,
	AvailableTimeFormats,
	TimeFormats,
} from '../../../../core/helpers/time/timeHelper';

export const displayDateTime = (
	estTime: string | null,
	actualTime: string | null,
	type: AvailableTimeFormats,
): string => {
	if (actualTime) {
		return formatTimeStamp(actualTime, TimeFormats[type]);
	}

	return estTime ? formatTimeStamp(estTime, TimeFormats[type]) : '-';
};
