import { DossierLogType } from '../../../core/core.types';
import { ActivityLogType } from '../registries/logs.types';

export const LOG_TYPES = {
	[ActivityLogType.general]: [
		DossierLogType.DossierEnded,
		DossierLogType.DossierGranted,
		DossierLogType.DossierRevoked,
		DossierLogType.DossierStakeholderAdded,
		DossierLogType.DossierStakeholderRemoved,
		DossierLogType.DossierUpdated,
	],
	[ActivityLogType.activity]: [
		DossierLogType.ActivityConflict,
		DossierLogType.ActivityCreated,
		DossierLogType.ActivityDeleted,
		DossierLogType.ActivityEndEstimateUpdated,
		DossierLogType.ActivityEnded,
		DossierLogType.ActivityStartEstimateUpdated,
		DossierLogType.ActivityStarted,
		DossierLogType.AlertCreated,
	],
};
