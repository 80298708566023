import React from 'react';

import { INITIAL_DOSSIERS_OPTIONS } from '../../../lib/dossiers/store/dossiers/dossiers.model';
import { PinPoint } from '../../../../assets/images';

import { ZoomRegionButton } from './components/ZoomRegionButtons/ZoomRegionButtons.types';

export const MAP_OVERVIEW_DOSSIERS_OPTIONS = {
	...INITIAL_DOSSIERS_OPTIONS,
	'page-size': 30,
};

export const MAP_OVERVIEW_DISABLE_CLUSTERING_ZOOM_LEVEL = 15;

// Predefined locations
export const MAP_ZOOM_REGION_WORLD_CENTER: [number, number] = [51.50532341149335, 3.6145019531250004];
export const MAP_ZOOM_REGION_WORLD_ZOOM = 10;

export const MAP_ZOOM_REGION_ANCHORAGE_CENTER: [number, number] = [51.558289817660686, 3.0953979492187504];
export const MAP_ZOOM_REGION_ANCHORAGE_ZOOM = 10;

export const MAP_ZOOM_REGION_PORT_CENTER: [number, number] = [51.30099875579057, 4.196777343750001];
export const MAP_ZOOM_REGION_PORT_ZOOM = 11;

export const MAP_OVERVIEW_ZOOM_REGION_BUTTONS: ZoomRegionButton[] = [
	{
		text: 'World',
		center: MAP_ZOOM_REGION_WORLD_CENTER,
		zoom: MAP_ZOOM_REGION_WORLD_ZOOM,
		icon: <PinPoint />,
	},
	{
		text: 'Anchorage',
		center: MAP_ZOOM_REGION_ANCHORAGE_CENTER,
		zoom: MAP_ZOOM_REGION_ANCHORAGE_ZOOM,
		icon: <PinPoint />,
	},
	{
		text: 'Port',
		center: MAP_ZOOM_REGION_PORT_CENTER,
		zoom: MAP_ZOOM_REGION_PORT_ZOOM,
		icon: <PinPoint />,
	},
];
