import React, { FC, createRef } from 'react';
import { Map as LeafletMap, Marker } from 'react-leaflet';
import cx from 'classnames/bind';

import { useNavigation } from 'react-navi';
import { Map, Button } from '../../../core/components';
import styles from './VoyageDetailMapSection.module.scss';
import { getUniqueVesselKey } from '../../../berth/pages/MapOverview/helpers';
import { MapOverviewMarkerColors } from '../../../berth/pages/MapOverview/MapOveriew.types';
import { ArrowMarker } from '../../../core/components/Map/markers';
import {
	MAP_ZOOM_REGION_PORT_CENTER,
	MAP_ZOOM_REGION_PORT_ZOOM,
} from '../../../berth/pages/MapOverview/MapOverview.const';

import { VoyageDetailMapSectionProps } from './VoyageDetailMapSection.props';
import { useVesselPositionIsLoading, useVesselPosition } from './VoyageDetailMapSection.hooks';
import { ButtonTypes, ButtonWeights } from '../../../core/components/Button/Button.types';
import { PinPoint } from '../../../../assets/images';

const cxBind = cx.bind(styles);

export const VoyageDetailMapSection: FC<VoyageDetailMapSectionProps> = ({
	dossier,
}) => {
	const navigation = useNavigation();
	const mapInstance = createRef<LeafletMap>();
	const isLoading = useVesselPositionIsLoading();
	const vessel = useVesselPosition(dossier.vessel.imo);

	const handleGoToMap = (): void => {
		navigation.navigate('/map-overview');
	};

	return (
		<>
			{dossier && (
				<section className="w-full mt-12">
					<header className="mb-4">
						<h2>Map</h2>
					</header>

					<div className="flex w-full">
						<div className={cxBind('m-voyage-map')}>
							<Map
								center={
									vessel
										? [vessel.location.latitude, vessel.location.longitude]
										: MAP_ZOOM_REGION_PORT_CENTER
								}
								zoom={MAP_ZOOM_REGION_PORT_ZOOM}
								ref={mapInstance}
								loading={isLoading}
								loadingContent={(
									isLoading && <p>Fetching vessel</p>
								)}
								message={(
									(!isLoading && !vessel) && <p>Could not find your vessel</p>
								)}
								topLeftContentOnTop
								topLeftCornerContent={(
									<Button
										type={ButtonTypes.Default}
										weight={ButtonWeights.light}
										icon={<PinPoint />}
										action={handleGoToMap}
									>
										Show all vessels
									</Button>
								)}
							>
								{ vessel && (
									<Marker
										key={getUniqueVesselKey(vessel)}
										position={[vessel.location.latitude, vessel.location.longitude]}
										icon={ArrowMarker(MapOverviewMarkerColors.Red, vessel.location.course)}
										onClick={(): void => {}}
									/>
								)}
							</Map>
						</div>
						<div className={cxBind('m-map-legend')}>
							<div className={cxBind('m-map-legend--item', 'm-map-legend--item__red-dot')}>
								<span>
									Own vessel at berth
								</span>
							</div>
							<div className={cxBind('m-map-legend--item', 'm-map-legend--item__red-arrow')}>
								<span>
									Own moving vessel
								</span>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
};
