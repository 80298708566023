import { TimelineOptions } from 'vis-timeline';

import { TIMELINE_ALERTS_ID } from '../Timeline/Timeline.const';

export const DETAIL_ACTIVITY_TIMELINE_OPTIONS: TimelineOptions = {
	stack: false,
	stackSubgroups: false,
	tooltip: {
		overflowMethod: 'cap',
		followMouse: true,
		delay: 250,
	},
	groupOrder: (a, b) => a.order - b.order,
	cluster: {
		clusterCriteria: (firstItem, secondItem): boolean => {
			const isPassagePointId = (
				firstItem.group === TIMELINE_ALERTS_ID
				&& secondItem.group === TIMELINE_ALERTS_ID
			);

			return (
				isPassagePointId
				&& (firstItem.group === secondItem.group)
			);
		},
	},
};
