import React from 'react';
import { renderToString } from 'react-dom/server';
import { DataGroup } from 'vis-timeline';

import { ActivityTimelineGroup } from './ActivityTimelineGroup';
import { UniqueStakeHolder } from '../ActivityTimeline.types';

import { Stakeholder, SupplyChainRole } from '../../../core.types';
import { DossierDetailActivity, DossierAlert } from '../../../../lib/dossiers/registries/dossiers.types';
import { getCompanyColor } from '../../../../lib/companies/helpers/getColor';
import { formatName } from '../../../helpers/text/textHelper';
import { hashString } from '../../../helpers/generators/hash';
import { TIMELINE_ALERTS_ID } from '../../Timeline/Timeline.const';
import { VoyagePassage } from '../../../../lib/voyages/registries/voyages.types';

export interface TimelineGroup extends DataGroup {
	order: number;
}

// render timeline groups
const renderGroups = (stakeholder: Stakeholder): string => (
	renderToString(<ActivityTimelineGroup stakeholder={stakeholder} />)
);

// Create array of unique stakeholders so we can group their activities (based on id)
// A unique stakeholder is a stakeholder with a unique id and supplyChainRole.
// For stakeholders with equally id's, we should check the supplyChainRole.
export const mapActivityTimelineGroups = (
	activities: DossierDetailActivity[],
	alerts: DossierAlert[],
	passagePoints: VoyagePassage[],
): DataGroup[] => {
	// create unique id's for stakeholders
	const stakeholders: UniqueStakeHolder[] = activities.map((item: DossierDetailActivity) => ({
		...item.lastAssigneeStakeholder,
		uniqueId: item.lastAssigneeStakeholder.id + item.lastAssigneeStakeholder.supplyChainRole,
	}));

	// filter out duplicates
	const uniq = stakeholders.reduce((acc: UniqueStakeHolder[], curr: UniqueStakeHolder) => (
		acc.find((s: UniqueStakeHolder) => s.uniqueId === curr.uniqueId) ? acc : acc.concat(curr)
	), []);

	const alertGroup = (alerts.length > 0 || passagePoints.length > 0) ? [{
		id: TIMELINE_ALERTS_ID,
		className: 'vis-alert-group',
		content: '',
		subgroupStack: true,
		order: 0,
	}] : [];


	const stakeholderGroups = uniq.map((stakeholder: Stakeholder, idx) => {
		const color = getCompanyColor(stakeholder.supplyChainRole as SupplyChainRole);
		const stakeholderRole = formatName(stakeholder.supplyChainRole, 'LB_');

		return {
			id: hashString(stakeholder.id + stakeholder.supplyChainRole),
			title: `${stakeholder.name} (${stakeholderRole})`,
			className: `vis-label__border--${color}`,
			content: renderGroups(stakeholder),
			subgroupStack: false,
			order: idx + 1,
		};
	});

	return [
		...alertGroup,
		...stakeholderGroups,
	];
};
