import React, { useState } from 'react';
import cx from 'classnames/bind';

import { ArrowDown } from '../../../../assets/images';

import { DropdownProps } from './Dropdown.props';
import { DropdownItem } from './Dropdown.types';
import styles from './Dropdown.module.scss';

const cxBind = cx.bind(styles);

export const Dropdown: React.FC<DropdownProps> = ({
	items,
	onClick,
	name = '',
}) => {
	const [activeItem, setActiveItem] = useState<DropdownItem>(items[0]);

	return (
		<div className={cxBind('m-toggle-filter__wrapper')}>
			<div className={cxBind('toggle-filter')}>
				{ activeItem.title }
				<ArrowDown />
			</div>
			<ul
				className={cxBind('toggle-filter__dropdown')}
			>
				{
					items.map((item) => (
						<li
							className={cxBind({
								'toggle-filter__dropdown__item': true,
								'toggle-filter__dropdown__item--active': activeItem.id === item.id,
							})}
							key={`${name}_${item.title}_${item.id}`}
						>
							<button
								className={cxBind('toggle-filter__dropdown__item__button')}
								type="button"
								onClick={(): void => {
									onClick(item.id);
									setActiveItem(item);
								}}
							>
								{item.title}
							</button>
						</li>
					))
				}
			</ul>
		</div>
	);
};
