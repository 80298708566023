import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { userQuery } from '../store';

export const useIsAuthenticated = (): boolean | null => {
	const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null);

	useEffect(
		() => {
			const destroyed$: Subject<boolean> = new Subject<boolean>();

			userQuery.isAuthenticated$
				.pipe(
					takeUntil(destroyed$),
					filter((authenticated: boolean) => authenticated !== isAuthenticated)
				)
				.subscribe((authenticated: boolean) => {
					setIsAuthenticated(authenticated);
				});

			return (): void => {
				destroyed$.next(true);
				destroyed$.complete();
			};
		},
		[isAuthenticated]
	);

	return isAuthenticated;
};
