import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { vesselsQuery } from '../../../lib/vessels/store/vessels/vessels.query';
import { vesselsService } from '../../../lib/vessels/store/vessels/vessels.service';
import { VesselPosition } from '../../../lib/vessels/registries/vessels.types';

export const useVesselPositionIsLoading = (): boolean => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const destroyed$: Subject<boolean> = new Subject<boolean>();

		vesselsQuery.selectLoading()
			.pipe(takeUntil(destroyed$))
			.subscribe((loading: boolean) => (
				setIsLoading(loading)
			));

		return (): void => {
			destroyed$.next(true);
			destroyed$.complete();
		};
	}, []);

	return isLoading;
};

export const useVesselPosition = (imoNumber: string): VesselPosition | null => {
	const [currentVessel, setCurrentVessel] = useState<VesselPosition | null>(null);

	useEffect(() => {
		const destroyed$: Subject<boolean> = new Subject<boolean>();

		vesselsService.getOnePosition(imoNumber);

		vesselsQuery.activePosition$
			.pipe(takeUntil(destroyed$))
			.subscribe((position: VesselPosition) => {
				if (position.vessel.imoNumber === imoNumber) {
					setCurrentVessel(position);
				} else setCurrentVessel(null);
			});
	}, [imoNumber]);

	return currentVessel;
};
