import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { VesselPosition } from '../../registries/vessels.types';

import { VesselsStore, vesselsStore } from './vessels.store';
import { VesselsState } from './vessels.model';

export class VesselsQuery extends Query<VesselsState> {
	constructor(protected store: VesselsStore) {
		super(store);
	}

	public positions$: Observable<VesselPosition[]> = this.select('positions');

	public activePosition$: Observable<VesselPosition> = this.select('activePosition');

	public filter$: Observable<string | null> = this.select('filter');

	public showOwnVesselsOnly$: Observable<boolean> = this.select('showOwnVesselsOnly');
}

export const vesselsQuery = new VesselsQuery(vesselsStore);
