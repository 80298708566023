import { propOr, pathOr, uniqBy } from 'ramda';

import { splitCamelCaseString } from '../../../../core/helpers/text/textHelper';

import { DossierDetail } from '../../../../lib/dossiers/registries/dossiers.types';
import { InfoColumnContent } from '../components/InfoRow/InfoColumn/InfoColumn.types';
import { Voyage } from '../../../../lib/voyages/registries/voyages.types';

/* eslint-disable quote-props */
export const voyageInfo = (dossier: DossierDetail, voyage: Voyage | null): InfoColumnContent => ({
	'Qrono reference': propOr('-', 'dossierNumber', dossier),
	'Voyage  type': propOr('-', 'voyageType', dossier),
	'Voyage number': propOr('-', 'voyageNumber', dossier),
	'Berth': propOr('-', 'berth', dossier),
	'ETA berth': pathOr('-', ['voyages', 'fromLocation', 'estimatedTime'], voyage),
	'ETD berth': pathOr('-', ['voyages', 'toLocation', 'estimatedTime'], voyage),
});

export const stakeHolders = (dossier: DossierDetail): InfoColumnContent[] => {
	const filteredCompanies = dossier.activities.filter((activity) => !!activity.lastAssigneeStakeholder);
	const filteredDuplicateCompanies = uniqBy((item) => (
		pathOr('', ['lastAssigneeStakeholder', 'supplyChainRole'], item)
		+ pathOr('', ['lastAssigneeStakeholder', 'name'], item)
	), filteredCompanies);

	const companies = filteredDuplicateCompanies.map((activity) => {
		const name = pathOr('-', ['lastAssigneeStakeholder', 'name'], activity);
		const role = pathOr('role', ['lastAssigneeStakeholder', 'supplyChainRole'], activity);

		return {
			[splitCamelCaseString(role)]: name,
		};
	});

	return companies;
};
/* eslint-enable quote-props */

export default {
	voyageInfo,
	stakeHolders,
};
