import React, { FC } from 'react';
import cx from 'classnames/bind';

import { Search } from '../../../../assets/images';

import { SearchBarProps } from './SearchBar.props';

import styles from './SearchBar.module.scss';

const SearchBarStyles = cx.bind(styles);

export const SearchBar: FC<SearchBarProps> = ({
	id,
	placeholder,
	onChange,
	className = '',
}) => (
	<div className={SearchBarStyles('a-search-bar', className)}>
		<input
			className={SearchBarStyles('a-search-bar__input')}
			type="search"
			id={id}
			placeholder={placeholder}
			onChange={onChange}
		/>
		<Search className={SearchBarStyles('a-search-bar__icon')} />
	</div>
);
