import React from 'react';
import cx from 'classnames/bind';

import { splitCamelCaseString, formatName } from '../../../../core/helpers/text/textHelper';
import { timeBetween, AvailableTimeFormats } from '../../../../core/helpers/time/timeHelper';
import { getActivityIcon } from '../../../../core/helpers/icon/iconHelper';
import { DelayLabel } from '../../../../core/components';
import { ENTER_KEYCODE } from '../../../../../App.const';
import { ReactComponent as Warning } from '../../../../../assets/images/warning.svg';

import { ActivityListItemProps } from './ActivityListItem.types';
import { displayDateTime } from './ActivityList.helper';

import styles from './ActivityListItem.module.scss';

const cxBind = cx.bind(styles);

export const ActivityListItem: React.FC<ActivityListItemProps> = ({
	activityData,
	cargoContentName,
	alerts,
	color,
	onClick = (): void => {},
}) => {
	const { id, activityType, dateTimeWindow, lastAssigneeStakeholder } = activityData;
	const hasAlert = alerts.length > 0;

	let activityName = splitCamelCaseString(activityType);
	if (cargoContentName) activityName += ` - ${cargoContentName}`;
	return (
		<div
			className={cxBind(
				'm-activity-list-item',
				`m-activity-list-item--${color}`,
			)}
			onClick={(): void => onClick(id)}
			onKeyDown={({ keyCode }): void => {
				if (keyCode === ENTER_KEYCODE) {
					onClick(id);
				}
			}}
			role="button"
			tabIndex={0}
		>
			<div className={cxBind('a-activity-list-content')}>
				<div className={cxBind('a-activity-info', 'a-activity-type')}>
					{getActivityIcon(activityType)}
					<span>
						{activityName}
					</span>
				</div>
				<div className={cxBind('a-activity-info', 'a-activity-date-time')}>
					<span className={cxBind('a-activity-date')}>
						{displayDateTime(
							dateTimeWindow.beginEstimate,
							dateTimeWindow.beginActual,
							AvailableTimeFormats.date,
						)}
					</span>
					<div className={cxBind('a-activity-time-wrapper')}>
						<span className={cxBind('a-activity-time')}>
							{displayDateTime(
								dateTimeWindow.beginEstimate,
								dateTimeWindow.beginActual,
								AvailableTimeFormats.time,
							)}
						</span>
						{timeBetween(
							dateTimeWindow.beginEstimate,
							dateTimeWindow.beginActual
						) !== 0 && (
							<DelayLabel
								estTime={dateTimeWindow.beginEstimate}
								realTime={dateTimeWindow.beginActual}
							/>
						)}
					</div>
				</div>
				<div className={cxBind('a-activity-info', 'a-activity-date-time')}>
					<span className={cxBind('a-activity-date')}>
						{displayDateTime(
							dateTimeWindow.endEstimate,
							dateTimeWindow.endActual,
							AvailableTimeFormats.date,
						)}
					</span>
					<div className={cxBind('a-activity-time-wrapper')}>
						<span className={cxBind('a-activity-time')}>
							{displayDateTime(
								dateTimeWindow.endEstimate,
								dateTimeWindow.endActual,
								AvailableTimeFormats.time,
							)}
						</span>
						{timeBetween(
							dateTimeWindow.endEstimate,
							dateTimeWindow.endActual
						) !== 0 && (
							<DelayLabel
								estTime={dateTimeWindow.endEstimate}
								realTime={dateTimeWindow.endActual}
							/>
						)}
					</div>
				</div>
				<div className={cxBind('a-activity-info', 'a-activity-alert')}>
					{hasAlert && (
						<Warning className={cxBind('a-activity-alert-icon')} />
					)}
				</div>
				<div className={cxBind('a-activity-info', 'a-activity-agent-info')}>
					<span>
						{formatName(lastAssigneeStakeholder.supplyChainRole, 'LB_')}
					</span>
					<span>
						{lastAssigneeStakeholder.name}
					</span>
				</div>
			</div>
		</div>
	);
};
