import React from 'react';
import cx from 'classnames';
import hash from 'object-hash';

import { InfoRowProps, InfoRowData } from './InfoRow.types';
import { InfoColumn } from './InfoColumn/InfoColumn';

import styles from './InfoRow.module.scss';

export const InfoRow: React.FC<InfoRowProps> = ({ rows }) => (
	<>
		{
			rows.map(({ left, right, rowTitle }: InfoRowData, index) => (
				// To make sure that the hash will be unique, we added the index just in case
				<div key={hash({ ...left, ...right, index })}>
					{rowTitle && (
						<h3 className="mb-4">
							{rowTitle}
						</h3>
					)}
					<div
						key={`info-row--${left.title}`}
						className={styles['m-info-column']}
					>
						<InfoColumn
							title={left.title}
							data={left.data}
							className={cx(
								styles['a-info-column-item'],
								styles['a-info-column-item--left'],
							)}
						/>
						<InfoColumn
							title={right.title}
							data={right.data}
							className={cx(
								styles['a-info-column-item'],
								styles['a-info-column-item--right'],
							)}
						/>
					</div>
				</div>

			))
		}
	</>
);
