import {
	DossierDetail,
	DossierDetailActivity,
	DossierDetailVessel,
} from '../../../lib/dossiers/registries/dossiers.types';
import { ActivityType, VoyageType } from '../../../core/core.types';

const TEST_VESSEL: DossierDetailVessel = {
	id: 1,
	imo: '123C',
	eni: '123C',
	callSign: '123C',
	msi: '123C',
	name: '123C',
	lengthMeters: 10,
	widthMeters: 10,
	depthMeters: 10,
	actualDraughtMeters: 10,
	maximumDraughtMeters: 10,
	deadWeightTonnage: 10,
	vesselTypeCode: '123C',
};

export const TEST_VOYAGE: DossierDetail = {
	id: 1,
	createdOn: 'test',
	dossierNumber: '4572548',
	creatorStakeholderId: 1,
	clientStakeholderId: 1,
	requestingStakeholderReference: [123],
	portLocode: 'code',
	terminal: 'one',
	berth: '365C',
	voyageType: VoyageType.In,
	voyageNumber: 123,
	stayNumber: '123C',
	vessel: TEST_VESSEL,
	activities: [],
	cargo: [],
	alerts: [],
	currentActivityActualEndDateTime: '2019-10-11T08:15:50.9933333',
	currentActivityActualStartDateTime: '2019-10-11T08:15:50.9933333',
	currentActivityExpectedEndDateTime: '2019-10-11T08:04:50.9933333',
	currentActivityExpectedStartDateTime: '2019-10-11T08:05:50.9933333',
	currentActivityType: ActivityType.LoadingLaycan,
	nextStakeholderActivityActualEndDateTime: null,
	nextStakeholderActivityActualStartDateTime: null,
	nextStakeholderActivityExpectedEndDateTime: '2019-10-11T08:04:50.9933333',
	nextStakeholderActivityExpectedStartDateTime: '2019-10-11T08:04:50.9933333',
	nextStakeholderActivityType: ActivityType.LoadingLaycan,
};

const INBOUND_ACTIVITIES = ['LoadingLaycan', 'ToBerth'];
// eslint-disable-next-line max-len
const AT_BERTH_ACTIVITIES = ['Sampling', 'Analysis', 'CargoOperations', 'Inspection', 'Loading', 'Vapours', 'Discharging'];
const OUTBOUND_ACTIVITIES = ['Departure', 'DischargeWindow'];

export const getInboundActivities = (dossier: DossierDetail): DossierDetailActivity[] => (
	dossier.activities.filter((activity: DossierDetailActivity) => (
		INBOUND_ACTIVITIES.includes(ActivityType[activity.activityType])
	))
);

export const getAtBerthActivities = (voyage: DossierDetail): DossierDetailActivity[] => (
	voyage.activities.filter((activity: DossierDetailActivity) => (
		AT_BERTH_ACTIVITIES.includes(ActivityType[activity.activityType])
	))
);

export const getOutboundActivities = (voyage: DossierDetail): DossierDetailActivity[] => (
	voyage.activities.filter((activity: DossierDetailActivity) => (
		OUTBOUND_ACTIVITIES.includes(ActivityType[activity.activityType])
	))
);
