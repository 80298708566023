import { StoreConfig, Store } from '@datorama/akita';

import { VoyageState, createInitialVoyageState } from './voyage.model';

@StoreConfig({
	name: 'voyage',
	resettable: true,
})
export class VoyageStore extends Store<VoyageState> {
	constructor() {
		super(createInitialVoyageState());
	}
}

export const voyageStore = new VoyageStore();
