export const CORE_ROUTES = Object.freeze({
	NOT_ALLOWED: '/not-allowed',
	NOT_FOUND: '/not-found',
});

export const PAGE_SIZES = {
	default: 10,
};

export const ESC_KEY = 27;
