import { VesselPosition } from '../../../registries/vessels.types';

export const vesselsFactory = (vessels: VesselPosition[]): VesselPosition[] => (
	vessels.map((vessel) => {
		const hasDegree = !!vessel.location.course;

		if (hasDegree) {
			return vessel;
		}

		return {
			...vessel,
			location: {
				...vessel.location,
				course: 0,
			},
		};
	})
);
