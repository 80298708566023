import { route } from 'navi';

import { ARRIVE_DEPART_ROUTES } from './arriveDepart.const';
import { withAuthentication, withLBRolCheck } from '../../router/router.helpers';

export default {
	[ARRIVE_DEPART_ROUTES.ARRIVALS_DEPARTURE]: withAuthentication(withLBRolCheck(route({
		title: 'Arrivals & Departures',
		getView: () => import('./pages/ArrivalsDepartures/ArrivalsDepartures.page'),
	}))),
};
