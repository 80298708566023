import React from 'react';
import cx from 'classnames';

import { splitCamelCaseString } from '../../../../core/helpers/text/textHelper';
import { getCompanyColor } from '../../../../lib/companies/helpers/getColor';

import { PartyListItemProps } from './PartyListItem.types';

import { Marker, Phone, Mail } from '../../../../../assets/images';
import styles from './PartyListItem.module.scss';

export const PartyListItem: React.FC<PartyListItemProps> = ({
	title,
	address = {
		streetAndNumber: '-',
		zipCode: '-',
		city: '-',
		country: '-',
	},
	phone = '-',
	email = '-',
	role,
}) => (
	<div className={styles['m-party-list-item']}>
		<div
			className={cx(
				styles['m-party-item-header'],
				styles[`m-party-item-header--${getCompanyColor(role)}`]
			)}
		>
			<div className="flex flex-col">
				<span className={styles['a-party-role']}>{splitCamelCaseString(role)}</span>
				<span className={styles['a-party-title']}>{title}</span>
			</div>
		</div>
		<div>
			<div className="flex mt-8">
				<Marker className={styles['a-party-icon']} />
				<div className={styles['a-party-address']}>
					<span className={styles['a-party-street-and-nr']}>
						{address.streetAndNumber}
					</span>
					<span className={styles['a-party-city-and-postal']}>
						{address.zipCode} {address.city}, {address.country}
					</span>
				</div>
			</div>
			<div className="flex items-center mt-4">
				<Phone className={styles['a-party-icon']} />
				<span className={styles['a-party-telephone']}>{phone}</span>
			</div>
			<div className="flex items-center mt-4">
				<Mail className={styles['a-party-icon']} />
				<span className={styles['a-party-mail']}>{email}</span>
			</div>
		</div>
	</div>
);
