import { useEffect, useState } from 'react';
import { DataItem } from 'vis-timeline';

import { Dossier } from '../../../../lib/dossiers/registries/dossiers.types';
import { VoyageDetail } from '../../../../lib/voyages/registries/voyages.types';
import { hashString } from '../../../helpers/generators/hash';

export const useBerthOverviewItems = (voyages: VoyageDetail[], dossiers: Dossier[]): DataItem[] => {
	const [timelineItems, setTimelineItems] = useState<DataItem[]>([]);

	useEffect(() => {
		setTimelineItems(voyages.map(
			(voyage) => {
				const { start, end, beginTime, endTime, voyageNumber, stayNumber, vessel } = voyage;

				const activeDossier = dossiers.find(
					({ stayNumber: sn, voyageNumber: vn }) => {
						const equalVesselStaynumber = sn === stayNumber;
						const equalVoyageNumber = vn === voyageNumber;

						return equalVesselStaynumber && equalVoyageNumber;
					}
				);
				const className = activeDossier ? '' : 'a-timeline-item--blue-outline';

				return {
					id: `${voyageNumber}_${stayNumber}`,
					group: hashString(voyage.berth),
					content: vessel.name,
					start: new Date(beginTime || start.actualTime || start.estimatedTime || ''),
					end: new Date(endTime || end.actualTime || end.estimatedTime || ''),
					className: `a-timeline-item a-timeline-item--blue ${className}`,
				};
			}
		));
	}, [dossiers, voyages]);

	return timelineItems;
};
