import { StoreConfig, EntityStore } from '@datorama/akita';

import { CompaniesState, createInitialCompaniesState } from './companies.model';

@StoreConfig({
	name: 'companies',
	resettable: true,
})
export class CompaniesStore extends EntityStore<CompaniesState> {
	constructor() {
		super(createInitialCompaniesState(), {
			resettable: true,
		});
	}
}

export const companiesStore = new CompaniesStore();
