import { toast } from 'react-toastify';
import { LogsRegistry, logsRegistry } from '../registries/logs.registry';
import { Log, LogQuery, DossierLog, UnreadLogs } from '../registries/logs.types';
import { LogsStore, logsStore } from './logs.store';
import { ApiError } from '../../../core/services/api.types';

export class LogsService {
	constructor(
		private store: LogsStore,
		private logs: LogsRegistry,
		private nextLogs: DossierLog[],
	) {
		this.store = store;
		this.logs = logs;
		this.nextLogs = nextLogs;
	}

	public get(query: LogQuery): void {
		const { dossier: id, page } = query;
		this.store.setLoading(true);

		this.logs.fetchAll(query)
			.subscribe(
				(logs: Log[]) => {
					if (query['page-size']) {
						const isLastPage = logs.length < query['page-size'];
						this.store.setLoading(false);

						if (page === 1) {
							this.store.remove(id);
						}

						this.store.upsert(id, {
							logs,
							page,
							isLastPage,
						});
					}
				},
				(error: ApiError) => {
					this.store.setLoading(false);
					this.store.setError(error);
				}
			);
	}

	public add(query: LogQuery): void {
		const { dossier: id, page } = query;
		this.store.setLoading(true);

		if (!id) {
			this.store.setLoading(false);
			return;
		}

		const { logs: nextLogs, isLastPage } = this.nextLogs[id];

		this.store.update(id, (entity) => ({
			logs: [
				...entity.logs,
				...nextLogs,
			],
			isLastPage,
			page,
		}));

		this.store.setLoading(false);
	}

	public checkNextPage(query: LogQuery): void {
		const { dossier: id, page } = query;

		this.store.setLoading(true);
		this.logs.fetchAll(query)
			.subscribe(
				(logs: Log[]) => {
					if (query['page-size']) {
						const isLastPage = logs.length < query['page-size'];

						if (logs.length === 0) {
							// eslint-disable-next-line arrow-body-style
							this.store.update(id, () => {
								return {
									isLastPage,
								};
							});
						}

						this.store.setLoading(false);

						if (id && page) {
							this.nextLogs[id] = {
								id,
								logs,
								page,
								isLastPage,
							};
						}
					}
				},
				(error: ApiError) => {
					this.store.setLoading(false);
					this.store.setError(error);
				}
			);
	}

	public readAll(unreadLogsArray: UnreadLogs[]): void {
		unreadLogsArray.forEach((unreadLogs) => {
			this.logs.readAll(unreadLogs.logs);
			this.store.update(unreadLogs.id, (entitiy) => {
				const tmpLogs = entitiy.logs.map((log) => {
					const isReadByCurrentStakeholder = unreadLogs.logs.includes(log.id)
						? !log.isReadByCurrentStakeholder
						: log.isReadByCurrentStakeholder;

					return {
						...log,
						isReadByCurrentStakeholder,
					};
				});

				return {
					logs: [...tmpLogs],
				};
			});
		});
	}

	public polNotifications(query: LogQuery): void {
		this.logs.fetchAll(query)
			.subscribe(
				(logs: Log[]) => {
					logs.forEach((log, i: number) => setTimeout(() => {
						toast(
							log.textMessage,
							{ type: 'info' },
						);
					}, 750 * i));
				}
			);
	}
}

export const logsService = new LogsService(logsStore, logsRegistry, []);
