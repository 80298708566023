import { CompaniesStore, companiesStore } from './companies.store';
import { CompaniesRegistry, companiesRegistry } from '../../registries/companies.registry';
import { Company, ModifiedCompany } from '../../registries/companies.types';

export class CompaniesService {
	constructor(
		private store: CompaniesStore,
		private companies: CompaniesRegistry,
	) {
		this.store = store;
		this.companies = companies;
	}

	public getMultiple(newCompanies: ModifiedCompany[]): void {
		this.store.setLoading(true);

		newCompanies.forEach((newCompany: ModifiedCompany) => this.fetchAndStore(newCompany));
	}

	private fetchAndStore(newCompany: ModifiedCompany): void {
		if (!newCompany || !newCompany.vat) {
			this.store.setLoading(false);
			return;
		}

		this.companies.fetchOne(newCompany.vat)
			.subscribe(
				(company: Company) => {
					this.store.add({
						...company,
						name: newCompany.name,
						role: newCompany.role,
					});
					this.store.setLoading(false);
				},
				() => this.store.add(newCompany)
			);
	}
}

export const companiesService = new CompaniesService(companiesStore, companiesRegistry);
