import { timeBetween } from '../../helpers/time/timeHelper';
import { SHORT_DELAY, LONG_DELAY, DELAY_COLORS } from './DelayLabel.const';

export const getLabelColor = (delay: number): string => {
	if (delay < SHORT_DELAY) {
		return DELAY_COLORS.green;
	}

	if (delay >= SHORT_DELAY && delay <= LONG_DELAY) {
		return DELAY_COLORS.orange;
	}

	return DELAY_COLORS.red;
};

const isNegative = (delay: number): string => (
	(Math.sign(delay) < 0) ? '-' : '+'
);

export const getDelay = (estTime: string, actualTime: string | null): string => {
	const delay = timeBetween(estTime, actualTime);
	return `${isNegative(delay)}${Math.abs(delay)}`;
};
