import { DossierDetail } from '../../registries/dossiers.types';

export interface DossierState {
	detail: DossierDetail | null;
}

export function createInitialDossierState(): DossierState {
	return {
		detail: null,
	};
}
