import { filter, map } from 'rxjs/operators';
import JwtDecode from 'jwt-decode';
import { isNil, propOr } from 'ramda';
import { mapUserProfileByUserInfo } from '../helpers/mappers/userProfile';

import { UserStore, userStore } from './user.store';
import { Token, Profile, UserInfoPayload } from './user.model';
import { userRegistry } from './registries/user.registry';
import { userQuery } from './user.query';

export class UserService {
	constructor(private store: UserStore) {
		this.store = store;
	}

	public setToken(token: Token): void {
		const expires: number = new Date().getTime() + parseInt(token.expires_in, 10) * 1000;

		this.store.update({
			token: {
				...token,
				expires,
			},
		});
	}

	public setProfile(profile: Profile): void {
		this.store.update({
			profile,
		});
	}

	public fetchUserInfo(): void {
		userQuery.token$
			.pipe(filter((token: Token | null) => !isNil(token)), map((token) => (token ? JwtDecode(token.id_token) : {})))
			.subscribe((token) => {
				const userInfo: UserInfoPayload = {
					sub: propOr('', 'sub', token),
					family_name: propOr('', 'family_name', token),
					given_name: propOr('', 'given_name', token),
					name: propOr('', 'name', token),
					'nxt:entity:domain': propOr('', 'extension_CompanyDomain', token),
					'nxt:entity:id': propOr('', 'extension_CompanyId', token),
					'nxt:entity:name': propOr('', 'extension_CompanyName', token),
					'nxt:entity:nxtentityid': propOr('', 'extension_CompanyEntityId', token),
					'nxt:entity:roles': propOr<string, unknown, string>('', 'extension_EntityRoles', token).split(',').map((r) => r.trim()).map((r) => parseInt(r, 10)),
					'nxt:entity:roles:sandbox': [],
					'nxt:entity:vat': propOr('', 'extension_CompanyVat', token),
					'nxt:user:email': (propOr([], 'emails', token)as string[])[0] || '',
					'nxt:user:roles': propOr<string, unknown, string>('', 'extension_UserRoles', token).split(',').map((r) => r.trim()).map((r) => parseInt(r, 10)),
					'nxt:entity:subscriptions': [],
					'nxt:entity:trustLevel': '',
					'nxt:user:preferredlanguage': '',
					'nxt:user:roles:sandbox': [],
				};
				const profile = mapUserProfileByUserInfo(userInfo);
				this.store.update({
					profile,
				});
			});
	}

	public logout(): void {
		this.store.update({
			token: null,
			profile: null,
		});
	}
}

export const userService = new UserService(userStore);
