import React, { ReactNode } from 'react';

import { AlertSection } from '../../../components/AlertSection/AlertSection';
import { getAlerts, hasAlert } from '../../../helpers/alerts/alertHelper';

import { ImpactedActor } from '../../../components/AlertSection/AlertSection.types';
import { ActivityModalFooterProps } from './ActivityModalFooter.props';
import { DossierDetailActivity, DossierAlert } from '../../../../lib/dossiers/registries/dossiers.types';

import styles from './ActivityModalFooter.module.scss';

const renderAlertSection = (
	activity: DossierDetailActivity,
	alerts: DossierAlert[],
	impactedActors: ImpactedActor,
): ReactNode => {
	const currentAlerts = getAlerts(activity.id, alerts).map((alert) => (
		<AlertSection
			activityId={activity.id}
			alert={alert}
			impactedActors={impactedActors}
			key={`conflict_with_activity_${alert.activity1Id}_and_activity_${alert.activity2Id}`}
		/>
	));

	return currentAlerts;
};

export const ActivityModalFooter: React.FC<ActivityModalFooterProps> = ({
	actviveActivity,
	alerts,
	impactedActors,
}) => {
	const renderAlert = hasAlert(actviveActivity.id, alerts);

	if (!renderAlert) {
		return null;
	}

	return (
		<div className={styles['o-alert-section']}>
			{renderAlertSection(actviveActivity, alerts, impactedActors)}
		</div>
	);
};
