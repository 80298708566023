import React from 'react';
import { pathOr } from 'ramda';

import { Warning } from '../../../../assets/images';
import { splitCamelCaseString } from '../../../core/helpers/text/textHelper';

import { getConflictedActivity, getImpactedActor } from './AlertSection.helper';
import { AlertSectionProps } from './AlertSection.props';

import styles from './AlertSection.module.scss';

export const AlertSection: React.FC<AlertSectionProps> = ({
	activityId,
	alert,
	impactedActors,
}) => {
	const alertType = pathOr('-', ['alertType'], alert);
	const alertMessage = pathOr('-', ['textMessage'], alert);
	const conflictedActivity = getConflictedActivity(activityId, alert);
	const impactedActor = getImpactedActor(activityId, alert, impactedActors);

	return (
		<div className="flex flex-1">
			<div className="flex flex-1 flex-col">
				<div className="flex">
					<Warning className="mr-4 w-6" />
					<span className={styles['a-alert-type']}>
						{alertType}!
					</span>
				</div>

				<span className={styles['a-alert-message']}>
					{alertMessage}
				</span>
			</div>
			<div className="w-1/2 pl-12">
				<table className="w-full">
					<tbody>
						<tr>
							<td className={styles['a-list-item__title']}>
								Conflict with
							</td>
							<td className={styles['a-list-item__desc']}>
								{splitCamelCaseString(conflictedActivity)}
							</td>
						</tr>
						<tr>
							<td className={styles['a-list-item__title']}>
								Impacted actor
							</td>
							<td className={styles['a-list-item__desc']}>
								{impactedActor}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
