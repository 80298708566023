import { throwError, of, Observable } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { RxHttpRequestResponse } from '@akanass/rx-http-request';
import { toast } from 'react-toastify';

import { ApiError } from '../api.types';

const ERROR_STATUS_CODE = new RegExp('^[4-5][0-9][0-9]');

const parseErrorBody = (error: ApiError | string): string => {
	if (typeof error === 'string') {
		return error;
	}

	const keys = Object.keys(error);
	const messages = keys.map((key: string) => error[key]);

	return messages.join(', ');
};

function processResponse<T>(showToast = true) {
	return (obs$: Observable<RxHttpRequestResponse<T>>): Observable<T> => obs$.pipe(
		first(),
		switchMap(({ body, response }: RxHttpRequestResponse<T>) => {
			const statusCode = response.statusCode.toString();
			if (ERROR_STATUS_CODE.test(statusCode)) {
				if (showToast) {
					toast(
						parseErrorBody(body as unknown as ApiError | string),
						{
							type: 'error',
						},
					);
				}

				return throwError(body);
			}

			return of(body);
		})
	);
}

export default processResponse;
