import React from 'react';
import { Link } from 'react-navi';

import { RouterLinkProps } from './RouterLink.props';

export const RouterLink: React.FC<RouterLinkProps> = ({
	active,
	href,
	children,
	...rest
}) => (
	<Link
		{...rest}
		active={active}
		href={href}
	>
		{children}
	</Link>
);

RouterLink.defaultProps = {
	href: '',
	children: null,
};
