import { StoreConfig, Store } from '@datorama/akita';

import { DossierState, createInitialDossierState } from './dossier.model';

@StoreConfig({
	name: 'dossier',
	resettable: true,
})
export class DossierStore extends Store<DossierState> {
	constructor() {
		super(createInitialDossierState(), {
			resettable: true,
		});
	}
}

export const dossierStore = new DossierStore();
