import { Matcher, map, redirect } from 'navi';

import { USER_ROUTES } from '../modules/user/user.const';
import { VOYAGE_ROUTES } from '../modules/voyage/voyage.const';
import { ARRIVE_DEPART_ROUTES } from '../modules/arriveDepart/arriveDepart.const';

type RouterContext = {
	isAuthenticated: boolean;
	isLiquidBulkUser: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withAuthentication(matcher: Matcher<RouterContext>): any {
	return map<RouterContext>(async (request, context) => {
		const { isAuthenticated } = context;

		if (isAuthenticated === null) {
			return matcher;
		}

		const { mountpath } = request;

		const isLoginRoute = mountpath === USER_ROUTES.LOGIN;

		// Redirect to default route if user is authenticated and on login page
		if (isLoginRoute && isAuthenticated) {
			return redirect(VOYAGE_ROUTES.VOYAGE_OVERVIEW, {
				exact: true,
			});
		}

		// redirect to login with current page as param if user is not authenticated
		if (!isLoginRoute && !isAuthenticated) {
			return redirect(`${USER_ROUTES.LOGIN}?redirectTo=${mountpath}`, {
				exact: true,
			});
		}

		return matcher;
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withLBRolCheck(matcher: Matcher<RouterContext>): any {
	return map<RouterContext>((request, context) => {
		const { isLiquidBulkUser } = context;

		if (isLiquidBulkUser === null) {
			return matcher;
		}

		const { mountpath } = request;

		const isArrivalsDeparturesRoute = mountpath === ARRIVE_DEPART_ROUTES.ARRIVALS_DEPARTURE;

		// Redirect to default route if user is authenticated and on login page
		if (isArrivalsDeparturesRoute && !isLiquidBulkUser) {
			return redirect(VOYAGE_ROUTES.VOYAGE_OVERVIEW, {
				exact: true,
			});
		}

		return matcher;
	});
}
