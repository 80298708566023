import React from 'react';
import cx from 'classnames/bind';

import { Spinner } from '../../../../assets/images';

import { LoaderProps } from './Loader.props';

import styles from './Loader.module.scss';

const cxBind = cx.bind(styles);

export const Loader: React.FC<LoaderProps> = ({
	loading,
	classNames,
	children,
}) => (
	<div
		className={cxBind(
			'loader',
			classNames,
		)}
	>
		{
			loading
				? (
					<Spinner className={styles['a-icon']} />
				)
				: children
		}
	</div>
);
