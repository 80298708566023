import React from 'react';
import cx from 'classnames/bind';
import { isNil } from 'ramda';

import { ActivityLogListProps } from './ActivityLogList.types';
import { ActivityLogListItem } from './ActivityLogListItem/ActivityLogListItem';
import styles from './ActivityLogList.module.scss';

const cxBind = cx.bind(styles);

export const ActivityLogList: React.FC<ActivityLogListProps> = ({
	logs,
	small = false,
}) => (
	<div
		className={cxBind({
			'u-grid-container': true,
			'o-activity-log-list': true,
			'o-activity-log-list--small': small,
		})}
	>
		{logs && logs.length > 0
			? logs.map((item, index) => (
				<ActivityLogListItem
					key={`activity-${item.dossierLogType + index}-list-item`}
					type={item.dossierLogType}
					title={!isNil(item.textMessage) ? item.textMessage : 'This log has no title.'}
					createdOn={item.createdOn}
					isRead={item.isReadByCurrentStakeholder}
					isParentSmall={small}
				/>
			))
			: <p className="text-sm mb-4 ml-4 text-regentGray">No activity logs available</p>}
	</div>
);
