import React from 'react';

import { RouterItem } from './Header.types';

// import { CORE_ROUTES } from '../../core.const';
import { VOYAGE_ROUTES } from '../../../voyage/voyage.const';
import { BERTH_ROUTES } from '../../../berth/berth.const';
import { NOTIFICATIONS_ROUTES } from '../../../notifications/notifications.const';
import { ARRIVE_DEPART_ROUTES } from '../../../arriveDepart/arriveDepart.const';
import { /* LifeRing, Map, */Bell, Ship, Bullseye, Map, Anchor } from '../../../../assets/images';

import styles from './Header.module.scss';

export const ROUTERLINKS: RouterItem[] = [
	{
		link: VOYAGE_ROUTES.VOYAGE_OVERVIEW,
		icon: <Ship className={styles['a-nav-icon']} />,
		label: 'Voyages',
	},
	{
		link: BERTH_ROUTES.BERTH_OVERVIEW,
		icon: <Bullseye className={styles['a-nav-icon']} />,
		label: 'Berth Overview',
	},
	{
		link: ARRIVE_DEPART_ROUTES.ARRIVALS_DEPARTURE,
		icon: <Anchor className={styles['a-nav-icon']} />,
		label: 'Arrivals & Departures',
	},
	{
		link: BERTH_ROUTES.MAP_OVERVIEW,
		icon: <Map className={styles['a-nav-icon']} />,
		label: 'Map Overview',
	},
	{
		link: NOTIFICATIONS_ROUTES.NOTIFICATIONS_VIEW,
		icon: <Bell className={styles['a-nav-icon']} />,
		label: 'Notifications',
	},
	/* {
		link: CORE_ROUTES.NOT_FOUND,
		icon: <LifeRing className={styles['a-nav-icon']} />,
		label: 'Help',
	}, */
];

export const NEEDS_LB_ACCOUNT = [VOYAGE_ROUTES.VOYAGE_OVERVIEW];
