import React from 'react';

import { ModalHeaderProps } from './ModalHeader.props';
import { ReactComponent as Cross } from '../../../../../assets/images/cross.svg';
import styles from './ModalHeader.module.scss';

export const ModalHeader: React.FC<ModalHeaderProps> = ({
	children,
	title,
	subtitle,
	closeModal,
}) => (
	<div className={styles['o-modal__header']}>
		<div>
			<h3>{title}</h3>
			{ subtitle && (<div className={styles.subtitle}>{subtitle}</div>) }
			{children}
		</div>
		<div
			role="presentation"
			onClick={closeModal}
			className={styles['o-modal__close']}
		>
			<Cross />
		</div>
	</div>
);
