import { DataItem, DataGroup } from 'vis-timeline';

import {
	DossierDetailActivity,
	DossierAlert,
	ActivityEvent,
	DossierDetailCargo,
} from '../../../lib/dossiers/registries/dossiers.types';

import {
	mapActivityTimelineItems,
	mapActivityTimelineAlerts,
	mapActivityTimelinePassagePoints,
} from './AcitivyTimelineItem/AcitivyTimelineItem.helpers';
import { mapActivityTimelineGroups } from './AcitivyTimelineGroup/ActivityTimelineGroup.helpers';
import { VoyagePassage } from '../../../lib/voyages/registries/voyages.types';

const getActivityEvents = (activities: DossierDetailActivity[]): ActivityEvent[] => (
	activities.reduce((acc: ActivityEvent[], curr) => {
		curr.activityEvents.forEach((event) => (
			acc.push(event)
		));

		return acc;
	}, [])
);

export const mapTimelineData = (
	activities: DossierDetailActivity[],
	alerts: DossierAlert[],
	passagePoints: VoyagePassage[],
	cargo: DossierDetailCargo[],
): {
	timelineItems: DataItem[];
	timelineGroups: DataGroup[];
	timelineAlerts: DataItem[];
	timelinePassagePoints: DataItem[];
} => {
	const timelineGroups = mapActivityTimelineGroups(activities, alerts, passagePoints);
	const timelineItems = mapActivityTimelineItems(activities, timelineGroups, cargo);
	const timelineAlerts = mapActivityTimelineAlerts(alerts);
	const timelinePassagePoints = mapActivityTimelinePassagePoints(passagePoints, getActivityEvents(activities));

	return {
		timelineGroups,
		timelineItems,
		timelineAlerts,
		timelinePassagePoints,
	};
};
