import React from 'react';
import { route, NaviRequest } from 'navi';

import VoyageDetail from './pages/VoyageDetail/VoyageDetail';
import VoyageActivityLog from './pages/VoyageActivityLog/VoyageActivityLog';
import { VOYAGE_ROUTES } from './voyage.const';
import { withAuthentication } from '../../router/router.helpers';
import { dossierService } from '../lib/dossiers/store/dossier/dossier.service';
import { logsService } from '../lib/logs/store/logs.service';

export default {
	[VOYAGE_ROUTES.VOYAGE_OVERVIEW]: withAuthentication(route({
		title: 'Voyages Overview',
		getView: () => import('./pages/VoyageOverview/VoyageOverview'),
	})),
	[VOYAGE_ROUTES.VOYAGE_DETAIL]: withAuthentication(route(({ params }: NaviRequest) => {
		const { id } = params;
		const parsedId = parseInt(id, 10);

		dossierService.get(parsedId);
		logsService.get({
			dossier: parsedId,
		});

		return {
			title: 'Voyages Detail',
			view: <VoyageDetail />,
		};
	})),
	[VOYAGE_ROUTES.VOYAGE_ACTIVITYLOG]: withAuthentication(route(({ params }: NaviRequest) => {
		const { id } = params;
		const parsedId = parseInt(id, 10);

		dossierService.get(parsedId);
		logsService.get({
			dossier: parsedId,
		});

		return {
			title: 'Voyage Detail Log',
			view: <VoyageActivityLog />,
		};
	})),
};
