export const splitCamelCaseString = (string: string): string => {
	const splitString = string.match(/[A-Z][a-z]+/g);

	if (!splitString) {
		return string || '';
	}

	return splitString.join(' ');
};

export const formatName = (string: string, prefix: string): string => {
	const regex = new RegExp(prefix);
	return splitCamelCaseString(string.replace(regex, ''));
};
