import React, { FC } from 'react';
import cx from 'classnames/bind';

import { getActivityIcon } from '../../helpers/icon/iconHelper';
import { splitCamelCaseString } from '../../helpers/text/textHelper';

import { ActivityProps } from './Activity.props';
import styles from './Activity.module.scss';

const cxBind = cx.bind(styles);

export const Activity: FC<ActivityProps> = ({
	fallback = '-',
	label,
	marginBottom = true,
	type,
	whiteText = false,
}) => (
	<div
		className={
			cxBind({
				'm-activity': true,
				'm-activity--no-margin': !marginBottom,
			})
		}
	>
		{getActivityIcon(
			type,
			cxBind({
				'm-activity__icon': true,
				'm-activity__icon--active': whiteText,
			}),
		)}
		<div className={styles['m-activity-label']}>
			<span
				className={
					cxBind({
						'm-activity-label__title': true,
						'm-activity-label__title--active': whiteText,
					})
				}
			>
				{label}
			</span>
			<span
				className={
					cxBind({
						'm-activity-label__subtitle': true,
						'm-activity-label__subtitle--active': whiteText,
					})
				}
			>
				{type ? splitCamelCaseString(type) : fallback}
			</span>
		</div>
	</div>
);
