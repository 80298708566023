import { ButtonSize, ButtonWeight, ButtonWeights, ButtonSizes } from './Button.types';

export const getButtonSize = (buttonSize: ButtonSize): string => {
	switch (buttonSize) {
		case ButtonSizes.small:
			return 'a-button--small';

		case ButtonSizes.large:
			return 'a-button--big';

		default:
			return '';
	}
};

export const getButtonWeight = (buttonWeight: ButtonWeight): string => {
	switch (buttonWeight) {
		case ButtonWeights.bold:
			return 'a-button--font-bold';

		case ButtonWeights.light:
			return 'a-button--font-light';

		default:
			return '';
	}
};
