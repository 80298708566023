import { IdType, DataItem, DataGroup } from 'vis-timeline';
import { isAfter, isEqual, format } from 'date-fns';
import { pathOr, propOr, isEmpty } from 'ramda';
import cx from 'classnames';

import { TimelineItemTimeDifferences } from '../../Timeline/Timeline.types';
import { DateTimeWindow, Stakeholder, SupplyChainRole } from '../../../core.types';
import {
	DossierDetailActivity,
	DossierAlert,
	ActivityEvent,
	ActivityEventType,
	DossierDetailCargo,
} from '../../../../lib/dossiers/registries/dossiers.types';
import { getCompanyColor } from '../../../../lib/companies/helpers/getColor';
import { hashString } from '../../../helpers/generators/hash';
import { TIMELINE_ALERTS_ID } from '../../Timeline/Timeline.const';
import { VoyagePassage } from '../../../../lib/voyages/registries/voyages.types';
import { AvailableTimeFormats, TimeFormats } from '../../../helpers/time/timeHelper';
import { splitCamelCaseString } from '../../../helpers/text/textHelper';

const emptyTimeDifferences = {
	advance: [],
	delay: [],
};

// Calculate time differences between estimated start & actual start.
// Based on the difference between them, we can visualize if the start of
// the activity is delayed or started in advance.
export const getTimelineItemStartDifferences = (
	id: number,
	itemTimes: DateTimeWindow,
	group: IdType,
	subgroup: string,
	color: string
): TimelineItemTimeDifferences => {
	const { beginActual, beginEstimate } = itemTimes;

	// return when no actual start time is present
	if (!beginActual) {
		return emptyTimeDifferences;
	}

	// parse date objects from timestamps
	const actual = new Date(beginActual);
	const estimate = new Date(beginEstimate);

	// return when actual is equal to estimate
	if (isEqual(actual, estimate)) {
		return emptyTimeDifferences;
	}

	// Delay: actual is after estimated -> light color
	if (isAfter(actual, estimate)) {
		return {
			...emptyTimeDifferences,
			delay: [{
				// the id should be in format `${id}_${start or end}-${delay or advance}`
				// for the timeline clickHandler to work, we should keep this format
				id: `${id}_start-delay`,
				content: '',
				start: estimate.getTime(),
				end: actual.getTime(),
				className: cx(
					'a-timeline-item',
					`a-timeline-item--${color}`,
					'a-timeline-item--light',
					'a-timeline-item--right-cornered'
				),
				group,
				subgroup,
			}],
		};
	}

	// Advance: actual is before estimated -> dark color
	if (!isAfter(actual, estimate)) {
		return {
			...emptyTimeDifferences,
			advance: [{
				// the id should be in format `${id}_${start or end}-${delay or advance}`
				// for the timeline clickHandler to work, we should keep this format
				id: `${id}_start-advance`,
				content: '',
				start: actual.getTime(),
				end: estimate.getTime(),
				className: cx(
					'a-timeline-item',
					`a-timeline-item--${color}`,
					'a-timeline-item--dark',
					'a-timeline-item--right-cornered'
				),
				group,
				subgroup,
			}],
		};
	}

	return emptyTimeDifferences;
};

const getItemStart = (currentActivity: DossierDetailActivity): number | null => {
	const { beginActual, beginEstimate } = currentActivity.dateTimeWindow;

	if (!(beginActual && beginEstimate)) {
		return null;
	}

	const actual = new Date(beginActual);
	const estimate = new Date(beginEstimate);

	// Delay: actual is after estimate
	if (isAfter(actual, estimate)) {
		return actual.getTime();
	}

	// Advance: actual is before estimate
	if (!isAfter(actual, estimate)) {
		return estimate.getTime();
	}

	return null;
};

// Calculate time differences between estimated end & actual end.
// Based on the difference between them, we can visualize if the activity
// takes longer to complete or if the activity is completed earlier than expected.
export const getTimelineItemEndDifferences = (
	id: number,
	itemTimes: DateTimeWindow,
	group: IdType,
	subgroup: string,
	color: string
): TimelineItemTimeDifferences => {
	const { endActual, endEstimate } = itemTimes;

	// return when no actual end time is present
	if (!endActual) {
		return emptyTimeDifferences;
	}

	// parse date objects from timestamps
	const actual = new Date(endActual);
	const estimate = new Date(endEstimate);

	// return when actual is equal to estimate
	if (isEqual(actual, estimate)) {
		return emptyTimeDifferences;
	}

	// Delay: actual is after estimated -> dark color
	if (isAfter(actual, estimate)) {
		return {
			...emptyTimeDifferences,
			delay: [{
				// the id should be in format `${id}_${start or end}-${delay or advance}`
				// for the timeline clickHandler to work, we should keep this format
				id: `${id}_end-delay`,
				content: '',
				start: estimate.getTime(),
				end: actual.getTime(),
				className: cx(
					'a-timeline-item',
					`a-timeline-item--${color}`,
					'a-timeline-item--left-cornered',
					'a-timeline-item--dark'
				),
				group,
				subgroup,
			}],
		};
	}

	// Advance: actual is before estimated -> light color
	if (isAfter(estimate, actual)) {
		return {
			...emptyTimeDifferences,
			advance: [{
				// the id should be in format `${id}_${start or end}-${delay or advance}`
				// for the timeline clickHandler to work, we should keep this format
				id: `${id}_end-advance`,
				content: '',
				start: actual.getTime(),
				end: estimate.getTime(),
				className: cx(
					'a-timeline-item',
					`a-timeline-item--${color}`,
					'a-timeline-item--left-cornered',
					'a-timeline-item--light'
				),
				group,
				subgroup,
			}],
		};
	}

	return emptyTimeDifferences;
};

const getItemEnd = (currentActivity: DossierDetailActivity): number | null => {
	const { endActual, endEstimate } = currentActivity.dateTimeWindow;

	if (!(endActual && endEstimate)) {
		return null;
	}

	const actual = new Date(endActual);
	const estimate = new Date(endEstimate);

	// Delay: actual is after estimated -> dark color
	if (isAfter(actual, estimate)) {
		return estimate.getTime();
	}

	// Advance: actual is before estimated -> light color
	if (isAfter(estimate, actual)) {
		return actual.getTime();
	}

	return null;
};

// Gets the actual or estimated time label
const getTimeTitle = (estimatedTime: string | undefined, actualTime: string | undefined): string => {
	const timeFormat = TimeFormats[AvailableTimeFormats.time];

	if (!actualTime && estimatedTime) {
		return `ETA ${format(new Date(estimatedTime), timeFormat)}`;
	}

	if (actualTime) {
		return `ATA ${format(new Date(actualTime), timeFormat)}`;
	}

	return 'No ETA/ATA available';
};

// Check if the activity has a product code (DossierDetailActivity doesn't have a definition for productCode yet)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasProductCode = (activity: any): activity is { productCode: string } => {
	if (!('productCode' in activity)) return false;
	const { productCode }: { productCode: string | null } = activity;
	return productCode !== null;
};

// eslint-disable-next-line max-len
export const getCargoContentName = (cargo: DossierDetailCargo[], activity: DossierDetailActivity): string | undefined => {
	if (!isEmpty(cargo) && hasProductCode(activity)) {
		const matchedCargo = cargo.find((item) => activity.productCode === item.content);
		const newContent = matchedCargo !== undefined ? matchedCargo.contentName : undefined;

		return newContent;
	}
	return undefined;
};

// Create timeline items including activities (with delays & advances) and alerts.
// Each timeline item is assigned to a subgroup.
// Subgroups can contain activity advances or -delays
export const mapActivityTimelineItems = (
	activities: DossierDetailActivity[],
	groups: DataGroup[],
	cargo: DossierDetailCargo[],
): DataItem[] => activities.reduce((accumulator: DataItem[], currentActivity: DossierDetailActivity) => {
	// abstract relevant data from activity
	const { beginActual, beginEstimate, endActual, endEstimate } = currentActivity.dateTimeWindow;
	const stakeholder: Stakeholder = currentActivity.lastAssigneeStakeholder;
	const stakeholderID = pathOr('', ['id'], stakeholder);
	const stakeholderRole = pathOr(null, ['supplyChainRole'], stakeholder);
	let content = splitCamelCaseString(currentActivity.activityType);

	// get timeline item color based on stakeholder role
	const color = getCompanyColor(stakeholderRole as unknown as SupplyChainRole);

	// set item start & end times
	const start = beginActual || beginEstimate;
	const end = endActual || endEstimate;

	// Generate id, that needs to match group id
	const groupId = hashString(stakeholderID + stakeholderRole);
	const activityGroup = groups.find((group) => groupId === group.id);
	const group = activityGroup ? activityGroup.id : stakeholderID;
	const subGroupId = Math.random().toString(36).slice(0, 7);

	// Calculate delay or advance for start time
	const startDifferences = getTimelineItemStartDifferences(
		currentActivity.id,
		currentActivity.dateTimeWindow,
		group,
		`sg_group_${groupId}${start}${end}${subGroupId}`,
		color,
	);

	// Calculate delay or advance for end time
	const endDifferences = getTimelineItemEndDifferences(
		currentActivity.id,
		currentActivity.dateTimeWindow,
		group,
		`sg_group_${groupId}${start}${end}${subGroupId}`,
		color,
	);

	const hasBeginAdvanceOrDelay = startDifferences.advance.length > 0 || startDifferences.delay.length > 0;
	const hasEndAdvanceOrDelay = endDifferences.advance.length > 0 || endDifferences.delay.length > 0;

	// Determine if item left & right border-radius should be set or not
	const itemStartRadiusClass = hasBeginAdvanceOrDelay ? 'a-timeline-item--left-cornered' : '';
	const itemEndRadiusClass = hasEndAdvanceOrDelay ? 'a-timeline-item--right-cornered' : '';

	const itemStart = getItemStart(currentActivity) || new Date(start).getTime();
	const itemEnd = getItemEnd(currentActivity) || new Date(end).getTime();

	// Add cargo content name if a product code exists on the activity
	const cargoContentName = getCargoContentName(cargo, currentActivity);

	if (cargoContentName) {
		content = `${splitCamelCaseString(currentActivity.activityType)} - ${cargoContentName}`;
	}

	// Create subgroup for item & delay or/and advance
	return [
		...accumulator,
		...startDifferences.advance,
		...startDifferences.delay,
		{
			id: currentActivity.id,
			content,
			start: itemStart,
			end: itemEnd,
			className: cx(
				'a-timeline-item',
				`a-timeline-item--${color}`,
				itemStartRadiusClass,
				itemEndRadiusClass
			),
			group,
			subgroup: `sg_group_${groupId}${start}${end}${subGroupId}`,
		},
		...endDifferences.advance,
		...endDifferences.delay,
	];
}, []);

export const mapActivityTimelineAlerts = (alerts: DossierAlert[]): DataItem[] => (
	alerts.map((alert: DossierAlert) => ({
		id: hashString(`alert_${alert.id}`),
		content: '',
		start: new Date(alert.createdOn).getTime(),
		type: 'point',
		className: cx(
			'a-timeline-alert'
		),
		group: TIMELINE_ALERTS_ID,
	}))
);

const generateAlertTimelineItem = (
	id: number,
	content: string,
	title: string,
	start: number,
	actualTime: string | undefined,
): DataItem => ({
	id,
	content,
	title,
	start,
	type: 'point',
	className: cx(
		'a-timeline-passage',
		actualTime && 'a-timeline-passage--actual',
	),
	group: TIMELINE_ALERTS_ID,
});

export const mapActivityTimelinePassagePoints = (
	passagePoints: VoyagePassage[],
	activityEvents: ActivityEvent[],
): DataItem[] => {
	const passages: DataItem[] = passagePoints.map(({ code, locode, actualTime, estimatedTime }) => (
		generateAlertTimelineItem(
			hashString(`alert_passage_point_${code}_${locode}_${new Date(actualTime || estimatedTime)}`),
			code,
			getTimeTitle(estimatedTime, actualTime),
			new Date(actualTime || estimatedTime).getTime(),
			actualTime
		)
	));

	const events: DataItem[] = activityEvents.map((
		{ activityEventDate: eventDate, activityEventType: eventType },
		index,
	) => (
		generateAlertTimelineItem(
			hashString(`alert_activity_event_${eventDate}_${eventType}_${index}`),
			propOr('', eventType, ActivityEventType),
			getTimeTitle(undefined, eventDate),
			new Date(eventDate).getTime(),
			eventDate
		)
	));

	return [...passages, ...events];
};
