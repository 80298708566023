import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { isNil, isEmpty, propOr, pathOr } from 'ramda';

import { checkIfLiquidBulkUser, checkIfSuperAdmin } from '../helpers/roles/roleChecker';

import { Token, UserState, Profile } from './user.model';
import { UserStore, userStore } from './user.store';

export class UserQuery extends Query<UserState> {
	constructor(protected store: UserStore) {
		super(store);
	}

	public token$: Observable<Token | null> = this.select((state) => propOr(
		null,
		'token',
		state
	));

	public isAuthenticated$: Observable<boolean> = this.select((state: UserState) => !isNil(propOr(
		null,
		'token',
		state
	)) && !isEmpty(propOr(null, 'token', state)));

	public isLiquidBulkUser$: Observable<boolean> = this.select((state: UserState) => {
		const user = !isNil(propOr(null, 'profile', state));
		const userRoles = pathOr<number[]>([], ['profile', 'roles', 'standard'], state);
		const entityRoles = pathOr<number[]>([], ['profile', 'roles', 'entityRoles'], state);
		const hasLBRole = checkIfLiquidBulkUser(userRoles) || checkIfSuperAdmin(entityRoles);

		return user && hasLBRole;
	});

	public id$: Observable<string> = this.select((state: UserState) => pathOr(
		'',
		['profile', 'id'],
		state,
	));

	public profile$: Observable<Profile | null> =
	this.select((state: UserState): Profile | null => (
		propOr(null, 'profile', state)
	));
}

export const userQuery = new UserQuery(userStore);
