import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DossierDetail } from '../../registries/dossiers.types';
import { dossierQuery } from './dossier.query';
import { companiesService } from '../../../companies/store/companies/companies.service';
import { getUniqueCompaniesFromDossierDetail } from '../../../../voyage/pages/VoyageDetail/VoyageDetail.helper';
import { dossierStore } from './dossier.store';
import { voyageStore } from '../../../voyages/store/voyage/voyage.store';
import { companiesStore } from '../../../companies/store/companies/companies.store';
import { logsStore } from '../../../logs/store/logs.store';

export const useDossierDetail = (getCompanies = true): DossierDetail | null => {
	const [dossier, setDossier] = useState<DossierDetail | null>(null);

	useEffect(
		() => {
			const destroyed$: Subject<boolean> = new Subject<boolean>();

			dossierQuery.dossier$
				.pipe(takeUntil(destroyed$))
				.subscribe((detail: DossierDetail | null) => {
					if (detail && detail.activities && getCompanies) {
						companiesService.getMultiple((
							getUniqueCompaniesFromDossierDetail(detail.activities)
						));
					}
					setDossier(detail);
				});

			return (): void => {
				destroyed$.next(true);
				destroyed$.complete();
				dossierStore.reset();
				companiesStore.reset();
				voyageStore.reset();
				logsStore.reset();
			};
		},
		[getCompanies]
	);

	return dossier;
};
