import { Voyage, VoyageDetail } from '../../registries/voyages.types';

export interface VoyageState {
	info: Voyage | null;
	detail: VoyageDetail | null;
}

export function createInitialVoyageState(): VoyageState {
	return {
		info: null,
		detail: null,
	};
}
