import React, { FC } from 'react';
import ReactTable, { TableProps } from 'react-table';

import './Table.scss';

export const Table: FC<Partial<TableProps>> = ({
	data,
	columns,
	...rest
}) => (
	<ReactTable
		data={data}
		sortable={false}
		className="-highlight bg-white w-full shadow-md rounded-lg overflow-hidden"
		showPageJump={false}
		showPageSizeOptions={false}
		showPagination={false}
		columns={columns}
		minRows={0}
		resizable={false}
		manual
		{...rest}
	/>
);
