import './polyfills';

import React from 'react';
import { render } from 'react-dom';
import { akitaDevtools } from '@datorama/akita';

import App from './App';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	akitaDevtools();
}

render(<App />, document.querySelector('#root'));
