import { useState, useEffect } from 'react';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEmpty, equals } from 'ramda';

import { DossierLog, Log, LogQuery, ActivityLogType } from '../registries/logs.types';
import { logsQuery } from './logs.query';
import { DossierDetail } from '../../dossiers/registries/dossiers.types';
import { getLogQuery } from '../../../voyage/pages/VoyageDetail/VoyageDetail.helper';

export const useDossierLogs = (query: LogQuery, getAll = false): DossierLog[] => {
	const [dossierLogs, setDossierLogs] = useState<DossierLog[]>([]);

	useEffect(() => {
		if (isEmpty(query) && !getAll) {
			return (): void => {};
		}
		const destroyed$: Subject<boolean> = new Subject<boolean>();
		const logs$: Observable<DossierLog[]> = query.page && query.page > 1
			? logsQuery.addLogs(query)
			: logsQuery.getLogs(query);

		logsQuery.checkNextPage(query);

		logs$
			.pipe(takeUntil(destroyed$))
			.subscribe((results: DossierLog[]) => setDossierLogs(results));

		return (): void => {
			destroyed$.next(true);
			destroyed$.complete();
		};
	}, [query, getAll]);

	return dossierLogs;
};

export const useSingleDossier = (currentDossierLogs: DossierLog): Log[] => {
	const [logs, setLogs] = useState<Log[]>([]);

	useEffect(() => {
		if (currentDossierLogs && currentDossierLogs.logs) {
			setLogs(currentDossierLogs.logs);
		}
	}, [currentDossierLogs]);

	return logs;
};

export const useLogQuery = (
	dossier: DossierDetail | null,
	category: ActivityLogType = ActivityLogType.all,
	page = 1,
): LogQuery => {
	const [logQuery, setLogQuery] = useState<LogQuery>({});

	useEffect(() => {
		if (dossier && dossier.id) {
			const newQuery = getLogQuery(dossier.id, category, page);

			if (!equals(newQuery, logQuery)) {
				setLogQuery(newQuery);
			}
		}
	}, [dossier, category, page]); // eslint-disable-line react-hooks/exhaustive-deps

	return logQuery;
};
