import { DossierLogType, ActivityType } from '../../../core/core.types';

export enum ActivityLogType {
	all = 'All',
	general = 'General',
	activity = 'Activity'
}

export type ActivityLogTypes = 'General' | 'Activity';

export interface DossierLog {
	id: number;
	logs: Log[];
	page: number;
	isLastPage: boolean;
}

export interface UnreadLogs {
	id: number;
	logs: number[];
}

export interface Log {
	id: number;
	createdOn: string;
	dossierId: number;
	dossierLogType: DossierLogType;
	isAlert: boolean;
	stakeholderId: number;
	stakeholderName: string;
	stakeholderRoleCode: string;
	textMessage: string;
	activity1Id: number;
	liquidBulkActivity1Type: ActivityType;
	activity2Id: number;
	liquidBulkActivity2Type: ActivityType;
	vesselId: number;
	vesselName: string;
	valueDateTime: string;
	valueString: string;
	valueInt: number;
	isReadByCurrentStakeholder: boolean;
}

export type LogQuery = Partial<{
	dossier: number;
	category: string;
	alert: boolean;
	stakeholder: number;
	start: string;
	page: number;
	'page-size': number;
}>;

export interface ReadLogQuery {
	unreadItems: number[];
}
