import { route } from 'navi';

import { NOTIFICATIONS_ROUTES } from './notifications.const';
import { withAuthentication } from '../../router/router.helpers';

export default {
	[NOTIFICATIONS_ROUTES.NOTIFICATIONS_VIEW]: withAuthentication(route({
		title: 'Noticitaions',
		getView: () => import('./pages/NotificationsView/NotificationsView.page'),
	})),
};
