import L, { MarkerCluster, DivIcon } from 'leaflet';
import cx from 'classnames/bind';
import { pathOr } from 'ramda';

import styles from './ClusterMarker.module.scss';
import { CLUSTER_MARKER_ICON_SIZE, CLUSTER_MARKER_ANCHOR } from './CluserMarker.const';
import { MapOverviewMarkerColors } from '../../../../../berth/pages/MapOverview/MapOveriew.types';

const cxBind = cx.bind(styles);

export const ClusterMarker = (cluster: MarkerCluster): DivIcon => {
	const hasOwnVessel = !!cluster.getAllChildMarkers().some((marker) => {
		const className: string = pathOr('', ['options', 'icon', 'options', 'className'], marker);
		return className === 'a-marker--red';
	});

	return L.divIcon({
		html: `<span>${cluster.getChildCount()}</span>`,
		className: cxBind(
			'a-cluster-marker',
			`a-cluster-marker--${hasOwnVessel ? MapOverviewMarkerColors.Red : MapOverviewMarkerColors.Blue}`
		),
		iconSize: L.point(CLUSTER_MARKER_ICON_SIZE, CLUSTER_MARKER_ICON_SIZE, true),
		iconAnchor: L.point(CLUSTER_MARKER_ANCHOR, CLUSTER_MARKER_ANCHOR),
	});
};
