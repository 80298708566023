import React from 'react';
import { isNil } from 'ramda';
import cx from 'classnames';

import { ActivityClockProps } from './ActivityClock.props';
import { getClockColor } from './ActivityClock.helper';

import { formatTimeStamp, timeBetween, TimeFormats, AvailableTimeFormats } from '../../helpers/time/timeHelper';
import { Clock } from '../../../../assets/images';

import styles from './ActivityClock.module.scss';

export const ActivityClock: React.FC<ActivityClockProps> = ({ estTime, realTime }) => (
	<div className={styles['m-activity-clock']}>
		{
			isNil(realTime)
				? (
					<>
						<Clock className={cx(
							styles['a-activity-clock__icon'],
							styles['a-activity-clock__icon--gray']
						)}
						/>
						ET
						<div className={styles['a-activity__time']}>
							{
								formatTimeStamp(
									estTime,
									TimeFormats[AvailableTimeFormats.timeDateInWordsShort]
								)
							}
						</div>
					</>
				)
				: (
					<>
						<Clock className={cx(
							styles['a-activity-clock__icon'],
							styles[`a-activity-clock__icon--${
								getClockColor(timeBetween(estTime, realTime))}`],
						)}
						/>
						AT
						<div className={styles['a-activity__time']}>
							{
								formatTimeStamp(
									realTime,
									TimeFormats[AvailableTimeFormats.timeDateInWordsShort]
								)
							}
						</div>
					</>
				)
		}
	</div>
);
