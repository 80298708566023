import { TimelineOptions } from 'vis-timeline';
import { subHours, addHours } from 'date-fns';

import { hashString } from '../../helpers/generators/hash';

export const TIMELINE_DEFAULT_OPTIONS: TimelineOptions = {
	locale: 'en_EN',
	orientation: {
		axis: 'top',
		item: 'top',
	},
	margin: {
		item: {
			horizontal: 0,
		},
	},
	stack: true,
	zoomMin: 100000,
};

export const TIMELINE_DEFAULT_START = subHours(new Date(), 3);
export const TIMELINE_DEFAULT_END = addHours(new Date(), 5);

export const TIMELINE_ALERTS_ID = hashString('alerts');
