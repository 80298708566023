import {
	ActivityType,
	VoyageType,
	DateTimeWindow,
	Stakeholder,
	Input,
	CargoOperation,
	CargoContent,
	CargoUnit,
	VesselImoClass,
	AlertType,
	PropertyValue,
} from '../../../core/core.types';

export interface DossierAlert {
	id: number;
	createdOn: string;
	alertType: AlertType;
	activity1Id: number;
	liquidBulkActivity1Type: ActivityType;
	activity2Id: number;
	liquidBulkActivity2Type: ActivityType;
	textMessage: string | null;
}

export interface DossierCargoOverview {
	cargoQuantity: number;
	cargoUnit: string;
	cargoContents: string;
	cargoOperation: string;
	contentName: string;
}

export enum DossierCargoProp {
	cargoContents = 'cargoContents',
	cargoOperation = 'cargoOperation',
	cargoQuantity = 'cargoQuantity',
	contentName = 'contentName',
}

export interface Dossier {
	generatedForStakeholderId: number;
	id: number;
	clientStakeholderId: number;
	dossierNumber: string;
	requestingStakeholderReference: string[];
	portLocode: string;
	terminal: string;
	berth: string;
	stayNumber: string;
	voyageType: VoyageType;
	voyageNumber: string;
	voyageEstimateArrivalDateTime: string;
	voyageActualArrivalDateTime: string;
	voyageEstimateDepartureDateTime: string;
	voyageActualDepartureDateTime: string;
	vesselName: string;
	vesselTypeCode: string;
	vesselImo: string;
	vesselLengthMeters: number;
	vesselWidthMeters: number;
	vesselDepthMeters: number;
	cargoOverviews: DossierCargoOverview[];
	currentActivityType: ActivityType;
	currentActivityExpectedStartDateTime: string;
	currentActivityExpectedEndDateTime: string;
	currentActivityActualStartDateTime: string;
	currentActivityActualEndDateTime: string;
	nextStakeholderActivityType: ActivityType;
	nextStakeholderActivityExpectedStartDateTime: string;
	nextStakeholderActivityExpectedEndDateTime: string;
	nextStakeholderActivityActualStartDateTime: string;
	nextStakeholderActivityActualEndDateTime: string;
	alerts: DossierAlert[];
}

export enum GetDossiersSortField {
	ClientReference = 'ClientReference',
	DossierNumber = 'DossierNumber',
	NextActivityEstimatedStart = 'NextActivityEstimatedStart',
	StayNumber = 'StayNumber',
	VesselEta = 'VesselEta',
	VesselEtd = 'VesselEtd',
	VoyageNumber = 'VoyageNumber',
}

// eslint-disable-next-line max-len
export type GetDossierSortFields = 'ClientReference' | 'DossierNumber' | 'NextActivityEstimatedStart' | 'StayNumber' | 'VesselEta' | 'VesselEtd' | 'VoyageNumber';

export type DossierOptions = {
	portLocode?: string;
	terminal?: string;
	berth?: string;
	stay?: string;
	voyage?: string;
	'stakeholder-id'?: number;
	ref?: string;
	dossier?: string;
	departed?: boolean;
	imo?: string;
	'vessel-name'?: string;
	product?: CargoContent;
	'free-text'?: string;
	start?: string;
	end?: string;
	'order-by-field'?: GetDossiersSortField;
	'order-by'?: 'Ascending' | 'Descending';
	page: number;
	'page-size': number;
	version: string;
};

export interface DossierDetailVessel {
	id: number;
	imo: string;
	eni: string;
	callSign: string;
	msi: string;
	name: string;
	lengthMeters: number;
	widthMeters: number;
	depthMeters: number;
	actualDraughtMeters: number;
	maximumDraughtMeters: number;
	deadWeightTonnage: number;
	vesselTypeCode: string;
}

export interface DossierDetailActivity {
	id: number;
	cargoId: number;
	createdOn: string;
	updatedOn: string;
	inputs: Input[];
	dossierId: number;
	activityType: ActivityType;
	dateTimeWindow: DateTimeWindow;
	lastSourceStakeholder: Stakeholder;
	lastAssigneeStakeholder: Stakeholder;
	relatedVesselIds: number[];
	propertyValues: PropertyValue[];
	activityEvents: ActivityEvent[];
}

export interface ActivityEvent {
	activityEventDate: string;
	activityEventType: string;
}

export enum ActivityEventType {
	PortEngineArrivalTendered = 'NORT',
	PortEngineArrivalRetendered = 'NORR',
	PortEngineArrivalAccepted = 'NORA',
	CargoOperationsCargoArmConnected = 'CARC',
	CargoOperationsCargoArmDisconnected = 'CARD',
	CargoOperationsPaused = 'CARP',
	CargoOperationsResumed = 'CARR',
	InspectionDocumentsOnBoard = 'DOCS',
}

export interface DossierDetailCargo {
	id: number;
	sequence: number;
	reference: string;
	remarks: string;
	operation: CargoOperation;
	portOfLoading: string;
	portOfDischarge: string;
	content: CargoContent;
	contentName: string;
	quantity: number;
	quantityUnit: CargoUnit;
	loadingWindow: DateTimeWindow;
	dischargeWindow: DateTimeWindow;
	isHeated: boolean;
	unNumber: string;
	vesselImoClass: VesselImoClass;
	temperatureCelcius: number;
	tankCount: number;
	requiredConnectionType: string;
	doVapors: boolean;
	vaporsDurationHours: number;
}

export interface DossierDetail {
	id: number;
	createdOn: string;
	dossierNumber: string;
	creatorStakeholderId: number;
	clientStakeholderId: number;
	requestingStakeholderReference: Array<string | number>;
	portLocode: string;
	terminal: string;
	berth: string;
	voyageType: VoyageType;
	voyageNumber: number;
	stayNumber: string;
	vessel: DossierDetailVessel;
	activities: DossierDetailActivity[];
	currentActivityType: ActivityType;
	currentActivityExpectedStartDateTime: string;
	currentActivityExpectedEndDateTime: string;
	currentActivityActualStartDateTime: string | null;
	currentActivityActualEndDateTime: string | null;
	nextStakeholderActivityType: ActivityType;
	nextStakeholderActivityExpectedStartDateTime: string;
	nextStakeholderActivityExpectedEndDateTime: string;
	nextStakeholderActivityActualStartDateTime: string | null;
	nextStakeholderActivityActualEndDateTime: string | null;
	cargo: DossierDetailCargo[];
	alerts: DossierAlert[];
}
