import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { ApiError } from '../../../core/services/api.types';

import { LogQuery, DossierLog } from '../registries/logs.types';
import { LogsState } from './logs.model';
import { LogsStore, logsStore } from './logs.store';
import { logsService } from './logs.service';

export class LogsQuery extends QueryEntity <LogsState> {
	constructor(protected store: LogsStore) {
		super(store);
	}

	public isLoading$: Observable<boolean> = this.selectLoading();

	public hasError$: Observable<ApiError> = this.selectError();

	public getLogs(query: LogQuery): Observable<DossierLog[]> {
		logsService.get(query);

		return this.selectAll();
	}

	public addLogs(query: LogQuery): Observable<DossierLog[]> {
		logsService.add(query);

		return this.selectAll();
	}

	public checkNextPage(query: LogQuery): void {
		if (query.page) {
			logsService.checkNextPage({
				...query,
				page: query.page + 1,
			});
		}
	}

	public countLogs$: Observable<number> = this.selectCount();
}

export const logsQuery = new LogsQuery(logsStore);
