import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { userQuery } from '../store';
import { Profile } from '../store/user.model';

export const useProfile = (): Profile | null => {
	const [profile, setProfile] = useState<null | Profile>(null);

	useEffect(
		() => {
			const destroyed$: Subject<boolean> = new Subject<boolean>();

			userQuery.profile$
				.pipe(takeUntil(destroyed$))
				.subscribe((userProfile: Profile | null) => setProfile(userProfile));

			return (): void => {
				destroyed$.next(true);
				destroyed$.complete();
			};
		},
		[profile]
	);

	return profile;
};
