import { VesselPosition } from '../../../lib/vessels/registries/vessels.types';
import { VoyageDetail } from '../../../lib/voyages/registries/voyages.types';
import { Dossier } from '../../../lib/dossiers/registries/dossiers.types';

export interface FilteredVesselPositions {
	ownVessels: VesselPosition[];
	otherVessels: VesselPosition[];
}

export enum MapOverviewMarkerColors {
	Red = 'red',
	Blue = 'blue',
}

export type MapOverviewMarkerColor = MapOverviewMarkerColors.Red | MapOverviewMarkerColors.Blue;

export interface MapOverviewState {
	showOwnVesselsOnly: boolean;

	vesselPositionsLoading: boolean;
	dossiersLoading: boolean;

	voyageLoading: boolean;

	activeVoyage: VoyageDetail | null;
	activeDossier: Dossier | null;
	activeVessel: VesselPosition | null;

	dossiers: Dossier[];

	error?: string | null;
}
