import React from 'react';
import cx from 'classnames/bind';

import { ButtonProps } from './Button.props';
import { ButtonSizes, ButtonWeights } from './Button.types';
import { getButtonSize, getButtonWeight } from './Button.helpers';

import styles from './Button.module.scss';

const cxBind = cx.bind(styles);

export const Button: React.FC<ButtonProps> = ({
	children,
	type,
	action,
	size = ButtonSizes.normal,
	weight = ButtonWeights.normal,
	rounded = false,
	icon,
	className,
	disabled = false,
}) => (
	<button
		type="button"
		className={cxBind(
			'a-button',
			`a-button--${type}`,
			getButtonSize(size),
			getButtonWeight(weight),
			rounded && 'a-button--rounded',
			disabled && 'a-button--disabled',
			className,
		)}
		onClick={action}
		disabled={disabled}
	>
		<span className={cxBind('a-button__content')}>
			{icon && (
				<span className={cxBind('a-button__icon')}>
					{icon}
				</span>
			)}
			{children}
		</span>
	</button>
);
