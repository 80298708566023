import { pathOr } from 'ramda';

import { DossierDetail } from '../../../../lib/dossiers/registries/dossiers.types';
import { InfoColumnContent } from '../components/InfoRow/InfoColumn/InfoColumn.types';

/* eslint-disable quote-props */
export default (dossier: DossierDetail): InfoColumnContent => ({
	'Vessel name': pathOr('-', ['vessel', 'name'], dossier),
	'Imo number': pathOr('-', ['vessel', 'imo'], dossier),
	'Vessel length (m)': pathOr('-', ['vessel', 'lengthMeters'], dossier),
	'Vessel width (m)': pathOr('-', ['vessel', 'widthMeters'], dossier),
	'Vessel draught (m)': pathOr('-', ['vessel', 'depthMeters'], dossier),
});
/* eslint-enable quote-props */
