export enum AlertType {
	Conflict = 'Conflict',
	BehindNoConflictNoGap = 'BehindNoConflictNoGap',
	AheadNoConflictNoGap= 'AheadNoConflictNoGap',
	BehindNoConflictWithGap = 'BehindNoConflictWithGap',
	AheadNoConflictWithGap = 'AheadNoConflictWithGap',
}

export enum ActivityType {
	Analysis = 'Analysis',
	Departure = 'Departure',
	DischargeWindow = 'DischargeWindow',
	Discharging = 'Discharging',
	Inspection = 'Inspection',
	Loading = 'Loading',
	LoadingLaycan = 'LoadingLaycan',
	Sampling = 'Sampling',
	ToBerth = 'ToBerth',
	Vapours = 'Vapours',
}

export enum CargoContent {
	Rgp = 'Rgp',
}

export enum CargoOperation {
	Discharge = 'Discharge',
	Load = 'Load',
}

export enum CargoUnit {
	m3 = 'm3',
}

export enum DataType {
	LiquidBool = 'LiquidBool',
	LiquidDate = 'LiquidDate',
	LiquidDecimal = 'LiquidDecimal',
	LiquidInt = 'LiquidInt',
	LiquidString = 'LiquidString',
}

export enum DossierLogType {
	ActivityConflict = 'ActivityConflict',
	ActivityCreated = 'ActivityCreated',
	ActivityDeleted = 'ActivityDeleted',
	ActivityEnded = 'ActivityEnded',
	ActivityEndEstimateUpdated = 'ActivityEndEstimateUpdated',
	ActivityStarted = 'ActivityStarted',
	ActivityStartEstimateUpdated = 'ActivityStartEstimateUpdated',
	DossierEnded = 'DossierEnded',
	DossierGranted = 'DossierGranted',
	DossierRevoked = 'DossierRevoked',
	DossierStakeholderAdded = 'DossierStakeholderAdded',
	DossierStakeholderRemoved = 'DossierStakeholderRemoved',
	DossierUpdated = 'DossierUpdated',
	AlertCreated = 'AlertCreated',
}

export enum DossierReferenceStakeholderType {
	Assignee = 'Assignee',
	Client = 'Client',
	Source = 'Source',
}

export enum InputType {
	Analysis = 'Analysis',
	CargoOperations = 'CargoOperations',
	Dossier = 'Dossier',
	Inspection = 'Inspection',
	PortEngineArrival = 'PortEngineArrival',
	PortEngineDeparture = 'PortEngineDeparture',
	Sampling = 'Sampling',
	ShippingAgent = 'ShippingAgent',
}

export enum SortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending',
}

export enum SourceType {
	Email = 'Email',
	Ftp = 'Ftp',
}

/* eslint-disable @typescript-eslint/camelcase */
export enum SupplyChainRole {
	LB_Agent = 'LB_Agent',
	LB_Surveyor = 'LB_Surveyor',
	LB_Terminal = 'LB_Terminal',
	LB_Operator = 'LB_Operator',
	LB_Cargo = 'LB_Cargo',
	LB_Unspecified = 'LB_Unspecified',
	LB_Ship = 'LB_Ship',
	LB_Admin = 'LB_Admin',
}
/* eslint-enable @typescript-eslint/camelcase */

export enum VesselImoClass {
	One = 'One',
	Three = 'Three',
	Two = 'Two',
}

export enum VoyageType {
	In = 'In',
	Out = 'Out',
	Shift = 'Shift',
}

export interface DateTimeWindow {
	beginEstimate: string;
	endEstimate: string;
	beginActual: string | null;
	endActual: string | null;
}

export interface Stakeholder {
	id: number;
	name: string;
	reference: string;
	supplyChainRole: SupplyChainRole;
	vat: string;
}

export interface Input {
	id: number;
	createdOn: string;
}

export interface PropertyValue {
	name: string;
	value: string;
	dataType: DataType;
}

export interface Port {
	name: string;
	locode: string;
}
