import React, { FC } from 'react';

import { Timeline } from '../Timeline/Timeline';
import { BerthOverviewTimelineProps } from './BerthOverviewTimeline.props';
import { useBerthOverviewItems, useBerthOverviewGroups } from './hooks';

export const BerthOverviewTimeline: FC<BerthOverviewTimelineProps> = ({
	voyages,
	berths,
	dossiers,
	onTimelineItemClick,
	onTimelineViewportChange,
	options,
	endDate,
	startDate,
	timelineClass,
}) => {
	const items = useBerthOverviewItems(voyages, dossiers);
	const groups = useBerthOverviewGroups(berths);

	return (
		<Timeline
			items={items}
			groups={groups}
			options={options}
			startDate={startDate}
			endDate={endDate}
			onTimelineItemClick={onTimelineItemClick}
			onTimelineViewportChange={onTimelineViewportChange}
			timelineClass={timelineClass}
		/>
	);
};
