import React, { ReactNode } from 'react';

import { PartyListProps } from './PartyList.types';
import { PartyListItem } from './PartyListItem/PartyListItem';

import styles from './PartyList.module.scss';

export const PartyList: React.FC<PartyListProps> = ({ parties }) => (
	<div className={styles['o-party-list']}>
		{
			parties.map((item, index): ReactNode => {
				if (
					!item
					|| !item.name
					|| !item.role
				) {
					return null;
				}

				return (
					<PartyListItem
						key={`party-${item.name + index}-list-item`}
						title={item.name}
						address={item.address}
						phone={item.phone}
						email={item.email}
						role={item.role}
					/>
				);
			})
		}
	</div>
);
