import React, { FC } from 'react';
import cx from 'classnames/bind';

import { MapMessageProps } from './MapMessage.props';
import styles from './MapMessage.module.scss';

const cxBind = cx.bind(styles);

export const MapMessage: FC<MapMessageProps> = ({
	children,
}) => (
	<div className={cxBind('m-map-message')}>
		<div
			className={cxBind('m-map-message__content')}
		>
			{children}
		</div>
	</div>
);
