import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { pathOr } from 'ramda';

import { ApiError } from '../../../../core/services/api.types';
import { Voyage, VoyagePassage, VoyageDetail } from '../../registries/voyages.types';
import { VoyageState } from './voyage.model';
import { VoyageStore, voyageStore } from './voyage.store';

export class VoyageQuery extends Query<VoyageState> {
	constructor(protected store: VoyageStore) {
		super(store);
	}

	public isLoading$: Observable<boolean> = this.selectLoading();

	public hasError$: Observable<ApiError> = this.selectError();

	public voyage$: Observable<Voyage | null> = this.select('info');

	public detail$: Observable<VoyageDetail | null> = this.select('detail');

	public passages$: Observable<VoyagePassage[]> = this.select((state: VoyageState) => (
		pathOr([], ['info', 'voyages', 'passages'], state)
	));
}

export const voyageQuery = new VoyageQuery(voyageStore);
