import { subMilliseconds } from 'date-fns';
import React, { Suspense } from 'react';
import { Router, View } from 'react-navi';
import { ToastContainer } from 'react-toastify';

import routes from './router';

import { logsService } from './modules/lib/logs/store/logs.service';
import { useIsAuthenticated } from './modules/user/hooks';
import { useFetchUserInfoAndCheckLBRole } from './modules/user/hooks/useFetchUserInfoAndCheckLBRole';
import { POLLING_INTERVAL } from './App.const';

import './index.scss';

window.setInterval(() => {
	const date = subMilliseconds(new Date(), POLLING_INTERVAL);
	logsService.polNotifications({ start: date.toISOString() });
}, POLLING_INTERVAL);

const App: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	const isLiquidBulkUser = useFetchUserInfoAndCheckLBRole();

	return (
		<Router
			routes={routes}
			context={{
				isAuthenticated,
				isLiquidBulkUser,
			}}
		>
			<Suspense fallback={null}>
				<View />
			</Suspense>
			<ToastContainer
				bodyClassName="reactToastifyBody"
			/>
		</Router>
	);
};

export default App;
