import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { userQuery } from '../store';

export const useIsLiquidBulkUser = (): boolean | null => {
	const [isLiquidBulkUser, setIsAuthenticated] = useState<null | boolean>(null);

	useEffect(
		() => {
			const destroyed$: Subject<boolean> = new Subject<boolean>();

			userQuery.isLiquidBulkUser$
				.pipe(
					takeUntil(destroyed$),
					filter((hasRole: boolean) => hasRole !== isLiquidBulkUser)
				)
				.subscribe((hasRole: boolean) => setIsAuthenticated(hasRole));

			return (): void => {
				destroyed$.next(true);
				destroyed$.complete();
			};
		},
		[isLiquidBulkUser]
	);

	return isLiquidBulkUser;
};
