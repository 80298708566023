import { propOr } from 'ramda';
import { Profile, IDTokenPayload, AccessTokenPayload, UserInfoPayload } from '../../store/user.model';

export const mapUserProfileByTokens = (
	idToken: IDTokenPayload,
	accessToken: AccessTokenPayload
): Profile => ({
	id: propOr(null, 'sub', idToken),
	email: propOr('', 'nxt:user:email', idToken),
	firstname: propOr('', 'given_name', idToken),
	lastname: propOr('', 'family_name', idToken),
	roles: {
		entityRoles: propOr([], 'nxt:entity:roles', accessToken),
		standard: propOr([], 'nxt:user:roles', accessToken),
		sandbox: propOr([], 'nxt:user:roles:sandbox', accessToken),
	},
	company: {
		id: propOr(null, 'nxt:entity:id', accessToken),
		nxtEntityId: propOr(null, 'nxt:entity:nxtentityid', accessToken),
		name: propOr('', 'nxt:entity:name', accessToken),
		domain: propOr('', 'nxt:entity:domain', accessToken),
		vat: propOr('', 'nxt:entity:vat', accessToken),
	},
});

export const mapUserProfileByUserInfo = (
	userInfo: UserInfoPayload,
): Profile => ({
	id: propOr(null, 'sub', userInfo),
	email: propOr('', 'nxt:user:email', userInfo),
	firstname: propOr('', 'given_name', userInfo),
	lastname: propOr('', 'family_name', userInfo),
	roles: {
		entityRoles: propOr([], 'nxt:entity:roles', userInfo),
		standard: propOr([], 'nxt:user:roles', userInfo),
		sandbox: propOr([], 'nxt:user:roles:sandbox', userInfo),
	},
	company: {
		id: propOr(null, 'nxt:entity:id', userInfo),
		nxtEntityId: propOr(null, 'nxt:entity:nxtentityid', userInfo),
		name: propOr('', 'nxt:entity:name', userInfo),
		domain: propOr('', 'nxt:entity:domain', userInfo),
		vat: propOr('', 'nxt:entity:vat', userInfo),
	},
});
