import { SupplyChainRole } from '../../../core/core.types';

export const getCompanyColor = (role: SupplyChainRole | null): string => {
	switch (role) {
		case SupplyChainRole.LB_Agent:
			return 'blue';

		case SupplyChainRole.LB_Surveyor:
			return 'green';

		case SupplyChainRole.LB_Operator:
		case SupplyChainRole.LB_Terminal:
			return 'orange';

		case SupplyChainRole.LB_Cargo:
			return 'red';

		default:
			return '';
	}
};
