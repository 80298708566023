import { ActivityLogType } from '../../../lib/logs/registries/logs.types';

export const activityLogFilters = [
	{
		id: ActivityLogType.all,
		title: ActivityLogType.all,
	},
	{
		id: ActivityLogType.general,
		title: ActivityLogType.general,
	},
	{
		id: ActivityLogType.activity,
		title: ActivityLogType.activity,
	},
];
