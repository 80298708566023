import { isNil, isEmpty, pathOr } from 'ramda';

import {
	DossierDetailActivity,
	DossierAlert,
} from '../../../lib/dossiers/registries/dossiers.types';
import { ImpactedActor } from '../../components/AlertSection/AlertSection.types';

export const hasAlert = (activityId: number, alerts: DossierAlert[]): boolean => {
	const alert = alerts.find((alertItem) => (
		activityId === alertItem.activity1Id || activityId === alertItem.activity2Id
	));

	return !isNil(alert);
};

export const getAlerts = (activityId: number, alerts: DossierAlert[]): DossierAlert[] => {
	const activityAlerts = alerts.filter((alert) => (
		activityId === alert.activity1Id || activityId === alert.activity2Id
	));

	return activityAlerts;
};

// Gets the impacted actors for a specific activity
export const getImpactedActors = (
	activeActivity: DossierDetailActivity,
	allActivities: DossierDetailActivity[],
	alerts: DossierAlert[],
): ImpactedActor => {
	const impactedActors: ImpactedActor = {};

	// Checks if it there is an id and if it has any alerts
	if (!activeActivity || !hasAlert(activeActivity.id, alerts)) {
		return impactedActors;
	}

	// Cets all of the alerts
	const activityAlerts = getAlerts(activeActivity.id, alerts);

	// Checks if alerts is empty
	if (isEmpty(activityAlerts)) {
		return impactedActors;
	}

	// Maps over all alerts to find the specific activity
	// That is in conflict with the current activity
	// Returns an object with the id as key and the name of the actor as a value
	return activityAlerts
		.map(({ activity1Id, activity2Id }) => {
			const alertActivityId = (activity1Id === activeActivity.id) ? activity2Id : activity1Id;

			return allActivities.find((activity): boolean => activity.id === alertActivityId);
		})
		.filter((actor) => actor)
		.reduce((acc: ImpactedActor, curr) => {
			if (curr) {
				acc[curr.id] = pathOr('-', ['lastAssigneeStakeholder', 'name'], curr);
			}

			return acc;
		}, {});
};
