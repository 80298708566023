import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames/bind';

import { ESC_KEY } from '../../core.const';

import { ModalProps } from './Modal.props';
import styles from './Modal.module.scss';

const cxBind = cx.bind(styles);


export const Modal: React.FC<ModalProps> = ({
	visible,
	onClose,
	children,
	header,
	footer,
	padding = true,
	size = 'normal',
}) => {
	const onKeyPress = (e: KeyboardEvent): void => {
		if (e.keyCode === ESC_KEY) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('keyup', onKeyPress, false);
		return (): void => {
			document.removeEventListener('keyup', onKeyPress, false);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (visible) {
		return createPortal(
			<>
				<div
					className={cxBind({
						'o-modal': true,
						'o-modal--small': size === 'small',
						'o-modal--borderless': !padding,
					})}
				>
					<div
						role="presentation"
						onClick={(): void => onClose()}
						className={cx(cxBind('o-modal__overlay'))}
					/>
					<div className={cx(cxBind('o-modal__wrapper'))}>
						{header}

						<div className={cxBind('o-modal__body')}>
							{children}
						</div>

						{footer}
					</div>
				</div>
			</>,
			document.body
		);
	}
	return null;
};
