import React from 'react';
import cx from 'classnames/bind';

import { InfoBoxProps } from './InfoBox.props';
import styles from './InfoBox.module.scss';

const cxBind = cx.bind(styles);

export const InfoBox: React.FC<InfoBoxProps> = ({
	children,
	active,
	fullWidth = false,
}) => (
	<article
		className={cxBind({
			'm-infoBox': true,
			'm-infoBox--active': active,
			'm-infoBox--full-width': fullWidth,
		})}
	>
		{children}
	</article>
);
