import React, { useState } from 'react';
import cx from 'classnames/bind';

import { AccordeonProps } from './Accordeon.props';
import styles from './Accordeon.module.scss';

import { Triangle } from '../../../../assets/images';

const cxBind = cx.bind(styles);

export const Accordeon: React.FC<AccordeonProps> = ({
	header,
	children,
	onClick = (): void => {},
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			className={cxBind('o-accordeon')}
			role="listitem"
			onClick={(): void => {
				setIsOpen(!isOpen);
				onClick(!isOpen);
			}}
			onKeyDown={(): void => {
				setIsOpen(!isOpen);
				onClick(!isOpen);
			}}
		>
			<div className={cxBind('m-accordeon-header')}>
				<Triangle
					className={cxBind({
						'a-close-button': true,
						'a-close-button--open': isOpen,
					})}
				/>
				{header && header}
			</div>
			<div
				className={cxBind({
					'm-accordeon-children': true,
					'm-accordeon-children--open': isOpen,
				})}
			>
				{children && children}
			</div>
		</div>
	);
};
