import React from 'react';

import { Button } from '../../../../../core/components';
import { ButtonTypes } from '../../../../../core/components/Button/Button.types';

import { InfoSelectionProps, InfoSelectionOption } from './InfoSelection.types';

export const InfoSelection: React.FC<InfoSelectionProps> = ({
	options,
	onSelect,
	selectedOption,
}) => (
	<div className="mb-8">
		{
			options.map((option: InfoSelectionOption, i: number) => (
				<Button
					type={
						option === selectedOption
							? ButtonTypes.TabPrimary
							: ButtonTypes.TabPrimaryOutline
					}
					key={`info-selection--${option}`}
					action={(): void => onSelect(option)}
					className={i < options.length - 1 ? 'mr-3' : ''}
				>
					{option}
				</Button>
			))
		}
	</div>
);
