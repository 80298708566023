import hash from 'object-hash';

const nonce = hash({ nonce: Math.random() * Math.random() * Date.now() * Math.random() });

export const USER_ROUTES = Object.freeze({
	CALLBACK: '/callback',
	LOGOUT: '/logout',
	LOGIN: '/login',
});

export const AUTH_BASE_URL = process.env.REACT_APP_NEXTPORT_AUTH_BASE_URL;

export const AUTH_LOGOUT_URL = process.env.REACT_APP_NEXTPORT_AUTH_LOGOUT_URL;

export const AUTH_LOGIN_URL = `${AUTH_BASE_URL}
								?client_id=${process.env.REACT_APP_NEXTPORT_CLIENT_ID}
								&scope=openid%20offline_access%20profile%20email%20%20https%3A%2F%2Fportplusb2cdev.onmicrosoft.com%2Fliquidbuilk%2Fliquidbulk
								&redirect_uri=${process.env.REACT_APP_CALLBACK_REDIRECT_URI}
								&nonce=123
								&response_type=token id_token
							`;
