import React, { useState } from 'react';
import { propOr, pathOr } from 'ramda';

import { TimelineToggle } from '../TimelineToggle/TimelineToggle';
import { ActivityList } from '../ActivityList/ActivityList';
import { ActivityVisualisationTypes, ActivityVisualisationType } from '../TimelineToggle/TimelineToggle.types';

import { Button, ActivityTimeline } from '../../../core/components';
import { ButtonTypes, ButtonWeights } from '../../../core/components/Button/Button.types';
import { TIMELINE_DEFAULT_START, TIMELINE_DEFAULT_END } from '../../../core/components/Timeline/Timeline.const';
import { Clock, PinPoint } from '../../../../assets/images';
import {
	getInboundActivities,
	getAtBerthActivities,
	getOutboundActivities,
} from '../../pages/VoyageDetail/VoyageDetail.const';

import { VoyageDetailActivitySectionProps } from './VoyageDetailActivitySection.props';
import { VoyagePassage } from '../../../lib/voyages/registries/voyages.types';
import { ActivityType } from '../../../core/core.types';
import { getActualOrEstimateTime } from '../../../core/helpers/time/timeHelper';


export const VoyageDetailActivitySection: React.FC<VoyageDetailActivitySectionProps> = ({
	dossier,
	voyage,
	defaultActivityView,
	onActivityClick,
}) => {
	const [timelineStartDate, setTimelineStartDate] = useState<Date | null>(null);
	const [timelineEndDate, setTimelineEndDate] = useState<Date | null>(null);

	const [
		activityVisualisationType,
		setActivityVisualisationType,
	] = useState<ActivityVisualisationTypes>(defaultActivityView);

	const handleTimelineToggle = ({ target }: React.FormEvent<HTMLFormElement>): void => {
		setActivityVisualisationType(propOr(ActivityVisualisationType.timeline, 'value', target));
	};

	const handleTimelineViewUpdate = (start: Date | null = null, end: Date | null = null): void => {
		setTimelineStartDate(start);
		setTimelineEndDate(end);
	};

	const passagePoints = pathOr([] as VoyagePassage[], ['voyages', 'passages'], voyage);

	const toBerthActivity = dossier.activities.find((activity) => activity.activityType === ActivityType.ToBerth);
	const departureActivity = dossier.activities.find((activity) => activity.activityType === ActivityType.Departure);

	const TIMELINE_ACTIVITY_START = toBerthActivity
		? getActualOrEstimateTime(toBerthActivity.dateTimeWindow, true)
		: null;

	const TIMELINE_ACTIVITY_END = departureActivity
		? getActualOrEstimateTime(departureActivity.dateTimeWindow, false)
		: null;

	return (
		<>
			{dossier && (
				<section className="w-full mt-12">
					<header className="mb-4">
						<h2>Activity</h2>
					</header>

					<div className="flex justify-between mb-4">
						<TimelineToggle
							onChange={handleTimelineToggle}
							activityVisualisation={activityVisualisationType}
						/>
						{
							activityVisualisationType === 'timeline' && (
								<div>
									<Button
										type={ButtonTypes.Default}
										weight={ButtonWeights.light}
										disabled={!TIMELINE_ACTIVITY_START || !TIMELINE_ACTIVITY_END}
										className="mr-2"
										action={(): void => {
											handleTimelineViewUpdate(
												TIMELINE_ACTIVITY_START,
												TIMELINE_ACTIVITY_END
											);
										}}
										icon={<Clock />}
									>
										Activity view
									</Button>
									<Button
										type={ButtonTypes.Default}
										weight={ButtonWeights.light}
										action={(): void => handleTimelineViewUpdate()}
										className="mr-2"
										icon={<PinPoint />}
									>
										Voyage overview
									</Button>
									<Button
										type={ButtonTypes.Default}
										weight={ButtonWeights.light}
										action={(): void => {
											handleTimelineViewUpdate(
												TIMELINE_DEFAULT_START,
												TIMELINE_DEFAULT_END
											);
										}}
										icon={<Clock />}
									>
										Real-time
									</Button>
								</div>
							)
						}
					</div>

					{
						activityVisualisationType === 'timeline' && (
							<ActivityTimeline
								activities={dossier.activities}
								alerts={dossier.alerts}
								cargo={dossier.cargo}
								passagePoints={passagePoints}
								startDate={timelineStartDate}
								endDate={timelineEndDate}
								onTimelineItemClick={onActivityClick}
								onTimelineViewportChange={({ startDate, endDate }): void => {
									handleTimelineViewUpdate(
										startDate,
										endDate
									);
								}}
							/>
						)
					}

					{activityVisualisationType === 'list' && (
						<>
							<ActivityList
								title="Inbound"
								activities={getInboundActivities(dossier)}
								cargo={dossier.cargo}
								alerts={dossier.alerts}
								onClick={onActivityClick}
							/>
							<ActivityList
								title="At Berth"
								activities={getAtBerthActivities(dossier)}
								cargo={dossier.cargo}
								alerts={dossier.alerts}
								onClick={onActivityClick}
							/>
							<ActivityList
								title="Outbound"
								activities={getOutboundActivities(dossier)}
								cargo={dossier.cargo}
								alerts={dossier.alerts}
								onClick={onActivityClick}
							/>
						</>
					)}
				</section>
			)}
		</>
	);
};
