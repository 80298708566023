import React, { ReactNode } from 'react';
import cx from 'classnames';
import { Route } from 'navi';
import { useCurrentRoute } from 'react-navi';

import { RouterLink } from '../RouterLink/RouterLink';

import { ROUTERLINKS, NEEDS_LB_ACCOUNT } from './Header.const';
import { RouterItem } from './Header.types';

import {
	Logo,
	Profile,
	Logout,
	Cog,
} from '../../../../assets/images';

import { Token } from '../../../user/store/user.model';
import { parseLogoutUrl } from '../../../user/helpers/auth/logoutHelper';
import { useIsAuthenticated, useProfile, useIsLiquidBulkUser } from '../../../user/hooks';
import { useToken } from '../../../user/hooks/useToken';

import styles from './Header.module.scss';

const renderLink = (routerItem: RouterItem, currentRoute: Route, isLiquidBulkUser: boolean | null): ReactNode => {
	const activeLinkStyles = styles['o-header__link--active'];
	const isCurrentRoute = (route: string): boolean => currentRoute.url.href.includes(route);

	if (NEEDS_LB_ACCOUNT.includes(routerItem.link) && !isLiquidBulkUser) {
		return null;
	}

	return (
		<RouterLink
			active={isCurrentRoute(routerItem.link)}
			className={cx('mb-1', styles['o-header__link'])}
			activeClassName={activeLinkStyles}
			href={routerItem.link}
			key={`header-link-${routerItem.label}`}
		>
			{routerItem.icon}
			<div className={styles['m-nav-link']}>
				<span className={styles['a-nav-text']}>
					{routerItem.label}
				</span>
			</div>
		</RouterLink>
	);
};

export const Header: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	const isLiquidBulkUser = useIsLiquidBulkUser();
	const profile = useProfile();
	const token: Token | null = useToken();

	const currentRoute: Route = useCurrentRoute();
	const isCurrentRoute = (route: string): boolean => currentRoute.url.href === route;

	return (
		<header className={styles['o-header']}>
			<nav className={cx('u-grid-container', styles['o-header__wrapper'])}>
				<div className="u-grid-container">
					<RouterLink
						active={isCurrentRoute('/')}
						className={cx('mb-1', styles['o-header__link'])}
						href="/"
					>
						<Logo className={styles['a-logo']} />
					</RouterLink>
					{
						ROUTERLINKS
							.map((item: RouterItem) => renderLink(item, currentRoute, isLiquidBulkUser))
					}
				</div>
				<div className="u-grid-container">
					<div
						className={cx(
							'mb-8',
							styles['o-header__link'],
							styles['a-profile-link']
						)}
					>
						<Profile
							className={styles['a-nav-icon']}
						/>
						<div className={styles['m-profile']}>
							<div
								className={cx(
									styles['a-profile-item'],
									styles['a-profile-item__header'],
									'flex flex-col items-start',
								)}
							>
								<span className={cx(styles['a-nav-text'], styles['a-nav-text--small'])}>
									{profile && `${profile.firstname} ${profile.lastname}`}
								</span>
								<span className={cx(styles['a-nav-text'], styles['a-nav-text--small'], 'mt-1')}>
									{profile && `${profile.company.vat}`}
								</span>
							</div>
							<a
								className={styles['a-profile-item']}
								href={process.env.REACT_APP_PROFILE_URL}
								style={{ display: 'none' }}
							>
								<Cog className={styles['a-profile-item__icon']} />
								<span
									className={styles['a-profile-item__label']}
								>
									Account settings
								</span>
							</a>
							{isAuthenticated && token && token.id_token && (
								<a
									className={styles['a-profile-item']}
									href={parseLogoutUrl(token.id_token)}
								>
									<Logout className={styles['a-profile-item__icon']} />
									<span className={styles['a-profile-item__label']}>
										Log out
									</span>
								</a>
							)}
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
};
