import { route } from 'navi';

import { BERTH_ROUTES } from './berth.const';
import { withAuthentication } from '../../router/router.helpers';

export default {
	[BERTH_ROUTES.BERTH_OVERVIEW]: withAuthentication(route({
		title: 'Voyages Overview',
		getView: () => import('./pages/BerthOverview/BerthOverview.page'),
	})),
	[BERTH_ROUTES.MAP_OVERVIEW]: withAuthentication(route({
		title: 'Map Overview',
		getView: () => import('./pages/MapOverview/MapOverview.page'),
	})),
};
