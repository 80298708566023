import { isEmpty, isNil } from 'ramda';
import { ApiError } from '../../../../core/services/api.types';
import { VesselsRegistry, vesselsRegistry } from '../../registries/vessels.registry';
import { VesselPosition } from '../../registries/vessels.types';

import { VesselsStore, vesselsStore } from './vessels.store';
import { vesselsFactory } from './helpers/VesselsFactory';


export class VesselsService {
	constructor(
		private store: VesselsStore,
		private vessels: VesselsRegistry,
	) {
		this.store = store;
		this.vessels = vessels;
	}

	public getAllPositions(): void {
		this.store.setLoading(true);

		this.vessels.fetchPositions()
			.subscribe(
				(results: VesselPosition[]) => {
					this.store.setLoading(false);
					this.store.update({
						positions: vesselsFactory(results),
					});
				},
				(error: ApiError) => {
					this.store.setLoading(false);
					this.store.setError(error);
				}
			);
	}

	public getOnePosition(imo: string): void {
		if (isNil(imo) || isEmpty(imo)) {
			return;
		}

		this.store.setLoading(true);

		this.vessels.fetchPosition(imo)
			.subscribe(
				(result: VesselPosition) => {
					this.store.setLoading(false);
					this.store.update({
						activePosition: result,
					});
				},
				(error: ApiError) => {
					this.store.setLoading(false);
					this.store.setError(error);
				}
			);
	}

	public setFilter(value: string | null): void {
		if (!value || (typeof value === 'string' && value.length === 0)) {
			this.store.update({
				filter: null,
			});
			return;
		}

		this.store.update({
			filter: value,
		});
	}

	public showOwnVesselsOnly(showOwnVesselsOnly: boolean): void {
		this.store.update({
			showOwnVesselsOnly,
		});
	}
}

export const vesselsService = new VesselsService(vesselsStore, vesselsRegistry);
