import React, { FC, ReactNode } from 'react';
import { FormikHandlers } from 'formik';

import {
	Clock,
	List,
} from '../../../../assets/images';

import './RadioButton.scss';

export interface RadioButtonProps {
	field: {
		onChange: FormikHandlers['handleChange'];
		onBlur: FormikHandlers['handleBlur'];
		value: string;
		name: string;
	};
	id: string;
	label: string;
	className?: string;
	icon?: 'list' | 'clock';
}

export const RadioButton: FC<RadioButtonProps> = ({
	field,
	id,
	label,
	icon,
}) => {
	const {
		name,
		value,
		onChange,
		onBlur,
	} = field;

	const renderIcon = (iconName: string): ReactNode => {
		switch (iconName) {
			case 'list':
				return (
					<List className="a-radio-button__icon" />
				);
			case 'clock':
				return (
					<Clock className="a-radio-button__icon" />
				);

			default:
				return null;
		}
	};

	return (
		<div className="a-radio-button">
			<input
				className="a-radio-button__input"
				name={name}
				id={id}
				type="radio"
				value={id}
				checked={id === value}
				onChange={onChange}
				onBlur={onBlur}
			/>
			<label htmlFor={id} className="a-radio-button__label">
				<span className="a-radio-button__content">
					{icon && renderIcon(icon)} {label}
				</span>
			</label>
		</div>
	);
};
