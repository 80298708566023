import React, { ReactNode } from 'react';


import {
	Inbound,
	Outbound,
	Inspection,
	Sampling,
	Analysis,
	Discharging,
	Loading,
	Vapours,
	Forbidden,
	Bollard,
	Ship,
	Anchor,
} from '../../../../assets/images';
import { ActivityType } from '../../core.types';
import { ArriveDepartActvity } from '../../../lib/arriveDepart/registries/arriveDepart.types';

export const getActivityIcon = (activityType: ActivityType | null, classes = ''): ReactNode => {
	switch (activityType) {
		case ActivityType.LoadingLaycan:
		case ActivityType.ToBerth:
			return <Inbound className={classes} />;
		case ActivityType.DischargeWindow:
		case ActivityType.Departure:
			return <Outbound className={classes} />;
		case ActivityType.Sampling:
			return <Sampling className={classes} />;
		case ActivityType.Analysis:
			return <Analysis className={classes} />;
		case ActivityType.Discharging:
			return <Discharging className={classes} />;
		case ActivityType.Vapours:
			return <Vapours className={classes} />;
		case ActivityType.Loading:
			return <Loading className={classes} />;
		case ActivityType.Inspection:
			return <Inspection className={classes} />;
		default:
			return <Forbidden className={classes} />;
	}
};

export const getArriveDepartActivityIcon = (activity: ArriveDepartActvity, classes = ''): ReactNode => {
	switch (activity) {
		case 'At berth':
			return <Bollard className={classes} />;
		case 'Sailing':
			return <Ship className={classes} />;
		case 'Anchorage':
			return <Anchor className={classes} />;
		default:
			return <Forbidden className={classes} />;
	}
};
