import { tap } from 'rxjs/operators';
import { isNil } from 'ramda';
import { DossierStore, dossierStore } from './dossier.store';
import { DossiersRegistry, dossiersRegistry } from '../../registries/dossiers.registry';
import { DossierDetail } from '../../registries/dossiers.types';
import { ApiError } from '../../../../core/services/api.types';
import { voyageService } from '../../../voyages/store/voyage/voyage.service';

export class DossierService {
	constructor(
		private store: DossierStore,
		private dossiers: DossiersRegistry,
	) {
		this.store = store;
		this.dossiers = dossiers;
	}

	public get(id: number): void {
		this.store.setLoading(true);

		this.dossiers.fetchOne(id)
			.pipe(tap(({ stayNumber, voyageNumber }: DossierDetail) => {
				if (isNil(stayNumber) || isNil(voyageNumber)) {
					return;
				}
				voyageService.getVoyage({
					stayNumber,
					voyageNumber,
				});
			}))
			.subscribe(
				(detail: DossierDetail) => {
					let i = 0;
					detail.activities.forEach((element) => {
						element.id = i;// eslint-disable-line no-param-reassign
						i += 1;
					});
					let iAlerts = 0;
					detail.alerts.forEach((element) => {
						element.id = iAlerts;// eslint-disable-line no-param-reassign
						iAlerts += 1;
					});
					this.store.setLoading(false);
					this.store.update({
						detail,
					});
				},
				(error: ApiError) => {
					this.store.setLoading(false);
					this.store.setError(error);
				}
			);
	}

	public reset(): void {
		this.store.reset();
	}
}

export const dossierService = new DossierService(dossierStore, dossiersRegistry);
