import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { userQuery, userService } from '../store';
import { Profile } from '../store/user.model';

export const useFetchUserInfoAndCheckLBRole = (): boolean | null => {
	const [hasRoles, setHasRoles] = useState<null | boolean>(null);

	// We do both fetchUser and a check on isLiquidBulkUser
	// Because onLoad the user will be empty until fetchUser has fetched
	// Which means if we use isLiquidBulkUser without fetching the data first it will return false
	// This will result in the user being redirected to /
	// If the user goes to a page that's only accessible to LBUsers

	useEffect(
		() => {
			const destroyed$: Subject<boolean> = new Subject<boolean>();

			userService.fetchUserInfo();

			userQuery.profile$
				.pipe(takeUntil(destroyed$))
				.subscribe((userProfile: Profile | null) => {
					if (userProfile) {
						userQuery.isLiquidBulkUser$
							.pipe(
								takeUntil(destroyed$),
								filter((hasRole: boolean) => hasRole !== hasRoles)
							)
							.subscribe((hasRole: boolean) => setHasRoles(hasRole));
					}

					return (): void => {
						destroyed$.next(true);
						destroyed$.complete();
					};
				});

			return (): void => {
				destroyed$.next(true);
				destroyed$.complete();
			};
		},
		[hasRoles]
	);

	return hasRoles;
};
