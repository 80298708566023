import React, { FC } from 'react';
import cx from 'classnames/bind';

import { Spinner } from '../../../../../assets/images';

import { MapLoadingProps } from './MapLoading.props';
import styles from './MapLoading.module.scss';

const cxBind = cx.bind(styles);

export const MapLoading: FC<MapLoadingProps> = ({
	children,
}) => (
	<div className={cxBind('m-map-loading')}>
		<div
			className={cxBind('m-map-loading__content')}
		>
			<Spinner className="w-5 h-5 mb-2" />
			{children}
		</div>
	</div>
);
