import { ReactNode } from 'react';

export enum BadgeType {
	Gray = 'gray',
	Red = 'red',
	Green = 'green',
	Blue = 'Blue',
}

export interface BadgeProps {
	children: ReactNode;
	type: BadgeType;
	isAlert?: boolean;
	className?: string;
}
