import React, { FC, ReactNode } from 'react';

import './RadioButtonGroup.scss';

export interface RadioGroupProps {
	children: ReactNode;
}

export const RadioButtonGroup: FC<RadioGroupProps> = ({ children }) => (
	<div>
		<fieldset className="m-radio-button-group">
			{children}
		</fieldset>
	</div>
);
