import { head } from 'ramda';

import { ApiError } from '../../../../core/services/api.types';

import { VoyagesRegistry, voyagesRegistry } from '../../registries/voyages.registry';
import { VoyageResult, VoyageQuery, VoyageDetail } from '../../registries/voyages.types';
import { VoyageStore, voyageStore } from './voyage.store';

export class VoyageService {
	constructor(
		private store: VoyageStore,
		private voyages: VoyagesRegistry,
	) {
		this.store = store;
		this.voyages = voyages;
	}

	public getVoyage(voyageParams: VoyageQuery): void {
		this.store.setLoading(true);

		this.voyages.fetchOne(voyageParams)
			.subscribe(
				(info: VoyageResult) => {
					const voyage = head(info.voyages);
					const newInfo = {
						...info,
						voyages: voyage,
					};
					this.store.update({
						info: newInfo,
					});
					this.store.setLoading(false);
				},
				(error: ApiError) => {
					this.store.setLoading(false);
					this.store.setError(error);
				}
			);
	}

	public getOneByBerthImoNumber(imoNumber: string | number): void {
		this.store.reset();
		this.store.setLoading(true);

		this.voyages.fetchOneByImoNumber(imoNumber)
			.subscribe(
				(voyages: VoyageDetail[]) => {
					this.store.update({
						detail: head(voyages),
					});
					this.store.setLoading(false);
				},
				() => {
					this.store.reset();
					this.store.setLoading(false);
				}
			);
	}
}

export const voyageService = new VoyageService(voyageStore, voyagesRegistry);
