import { AUTH_LOGOUT_URL } from '../../user.const';

export const parseLogoutUrl = (idToken: string): string => (
	idToken.length > 0 ? `
		${AUTH_LOGOUT_URL}
		?id_token_hint=${idToken}
		&post_logout_redirect_uri=${process.env.REACT_APP_LOGOUT_REDIRECT_URI}
	`
		: ''
);
