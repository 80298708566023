import React from 'react';
import cx from 'classnames/bind';

import { AvailableTimeFormats, formatTimeStamp, TimeFormats } from '../../../../core/helpers/time/timeHelper';

import { ActivityLogListItemProps } from './ActivityLogListItem.types';
import { showLogTypeIcon } from './ActivityLogListItem.helper';
import styles from './ActivityLogListItem.module.scss';

const cxBind = cx.bind(styles);

export const ActivityLogListItem: React.FC<ActivityLogListItemProps> = ({
	type,
	title,
	createdOn,
	isRead,
	isParentSmall,
}) => (
	<div className={cxBind('m-activity-log-list-item')}>
		<div className="flex flex-1">
			<div
				className={cxBind({
					'a-log-item__is-read': true,
					'a-log-item__is-read--red': !isRead,
					'a-log-item__is-read--white': isRead,
				})}
			/>
			<div className={cxBind('m-log-item__content')}>
				<div className={cxBind('a-log-item__type')}>{showLogTypeIcon(type)}</div>
				<div
					className={cxBind({
						content__inner: true,
						'content__inner--small': isParentSmall,
					})}
				>
					<span
						className={cxBind({
							content__title: true,
							'content__title--new': !isRead,
						})}
					>
						{title}
					</span>
					<span
						className={cxBind({
							'content__created-on': true,
							'content__created-on--small': isParentSmall,
						})}
					>
						{formatTimeStamp(createdOn, TimeFormats[AvailableTimeFormats.timeDateShort])}
					</span>
				</div>
			</div>
		</div>
	</div>
);
