import { DossierDetailActivity } from '../../../lib/dossiers/registries/dossiers.types';
import { SupplyChainRole } from '../../core.types';

export const activityPopulator = (activities: DossierDetailActivity[]): DossierDetailActivity[] => (
	activities.map((activity: DossierDetailActivity) => {
		let tmpActivity = activity;

		if (!tmpActivity.lastAssigneeStakeholder && tmpActivity.activityType === 'ToBerth') {
			tmpActivity = {
				...activity,
				lastAssigneeStakeholder: {
					id: 315,
					name: 'Qronoport agent',
					reference: 'Qrono',
					supplyChainRole: SupplyChainRole.LB_Agent,
					vat: 'BE0692727775',
				},
			};
		}

		return tmpActivity;
	})
);
