export enum ButtonTypes {
	Primary = 'primary',
	Secondary = 'secondary',
	Default = 'default',
	Basic = 'basic',
	TabPrimary = 'tab-primary',
	TabPrimaryOutline = 'tab-primary-outline'
}

export type ButtonType = 'primary'
| 'secondary'
| 'primary-outline'
| 'default'
| 'basic'
| 'tab-primary'
| 'tab-primary-outline';

export enum ButtonSizes {
	small ='small',
	normal = 'normal',
	large = 'large',
}

export type ButtonSize = 'small' | 'normal' | 'large';

export enum ButtonWeights {
	light ='light',
	normal = 'normal',
	medium = 'medium',
	bold = 'bold',
}

export type ButtonWeight = 'light' | 'normal' | 'medium' | 'bold';
