import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModifiedCompany } from '../../registries/companies.types';
import { companiesQuery } from './companies.query';
import { companiesStore } from './companies.store';

export const useCompanies = (): ModifiedCompany[] => {
	const [companies, setCompany] = useState<ModifiedCompany[]>([]);

	useEffect(() => {
		const destroyed$: Subject<boolean> = new Subject<boolean>();

		companiesQuery.companies$
			.pipe(takeUntil(destroyed$))
			.subscribe((results: ModifiedCompany[]) => setCompany(results));

		return (): void => {
			destroyed$.next(true);
			destroyed$.complete();
			companiesStore.reset();
		};
	}, []);

	return companies;
};
