export const hashString = (string: string): number => {
	let hash = 0;
	let chr;

	if (string.length === 0) return hash;

	for (let i = 0; i < string.length; i += 1) {
		chr = string.charCodeAt(i);
		// eslint-disable-next-line no-bitwise
		hash = ((hash << 5) - hash) + chr;
		// eslint-disable-next-line no-bitwise
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};
