import { StoreConfig, Store } from '@datorama/akita';

import { UserState, createInitialUserState } from './user.model';

@StoreConfig({
	name: 'user',
})
export class UserStore extends Store<UserState> {
	constructor() {
		super(createInitialUserState());
	}
}

export const userStore = new UserStore();
