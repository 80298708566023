import React, { FC } from 'react';

import { formatName } from '../../../helpers/text/textHelper';

import { ActivityTimelineGroupProps } from './ActivityTimelineGroup.props';
import './ActivityTimelineGroup.scss';

export const ActivityTimelineGroup: FC<ActivityTimelineGroupProps> = ({ stakeholder }) => {
	const stakeholderRole = formatName(stakeholder.supplyChainRole, 'LB_');

	return (
		<span className="a-timeline-group__content">
			<span className="a-timeline-group__role">
				{stakeholderRole}
			</span>
			<span className="a-timeline-group__name">
				{stakeholder.name}
			</span>
		</span>
	);
};
