import { EntityState } from '@datorama/akita';

import { Log, DossierLog } from '../registries/logs.types';

export interface LogsState extends EntityState<DossierLog> {
	nextLogs: Log[];
}

export function createInitialLogsState(): LogsState {
	return {
		nextLogs: [],
	};
}
