import { ID } from '@datorama/akita';

export interface Company {
	id: string;
	nxtEntityId: ID;
	name: string;
	domain: string;
	vat: string;
}

export interface Token {
	access_token: string;
	expires_in: string;
	expires: number;
	id_token: string;
	scope: string;
	session_state: string;
	token_type: string;
	state: string;
}

export interface TokenState {
	[name: string]: string;
}

export interface Profile {
	id: ID;
	email: string;
	firstname: string;
	lastname: string;
	roles: {
		standard: number[];
		sandbox: number[];
		entityRoles: number[];
	};
	company: Company;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IDTokenPayload {
	'amr': string[];
	'at_hash': string;
	'aud': string;
	'auth_time': number;
	'exp': number;
	'family_name': string;
	'given_name': string;
	'iat': number;
	'idp': string;
	'iss': string;
	'name': string;
	'nbf': number;
	'nonce': string;
	'nxt:entity:subscriptions': string[];
	'nxt:user:email': string;
	'nxt:user:roles': number[];
	'nxt:user:roles:sandbox': number[];
	'sid': string;
	'sub': string;
}

export interface AccessTokenPayload {
	'iss': string;
	'aud': string;
	'exp': number;
	'nbf': number;
	'client_id': string;
	'scope': string[];
	'sub': string;
	'auth_time': number;
	'idp': string;
	'nxt:entity:id': string;
	'nxt:entity:nxtentityid': string;
	'nxt:entity:name': string;
	'nxt:entity:domain': string;
	'nxt:entity:vat': string;
	'nxt:entity:trustLevel': string;
	'amr': string[];
	'nxt:entity:subscriptions': string[];
	'nxt:user:roles': number[];
	'nxt:user:roles:sandbox': number[];
}

export interface UserInfoPayload {
	'family_name': string;
	'given_name': string;
	'name': string;
	'nxt:entity:domain': string;
	'nxt:entity:id': string;
	'nxt:entity:name': string;
	'nxt:entity:nxtentityid': string;
	'nxt:entity:roles': number[];
	'nxt:entity:roles:sandbox': [];
	'nxt:entity:subscriptions': string[];
	'nxt:entity:trustLevel': string;
	'nxt:entity:vat': string;
	'nxt:user:email': string;
	'nxt:user:preferredlanguage': string;
	'nxt:user:roles': number[];
	'nxt:user:roles:sandbox': number[];
	'sub': string;
}

export interface UserState {
	profile: Profile | null;
	token: Token | null;
}

export function createInitialUserState(): UserState {
	return {
		profile: null,
		token: null,
	};
}
